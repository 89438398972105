import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import BasicInput from "../../basicInput/basicInput";
import AppButton from "../../basicButton/basicButtton";
import { useAddUpdateActivistModalStyle } from "./addUpdateActivistModalStyles";
import { useEffect, useRef, useState } from "react";
import ActivistModel from "../../../models/activistModel/activistModel";
import CloseIcon from "@mui/icons-material/Close";
import { EmptyGuid, INIT_ACTIVIST } from "../../../initData/initData";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAllCities,
  getStreetById,
  getStreetsByCityId,
  resetStreetById,
  selectCities,
  selectStreetById,
  selectStreets,
} from "../../../slices/addressSlice/addressSlice";
import { City } from "../../../models/address/city";
import { Street } from "../../../models/address/street";
import {
  getActivistById,
  getActivistsByActivityId,
  resetActivistStatus,
  resetIsActivistSaved,
  saveActivist,
  selectActivistById,
  selectActivistStatus,
  selectActivists,
  selectActivities,
  selectIsActivistSaved,
  selectSelectedActivityId,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import ActivityModel from "../../../models/activityModel/activityModel";
import { toast } from "react-toastify";
import { ApiStatus } from "../../../models/app/apiStatus";
import { CapitalizeFirstLetter } from "../../../utilities/sureVoteUtility";
import StreetDetailsModel from "../../../models/address/streetDetailsModel";
import { selectCurrentAccount } from "../../../slices/auth/authSlice";
import { Role } from "../../../models/enums/roles";

interface Props {
  handleClose: () => void;
  open: boolean;
}

export default function AddUpdateActivistModal({ handleClose, open }: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useAddUpdateActivistModalStyle();
  const toastDisplayedRef = useRef(false);

  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);
  const activistStatus = useAppSelector(selectActivistStatus);
  const isActivistSaved = useAppSelector(selectIsActivistSaved);
  const activistById = useAppSelector(selectActivistById);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const activityId = useAppSelector(selectSelectedActivityId)

  const streetById = useAppSelector(selectStreetById);
  const cities = useAppSelector(selectCities);
  const streetsByCity = useAppSelector(selectStreets);
  const [activist, setActivist] = useState<ActivistModel>(INIT_ACTIVIST);
  const [place, setPlace] = useState<City | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<Street | null>(null);
  const [streetDetails, setStreetDetails] = useState<StreetDetailsModel | null>(
    null
  );
  const [operationType, setOperationType] = useState("");
console.log("operationType", operationType)
  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [selectedActivist, setSelectedActivist] =
    useState<ActivistModel | null>(null);

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);
    dispatch(getActivistsByActivityId(value?.id || "")).then(() => {
      dispatch(getAllCities());
    });
    if (value) {
      setSelectedActivist(null);
      setActivist((prevState) => ({
        ...prevState,
        activityId: value.id || "",
        firstName: "",
        lastName: "",
        phone: "",
        placeId: "",
        placeName: "",
        recommendation: "",
        streetId: "",
        streetNumber: "",
      }));
      setPlace(null);
      setSelectedStreet(null);
    } else {
      setSelectedActivist(null);
      setActivist(INIT_ACTIVIST);
      setSelectedStreet(null);
      setPlace(null);
    }
  };
  const handleActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedActivist(value);
    if (value) {
      setActivist((prevState) => ({
        ...prevState,
        firstName: value.firstName,
        lastName: value.lastName,
        streetNumber: value.streetNumber,
        streetId: value.streetId,
        phone: value.phone,
        id: value.id,
        placeId: value.placeId,
      }));

      dispatch(getActivistById(value?.id!));

      const foundStreet = streetsByCity?.find(
        (street) => street.streetId === value.streetId
      );
      setSelectedStreet(foundStreet || null);
    } else {
      setActivist(INIT_ACTIVIST);
      setSelectedStreet(null);
      setPlace(null);
    }
  };
  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    newValue: City | null
  ) => {
    if (!newValue) {
      setActivist({
        ...activist,
        placeId: "",
        placeName: "",
        streetId: "",
        streetNumber: "",
      });
      setSelectedStreet(null);
      setPlace(null);
    } else {
      dispatch(getStreetsByCityId(newValue.id));
      setSelectedStreet(null);
      setPlace(newValue);

      setActivist((prevState) => ({
        ...prevState,
        placeId: newValue.id,
        placeName: newValue.name,
      }));
    }
  };

  const handleStreetChange = (
    event: React.ChangeEvent<{}>,
    newValue: Street | null
  ) => {
    if (newValue) {
      setSelectedStreet(newValue);
      const streetDetails: StreetDetailsModel = {
        id: newValue.streetId,
        name: newValue.streetName,
        cityId: "",
        cityName: "",
        localCommunityId: "",
        localCommunityName: "",
        municipalityId: "",
        municipalityName: "",
      };

      setStreetDetails(streetDetails);
      setActivist((prevState) => ({
        ...prevState,
        streetId: newValue.streetId,
        streetNumber: prevState.streetNumber || "",
      }));
    } else {
      setSelectedStreet(null);
      setStreetDetails(null);

      setActivist((prevState) => ({
        ...prevState,
        streetId: "",
        streetNumber: "",
      }));
    }
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    if (name === "firstName" || name === "lastName") {
      value = CapitalizeFirstLetter(value);
    }
    setActivist((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveActivist = () => {
    if (selectedActivist && selectedActivist.id) {
      dispatch(saveActivist(activist)).then((action) => {
        if (action.payload) {
          // setSelectedActivist(action.payload);
        }
        var nameField = document.getElementById("activist-name-field");
        nameField?.focus();
      });
    } else {
      dispatch(saveActivist(activist)).then((action) => {
        if (action.payload) {
        }
      });
    }
  };

  const resetActivistState = () => {
    setActivist((prevState) => ({
      ...prevState,
      firstName: "",
      lastName: "",
      streetNumber: "",
      streetId: "",
      phone: "",
      placeId: "",
      activityId: selectedActivity?.id || "",
    }));
    setSelectedActivity(null);
    setSelectedActivist(null);
    setPlace(null);
    setSelectedStreet(null);
  };

  useEffect(() => {
    if (activistById) {
      setActivist((prevState) => ({
        ...prevState,
        firstName: activistById.firstName,
        lastName: activistById.lastName,
        streetNumber: activistById.streetNumber,
        streetId: activistById.streetId,
        phone: activistById.phone,
        placeId: activistById.placeId,
      }));
      dispatch(getStreetById(activistById.streetId));
    }
  }, [activistById]);

  useEffect(() => {
    if (selectedActivist) {
      const activistCity = cities?.find(
        (city) => city.id === selectedActivist.placeId
      );
      if (activistCity) {
        setPlace(activistCity);
      }
    }
  }, [selectedActivist, cities]);

  useEffect(() => {
    if (streetById) {
      const extractedStreet: Street = {
        streetId: streetById.id,
        streetName: streetById.name,
      };
      const extractedCity: City = {
        id: streetById.cityId,
        name: streetById.cityName,
        cityRegionId: "",
        countryId: "",
        municipalityName: "",
      };
      dispatch(getStreetsByCityId(extractedCity.id));
      setSelectedStreet(extractedStreet);
      setPlace(extractedCity);
    }
  }, [streetById]);

  useEffect(() => {
    if (activistStatus === "Fulfilled") {
      if (operationType === "Update") {
        toast.success("Aktivista je uspešno ažuriran!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetActivistStatus());
            resetActivistState();
            toastDisplayedRef.current = false;
            setActivist(INIT_ACTIVIST);
            resetAddress();
            setSelectedActivist(null);
            setSelectedActivity(null);
          },
        });
      } else if (operationType === "Save") {
        toast.success("Aktivista je uspešno kreiran!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetActivistStatus());
            resetActivistState();
            toastDisplayedRef.current = false;
            setActivist(INIT_ACTIVIST);
            resetAddress();
            setSelectedActivist(null);
            setSelectedActivity(null);
          },
          }
        );
      }
      handleClose();
    }
  }, [activistStatus, operationType]);

  useEffect(() => {
    if (isActivistSaved) {
      dispatch(getActivistsByActivityId(selectedActivity?.id || EmptyGuid));
      dispatch(resetIsActivistSaved);
    }
  }, [isActivistSaved]);

  useEffect(() => {
    setActivist((prevActivist) => ({
      ...prevActivist,
      activityId: selectedActivity?.id || "",
    }));
  }, [selectedActivist]);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "activist-name-field":
          var lastNameField = document.getElementById(
            "activist-lastName-field"
          );
          lastNameField?.focus();
          break;
        case "activist-lastName-field":
          var cityField = document.getElementById("activist-city-field");
          cityField?.focus();
          break;
        case "activist-city-field":
          var streetField = document.getElementById("activist-street-field");
          streetField?.focus();
          break;
        case "activist-street-field":
          var streetField = document.getElementById(
            "activist-streetNumber-field"
          );
          streetField?.focus();
          break;
        case "activist-streetNumber-field":
          var phoneField = document.getElementById("activist-phone-field");
          phoneField?.focus();
          break;
        case "activist-phone-field":
          var phoneField = document.getElementById("activist-save-btn");
          phoneField?.focus();
          break;
      }
    }
  };

  const resetAddress = () => {
    setPlace(null);
    setSelectedStreet(null);
  };

  return (
    <>
      <Dialog
        onClose={() => {
          handleClose();
          dispatch(resetStreetById());
          setActivist(INIT_ACTIVIST);
          resetAddress();
        }}
        open={open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid item md={12} className={classes.dialogTitleWrapper}>
            <Typography> Upravljanje aktivistima</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Postojeći podaci aktivnosti
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className={classes.inputTitle}>
                  Aktivnost:
                </Typography>
                <Autocomplete
                  options={activities ? activities : []}
                  getOptionLabel={(option) => option.name}
                  value={selectedActivity}
                  onChange={handleActivityChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {currentAccount?.role !== Role.User && (
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Postojeći aktivista:
                  </Typography>
                  <Autocomplete
                    options={activists ? activists : []}
                    getOptionLabel={(option) =>
                      option.lastName + " " + option.firstName
                    }
                    value={selectedActivist}
                    onChange={handleActivistChange}
                    disabled={!selectedActivity}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Podaci aktiviste
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <BasicInput
                id="activist-name-field"
                label={"Ime"}
                value={activist.firstName}
                name="firstName"
                disabled={!selectedActivity}
                onChange={handleFormChange}
                onKeyDown={(e) => handleOnKeyPressed(e, "activist-name-field")}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <BasicInput
                id="activist-lastName-field"
                label={"Prezime"}
                value={activist.lastName}
                name="lastName"
                disabled={!selectedActivity}
                onChange={handleFormChange}
                onKeyDown={(e) =>
                  handleOnKeyPressed(e, "activist-lastName-field")
                }
              />
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className={classes.inputTitle}>
                  Mesto:
                </Typography>
                <Autocomplete
                  id="activist-city-field"
                  options={cities ? cities : []}
                  getOptionLabel={(option) => option.name}
                  value={place}
                  onChange={handleCityChange}
                  disabled={!selectedActivity}
                  onKeyDown={(e: any) =>
                    handleOnKeyPressed(e, "activist-city-field")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className={classes.inputTitle}>
                  Ulica:
                </Typography>
                <Autocomplete
                  id="activist-street-field"
                  options={streetsByCity ? streetsByCity : []}
                  getOptionLabel={(option) => option.streetName}
                  onChange={handleStreetChange}
                  value={selectedStreet}
                  disabled={!place}
                  onKeyDown={(e: any) =>
                    handleOnKeyPressed(e, "activist-street-field")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                      disabled={!place}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <BasicInput
                  id="activist-streetNumber-field"
                  label="Kućni broj"
                  value={activist.streetNumber}
                  onChange={handleFormChange}
                  name="streetNumber"
                  disabled={!place || !selectedStreet}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "activist-streetNumber-field")
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <BasicInput
                id="activist-phone-field"
                label={"Kontakt telefon"}
                value={activist.phone}
                disabled={!selectedActivity}
                name="phone"
                onChange={handleFormChange}
                onKeyDown={(e) => handleOnKeyPressed(e, "activist-phone-field")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={() => {
              handleClose();
              dispatch(resetStreetById());
              setActivist(INIT_ACTIVIST);
              resetAddress();
              setSelectedActivist(null);
              setSelectedActivity(null);
            }}
            label="Zatvori"
            color={"rgba(255, 73, 71, 0.8)"}
            hover={"rgba(255, 73, 71, 0.8)"}
          />
          <AppButton
            id="activist-save-btn"
            label={selectedActivist ? "Ažuriraj" : "Sačuvaj"}
            color={"#4CAF50"}
            hover={"#4CAF50"}
            disabled={activistStatus === ApiStatus.Pending}
            // onClick={() => handleSaveActivist}
            onClick={() => {
              handleSaveActivist();
              if (selectedActivist) {
                setOperationType("Update");
              } else {
                setOperationType("Save");
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
