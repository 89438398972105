import { Container, Grid } from "@mui/material";
import Header from "../components/header/header";
import ViewAndPrint from "../components/viewAndPrint/ViewAndPrint";
import SureVotesSection from "../features/sureVotesSection/sureVotesSection";

type Props = {};

export default function SureVotesPage({}: Props) {
  return (
    <Container maxWidth="xl">
      <Header headerTitle="Uređivanje sigurnih glasova"/>
      <Grid item md={12} mt={11}>
        <SureVotesSection />
      </Grid>
    </Container>
  );
}
