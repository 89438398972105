import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getListSureVotes,
  getNewActivistsByActivityId,
  getOldActivistsByActivityId,
  getSureVotesByActivistId,
  getSureVotesForTransfer,
  resetSelectedActivistId,
  resetSelectedActivityId,
  resetSureVoteList,
  resetTransferSureVotes,
  resetTransferSureVotesStatus,
  saveTrasnferSureVotes,
  selectActivists,
  selectActivities,
  selectNewActivists,
  selectOldActivists,
  selectSelectedActivistId,
  selectSelectedActivityId,
  selectSureVotesForTransferStatus,
  setSelectedActivistId,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import ActivityModel from "../../../models/activityModel/activityModel";
import ActivistModel from "../../../models/activistModel/activistModel";
import {
  EmptyGuid,
  INIT_TRANSFER_SURE_VOTES,
  INIT_UPDATE_SURE_VOTES_ACTIVITY,
} from "../../../initData/initData";
import AppButton from "../../../components/basicButton/basicButtton";
import { colors } from "../../../app/theme/colors";
import SureVoteListRequest from "../../../models/sureVotes/sureVoteListRequest";
import { toast } from "react-toastify";
import { useTransferSureVotesStyles } from "./transferSureVotesStyles";
import { UpdateSureVotesActivityRequest } from "../../../models/sureVotes/updateSureVotesActivityRequest";
import TransferSureVotesRequest from "../../../models/sureVotes/transferSureVotesRequest";
import SearchIcon from "@mui/icons-material/Search";
import BasicInput from "../../../components/basicInput/basicInput";
import { LightTooltip } from "../../../components/viewAndPrint/ViewAndPrint";
import InfoIcon from "@mui/icons-material/Info";

export default function TransferSureVotes() {
  const dispatch = useAppDispatch();
  const { classes } = useTransferSureVotesStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);
  const newActivists = useAppSelector(selectNewActivists);
  const oldActivists = useAppSelector(selectOldActivists);
  const selectedActivityId = useAppSelector(selectSelectedActivityId);
  const selectedActivistId = useAppSelector(selectSelectedActivistId);
  const selectTransferSureVotes = useAppSelector((state) => {
    const filteredSureVotes = state?.sureVotes?.transferSureVotes?.filter(
      (sureVote) =>
        sureVote.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sureVote.surname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredSureVotes;
  });

  const transferSUreVotesStatus = useAppSelector(
    selectSureVotesForTransferStatus
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(15);

  const [selectedSureVoteIds, setSelectedSureVoteIds] = useState<string[]>([]);

  const [isButtonSave, setIsButtonSave] = useState<boolean>(false);

  const [updateSureVotesActivity, setUpdateSureVotesActivity] =
    useState<UpdateSureVotesActivityRequest>(INIT_UPDATE_SURE_VOTES_ACTIVITY);

  const [transferSureVotesData, setTransferSureVotesData] =
    useState<TransferSureVotesRequest>(INIT_TRANSFER_SURE_VOTES);
  const [selectedNewActivity, setSelectedNewActivity] =
    useState<ActivityModel | null>(null);
  const [selectedOldActivity, setSelectedOldActivity] =
    useState<ActivityModel | null>(null);
  const [selectedNewActivist, setSelectedNewActivist] =
    useState<ActivistModel | null>(null);
  const [selectedOldActivist, setSelectedOldActivist] =
    useState<ActivistModel | null>(null);

  const [selectedNewActivistId, setSelectedNewActivistId] = useState<
    string | null
  >(null);

  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
  };
  const handleNewActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedNewActivity(value);

    if (!value) {
      const emptyIds = {
        newActivityId: "",
        newActivistId: "",
      };

      setUpdateSureVotesActivity((prev) => ({ ...prev, ...emptyIds }));
      setTransferSureVotesData((prev) => ({ ...prev, ...emptyIds }));

      setSelectedNewActivist(null);
      dispatch(resetSureVoteList());
      dispatch(resetSelectedActivistId());
      dispatch(resetSelectedActivityId());
      setSelectedNewActivistId(null);
      dispatch(resetTransferSureVotes());
      setSelectedSureVoteIds([]);
    } else {
      const newValueId = value.id || "";

      setUpdateSureVotesActivity((prev) => ({
        ...prev,
        newActivityId: newValueId,
      }));
      setTransferSureVotesData((prev) => ({
        ...prev,
        newActivityId: newValueId,
      }));
      dispatch(getNewActivistsByActivityId(newValueId));
    }
  };

  const handleOldActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedOldActivity(value);

    const emptyIds = {
      oldActivityId: "",
      oldActivistId: "",
    };

    if (!value) {
      setUpdateSureVotesActivity((prev) => ({ ...prev, ...emptyIds }));
      setTransferSureVotesData((prev) => ({ ...prev, ...emptyIds }));
      setSelectedOldActivist(null);
      setIsButtonSave(false);
    } else {
      const newValueId = value.id || "";

      setUpdateSureVotesActivity((prev) => ({
        ...prev,
        ...emptyIds,
        oldActivityId: newValueId,
      }));
      setTransferSureVotesData((prev) => ({
        ...prev,
        ...emptyIds,
        oldActivityId: newValueId,
      }));
      dispatch(getOldActivistsByActivityId(newValueId));
    }
  };

  const handleNewActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    const newActivistId = value?.id || "";

    setSelectedNewActivist(value);

    setUpdateSureVotesActivity((prev) => ({ ...prev, newActivistId }));
    setTransferSureVotesData((prev) => ({ ...prev, newActivistId }));

    dispatch(getSureVotesByActivistId(newActivistId));
    dispatch(setSelectedActivistId(newActivistId || null));
  };

  const handleOldActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    const oldActivistId = value?.id || "";

    setSelectedOldActivist(value);

    setUpdateSureVotesActivity((prev) => ({ ...prev, oldActivistId }));
    setTransferSureVotesData((prev) => ({ ...prev, oldActivistId }));

    dispatch(getSureVotesByActivistId(oldActivistId));
    dispatch(setSelectedActivistId(oldActivistId || null));
  };

  const handleTransferSureVotes = () => {
    dispatch(saveTrasnferSureVotes(transferSureVotesData));
    resetState();
  };

  const handleSureVoteCheck = (sureVoteId: string) => {
    setSelectedSureVoteIds((prevSelectedIds) => {
      const updatedSelectedIds = prevSelectedIds.includes(sureVoteId)
        ? prevSelectedIds.filter((id) => id !== sureVoteId)
        : [...prevSelectedIds, sureVoteId];

      setTransferSureVotesData((prevTransferSureVotesData) => ({
        ...prevTransferSureVotesData,
        sureVotes: updatedSelectedIds,
      }));

      return updatedSelectedIds;
    });
  };

  const resetState = () => {
    setSelectedSureVoteIds([]);
    setTransferSureVotesData(INIT_TRANSFER_SURE_VOTES);
    setUpdateSureVotesActivity(INIT_UPDATE_SURE_VOTES_ACTIVITY);
    setSelectedNewActivist(null);
    setSelectedNewActivity(null);
    setSelectedOldActivist(null);
    setSelectedOldActivity(null);
    dispatch(resetSelectedActivistId());
    dispatch(resetSelectedActivityId());
    dispatch(resetTransferSureVotes());
  };

  useEffect(() => {
    if (transferSUreVotesStatus === "Fulfilled") {
      setIsButtonSave(false);
      toast.success("Uspešno ste preneli sigurne glasove!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetTransferSureVotesStatus());
        },
      });
    }
  }, [transferSUreVotesStatus]);

  useEffect(() => {
    if (selectedOldActivist) {
      dispatch(getSureVotesForTransfer(updateSureVotesActivity));
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    } else if (selectedNewActivist) {
      dispatch(getSureVotesForTransfer(updateSureVotesActivity));
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    }
  }, [selectedOldActivist, selectedNewActivist]);

  return (
    <>
      <Grid sx={{ boxShadow: "0 0 10px rgb(0 0 0 / 0.2)", padding: "20px" }}>
        <Grid
          container
          display={"flex"}
          alignItems="center"
          justifyContent={"flex-end"}
          spacing={1}
          mb={1}
        >
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                Aktivnost sa koje se prenosi
              </Typography>
              <Autocomplete
                id="autocomplete_new_activist"
                options={activities ? activities : []}
                getOptionLabel={(option) => option.name}
                value={selectedOldActivity}
                onChange={handleOldActivityChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                Aktivista sa kog se prenosi
              </Typography>
              <Autocomplete
                id="autocomplete_new_activist"
                options={oldActivists ? oldActivists : []}
                getOptionLabel={(option) =>
                  option.lastName + " " + option.firstName
                }
                value={selectedOldActivist}
                onChange={handleOldActivistChange}
                disabled={!selectedOldActivity}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                Aktivnost na koju se prenosi
              </Typography>
              <Autocomplete
                options={activities ? activities : []}
                getOptionLabel={(option) => option.name}
                value={selectedNewActivity}
                onChange={handleNewActivityChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                Aktivista na kog se prenosi
              </Typography>
              <Autocomplete
                options={newActivists ? newActivists : []}
                getOptionLabel={(option) =>
                  option.lastName + " " + option.firstName
                }
                value={selectedNewActivist}
                onChange={handleNewActivistChange}
                disabled={!selectedNewActivity}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {selectedOldActivist &&
        selectedNewActivist &&
        selectedOldActivity &&
        selectedNewActivity && (
          <>
            <TableContainer
              sx={{
                maxWidth: "1250px",
                overflowX: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Grid container alignItems="center" my={2}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ padding: "5px" }}
                  display="flex"
                >
                  <BasicInput
                    label="Pretraga"
                    onChange={(e) => handleSearchChange(e.target.value)}
                    value={searchTerm}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        style={{ margin: "0 5px" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                  <LightTooltip
                    title="Pretraga može da se izvrši isključivo po imenu ili prezimenu."
                    placement="top"
                  >
                    <InfoIcon sx={{ color: colors.primary }} />
                  </LightTooltip>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                >
                  <AppButton
                    label={"Sačuvaj"}
                    color={colors.green}
                    hover={colors.green}
                    disabled={selectedSureVoteIds.length === 0}
                    onClick={handleTransferSureVotes}
                  />
                </Grid>
              </Grid>
              <Table id="transfer-sure-votes-table" size="small">
                <TableHead
                  style={{
                    background: colors.primary,
                    color: "white !important",
                    borderBottom: "none",
                    border: "none",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>Odaberi</TableCell>{" "}
                    <TableCell sx={{ color: "white" }}>Prezime i ime</TableCell>
                    <TableCell sx={{ color: "white" }}>Datum rođ.</TableCell>
                    <TableCell sx={{ color: "white" }}>Adresa</TableCell>
                    <TableCell sx={{ color: "white" }}>Kontakt tel.</TableCell>
                    <TableCell sx={{ color: "white" }}>Aktivista</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectTransferSureVotes?.map((sureVote, index) => (
                    <TableRow
                      key={sureVote.id}
                      sx={{
                        backgroundColor: sureVote.possibleDuplicate
                          ? colors.red
                          : index % 2 === 0
                          ? "white"
                          : "whitesmoke",
                        color: sureVote.possibleDuplicate ? "white" : "inherit",
                        padding: "3px",
                      }}
                    >
                      <TableCell>
                        <FormControlLabel
                          label=""
                          control={
                            <Checkbox
                              className={[
                                sureVote.possibleDuplicate
                                  ? classes.checkboxWhite
                                  : classes.checkboxPrimary,
                                classes.smallCheckbox,
                              ].join(" ")}
                              checked={selectedSureVoteIds.includes(
                                sureVote.id
                              )}
                              onChange={() => handleSureVoteCheck(sureVote.id)}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          color: sureVote.possibleDuplicate
                            ? "white"
                            : "inherit",
                        }}
                      >{`${sureVote.surname} ${sureVote.name}`}</TableCell>
                      <TableCell
                        sx={{
                          color: sureVote.possibleDuplicate
                            ? "white"
                            : "inherit",
                        }}
                      >{`${sureVote.birthDate}`}</TableCell>
                      <TableCell
                        sx={{
                          color: sureVote.possibleDuplicate
                            ? "white"
                            : "inherit",
                        }}
                      >
                        {`${sureVote.cityName}, ${sureVote.streetName} ${sureVote.homeNumber}`}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: sureVote.possibleDuplicate
                            ? "white"
                            : "inherit",
                        }}
                      >
                        {sureVote.phone}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: sureVote.possibleDuplicate
                            ? "white"
                            : "inherit",
                        }}
                      >
                        {`${sureVote.activistDisplayName}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Grid
                item
                md={12}
                xs={12}
                sx={{ textAlign: "right", paddingRight: "5px" }}
                mt={2}
              >
                <AppButton
                  label={"Sačuvaj"}
                  color={colors.green}
                  hover={colors.green}
                  disabled={selectedSureVoteIds.length === 0}
                  onClick={handleTransferSureVotes}
                />
              </Grid>
            </TableContainer>
          </>
        )}
    </>
  );
}
