import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import BasicInput from "../../../components/basicInput/basicInput";
import { useAddressSectionStyles } from "./addressSectionStyles";
import { useEffect, useState } from "react";
import ElectionPlaceDetailsModal from "../../../components/modals/electionPlaceDetailsModal/electionPlaceDetailsModal";
import AppButton from "../../../components/basicButton/basicButtton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { City } from "../../../models/address/city";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import {
  getStreetsByCityId,
  getElectionPlaceRegionByCityId,
  selectCities,
  selectStreets,
  getElectionPlaceByCityId,
  selectElectionPlaceRegion,
  getElectionPlaceByCityAndStreet,
  getELectionPlaceById,
  selectElectionPlaceById,
  selectElectionPlaceByCityAndStreet,
  resetElectionPlaceByCity,
  selectElectionPlace,
  resetElectionPlace,
  resetElectionPlaceByCityAndStreet,
  resetElectionPlaceById,
} from "../../../slices/addressSlice/addressSlice";
import ElectionPlaceRequest from "../../../models/address/electionPlaceRequest";
import { Street } from "../../../models/address/street";
import { ElectionPlaceRegion } from "../../../models/address/electionPlaceRegion";
import { ElectionPlace } from "../../../models/address/electionPlace";
import AddressManagementModal from "../../../components/modals/addressManagementModal/addressManagementModal";
import { SureVoteRequest } from "../../../models/sureVotes/sureVoteRequest";
import { EmptyGuid } from "../../../initData/initData";

interface Props {
  sureVote: SureVoteModel;
  setSureVote: any;
  validationErrors: any;
  setValidationErrors: any;
  canAddSureVote: boolean | false;
  place: any;
  setPlace: any;
  selectedStreet: any;
  setSelectedStreet: any;
}

export default function AddressSection({
  setSureVote,
  sureVote,
  setValidationErrors,
  validationErrors,
  canAddSureVote,
  place,
  setPlace,
  selectedStreet,
  setSelectedStreet,
}: Props) {
  const INIT_ELECTION_PLACE_REQUEST: ElectionPlaceRequest = {
    cityId: "",
    cityName: "",
    streetName: "",
    streetNumber: "",
  };
  const dispatch = useAppDispatch();

  const { classes } = useAddressSectionStyles();

  const [showManualElectionPlaceRegion, setShowManualElectionPlaceRegion] =
    useState(false);
  const [showManualElectionPlace, setShowManualElectionPlace] = useState(false);
  const [isOpenElectionPlaceDetailsModal, setIsOpenElectionPlaceDetailsModal] =
    useState(false);
  const [isOpenAddressManagementModal, setIsOpenAddressManagementModal] =
    useState(false);

  const [electionPlaceRequest, setElectionPlaceRequest] =
    useState<ElectionPlaceRequest>(INIT_ELECTION_PLACE_REQUEST);

  const [selectedElectionById, setSelectedElectionPlaceById] =
    useState<ElectionPlaceRegion | null>(null);
  const [selectedElectionPlace, setSelectedElectionPlace] =
    useState<ElectionPlace | null>(null);
  //console.log(electionPlaceRequest);
  const streetsByCity = useAppSelector(selectStreets) || undefined;
  const {
    cities,
    electionPlaceRegionByCity,
    electionPlaceById,
    electionPlace,
    electionPlaceByStreetAndCity,
  } = useAppSelector((state) => ({
    cities: selectCities(state),
    electionPlaceRegionByCity: selectElectionPlaceRegion(state),
    electionPlaceById: selectElectionPlaceById(state),
    electionPlace: selectElectionPlace(state),
    electionPlaceByStreetAndCity: selectElectionPlaceByCityAndStreet(state),
  }));

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        [name]: value,
      },
    }));

    if (name === "homeNumber") {
      setElectionPlaceRequest((prevRequest) => ({
        ...prevRequest,
        streetNumber: value,
      }));
    }

    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = (event: any) => {
    setShowManualElectionPlaceRegion(event.target.checked);
  };

  const handleCheckboxElectionPlace = (event: any) => {
    setShowManualElectionPlace(event.target.checked);
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    newValue: City | null
  ) => {
    if (!newValue) {
      setSureVote((prevState: SureVoteModel) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          cityId: "", // Set cityId to an empty string when newValue is null
          cityName: "",
        },
      }));
      setElectionPlaceRequest({
        ...electionPlaceRequest,
        cityId: "",
        cityName: "",
        streetName: "",
        streetNumber: "",
      });
      setSelectedStreet(null);
      setPlace(null);
      setSelectedElectionPlaceById(null);
      setSelectedElectionPlace(null);
      dispatch(resetElectionPlaceByCity());
      dispatch(resetElectionPlaceByCityAndStreet());
      dispatch(resetElectionPlace());
      dispatch(resetElectionPlaceById());
    } else {
      dispatch(getStreetsByCityId(newValue.id));
      dispatch(getElectionPlaceRegionByCityId(newValue.id));
      dispatch(getElectionPlaceByCityId(newValue.id));
      setElectionPlaceRequest({
        ...electionPlaceRequest,
        cityId: newValue.id,
        cityName: newValue.name,
      });
      setSelectedStreet(null);
      setPlace(newValue);
      setSureVote((prevState: SureVoteModel) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          cityId: newValue.id,
          cityName: newValue.name,
          municipalityName: newValue.municipalityName,
        },
      }));
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        place: "",
      }));
    }
  };

  const handleStreetChange = (
    event: React.ChangeEvent<{}>,
    newValue: Street | null
  ) => {
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        streetId: newValue ? newValue.streetId : null,
        streetName: newValue ? newValue.streetName : "",
      },
    }));

    setSelectedStreet(newValue);

    setElectionPlaceRequest((prevRequest) => ({
      ...prevRequest,
      streetName: newValue ? newValue.streetName : "",
    }));

    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      street: "",
    }));
  };

  useEffect(() => {
    if (sureVote && sureVote.sureVoteRequest.cityId != "") {
      if (place === null || sureVote.sureVoteRequest.cityId !== place.id) {
        const sureVotePlace = cities?.find(
          (x) => x.id === sureVote.sureVoteRequest.cityId
        );
        setPlace(sureVotePlace || null);
        dispatch(getStreetsByCityId(sureVotePlace?.id || EmptyGuid));
        dispatch(
          getElectionPlaceRegionByCityId(sureVotePlace?.id || EmptyGuid)
        );
        dispatch(getElectionPlaceByCityId(sureVotePlace?.id || EmptyGuid));
      }
    } else {
      setPlace(null);
    }
  }, [sureVote]);

  useEffect(() => {
    if (!selectedStreet && sureVote.sureVoteRequest.homeNumber) {
      setSureVote({ ...sureVote, homeNumber: "" });
      setElectionPlaceRequest({ ...electionPlaceRequest, streetNumber: "" });
    }
    if (place === null) {
      setShowManualElectionPlaceRegion(false);
      setShowManualElectionPlace(false);
      dispatch(resetElectionPlaceByCityAndStreet());
      dispatch(resetElectionPlaceByCity());
    } else {
      setElectionPlaceRequest({
        ...electionPlaceRequest,
        cityId: place.id,
        cityName: place.name,
      });
    }
  }, [selectedStreet, place]);

  useEffect(() => {
    if (showManualElectionPlaceRegion && electionPlaceRequest.streetNumber) {
      dispatch(getElectionPlaceByCityAndStreet(electionPlaceRequest));
    }
  }, [showManualElectionPlaceRegion, electionPlaceRequest.streetNumber]);

  const handleHomeNumberBlur = () => {
    dispatch(getElectionPlaceByCityAndStreet(electionPlaceRequest));
  };

  useEffect(() => {
    if (!place) {
      setShowManualElectionPlaceRegion(false);
      setShowManualElectionPlace(false);
    }
  }, [place]);

  useEffect(() => {
    if (streetsByCity && sureVote.sureVoteRequest.streetId != "") {
      const sureVoteStreet =
        streetsByCity.find(
          (x) => x.streetId === sureVote.sureVoteRequest.streetId
        ) || null;
      setSelectedStreet(sureVoteStreet);
    } else {
      setSelectedStreet(null);
    }
  }, streetsByCity);

  useEffect(() => {
    if (selectedElectionPlace) {
      dispatch(resetElectionPlaceByCityAndStreet());
    }
  }, [selectedElectionPlace]);

  useEffect(() => {
    if (electionPlaceByStreetAndCity) {
      const electionPlaceId = electionPlaceByStreetAndCity.id;
      setSureVote((prevState: SureVoteModel) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          electionPlaceId: electionPlaceId,
        },
      }));
    }
  }, [electionPlaceByStreetAndCity]);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-city-field":
          var streetField = document.getElementById("sure-vote-street-field");
          streetField?.focus();
          break;
        case "sure-vote-street-field":
          var streetNumberField = document.getElementById(
            "sure-vote-streetNumber-field"
          );
          streetNumberField?.focus();
          break;
        case "sure-vote-streetNumber-field":
          var phoneField = document.getElementById("sure-vote-phone-field");
          phoneField?.focus();
          break;
      }
    }
  };

  return (
    <Grid item md={12} sx={{ padding: "10px" }}>
      {/* <Grid item md={12} xs={12} sx={{ padding: "10px" }}>
        <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
          Podaci prebivališta
        </Typography>
      </Grid> */}
      <Grid container spacing={1} mt={1}>
        <Grid item md={4} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            Mesto
          </Typography>
          <Autocomplete
            id={"sure-vote-city-field"}
            options={cities ? cities : []}
            getOptionLabel={(option) => option.name}
            value={place}
            onChange={handleCityChange}
            disabled={!canAddSureVote}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "sure-vote-city-field")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
                error={!!validationErrors.place}
                helperText={validationErrors.place}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            Ulica
          </Typography>
          <Autocomplete
            id={"sure-vote-street-field"}
            options={streetsByCity ? streetsByCity : []}
            getOptionLabel={(option) => option.streetName}
            onChange={handleStreetChange}
            value={selectedStreet}
            disabled={!place}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "sure-vote-street-field")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
                disabled={!place}
                error={!!validationErrors.street}
                helperText={validationErrors.street}
                required
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <BasicInput
            id={"sure-vote-streetNumber-field"}
            label="Kućni broj"
            value={sureVote.sureVoteRequest.homeNumber}
            onChange={handleFormChange}
            name="homeNumber"
            disabled={!place || !selectedStreet}
            onBlur={handleHomeNumberBlur}
            error={!!validationErrors.homeNumber}
            helperText={validationErrors.homeNumber}
            onKeyDown={(e) =>
              handleOnKeyPressed(e, "sure-vote-streetNumber-field")
            }
          />
        </Grid>
        {/* <Grid item md={12}>
          <Link
            sx={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => setIsOpenAddressManagementModal(true)}
          >
            Uređivnaje adresa
          </Link>
        </Grid> */}
        {/* <Grid item md={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={showManualElectionPlaceRegion}
                />
              }
              label="Ručno izaberite regiju bir. mesta"
            />
          </FormGroup>
        </Grid> */}
      </Grid>
      {showManualElectionPlaceRegion && (
        <Grid container spacing={1} mt={1}>
          {!showManualElectionPlace && (
            <Grid item md={6} xs={12}>
              <Typography variant="body1" className={classes.inputTitle}>
                Područje biračkog mesta
              </Typography>
              <Autocomplete
                disabled={!place}
                options={
                  electionPlaceRegionByCity ? electionPlaceRegionByCity : []
                }
                getOptionLabel={(option) => option.region}
                value={selectedElectionById}
                onChange={(event, value) => {
                  dispatch(
                    getELectionPlaceById(value ? value.electionPlaceId : "")
                  );
                  setSelectedElectionPlaceById(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <FormGroup>
              <FormControlLabel
                disabled={!place && !selectedStreet}
                control={
                  <Checkbox
                    onChange={handleCheckboxElectionPlace}
                    checked={showManualElectionPlace}
                  />
                }
                label="Ručno izaberite bir. mesto"
              />
            </FormGroup>
          </Grid>
          {showManualElectionPlace && electionPlace !== null && (
            <Grid item md={6} xs={12}>
              <Typography variant="body1" className={classes.inputTitle}>
                Biračko mesto
              </Typography>
              <Autocomplete
                options={electionPlace}
                getOptionLabel={(option) =>
                  `${electionPlace.indexOf(option) + 1}. ${option.name}`
                }
                value={selectedElectionPlace}
                onChange={(event, value) => {
                  setSureVote({
                    ...sureVote,
                    electionPlaceId: value ? value.id : null,
                  });
                  dispatch(getELectionPlaceById(value ? value.id : ""));
                  setSelectedElectionPlace(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
      {/* <Grid container justifyContent={"flex-end"} mt={1}>
        <AppButton
          disabled={!electionPlaceByStreetAndCity && !electionPlaceById}
          label={"Detalji biračkog mesta"}
          color="#2f4454"
          hover="#2f4454"
          onClick={() => setIsOpenElectionPlaceDetailsModal(true)}
        />
      </Grid> */}
      <ElectionPlaceDetailsModal
        handleClose={() => setIsOpenElectionPlaceDetailsModal(false)}
        open={isOpenElectionPlaceDetailsModal}
        electionPlaceById={
          electionPlaceByStreetAndCity === null
            ? electionPlaceById
            : electionPlaceByStreetAndCity
        }
      />
      <AddressManagementModal
        open={isOpenAddressManagementModal}
        handleClose={() => setIsOpenAddressManagementModal(false)}
      />
    </Grid>
  );
}
