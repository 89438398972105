import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
  } from "@mui/material";
  import BasicInput from "../../basicInput/basicInput";
  import AppButton from "../../basicButton/basicButtton";
  import CustomDatePicker from "../../customDatePicker/customDatePicker";
  import ActivityModel from "../../../models/activityModel/activityModel";
  import { useState } from "react";
  import { Dayjs } from "dayjs";
  import { INIT_ACTIVITY } from "../../../initData/initData";
  import CloseIcon from "@mui/icons-material/Close";
import { useAddressManagementModalStyles } from "./addressManagementModalStyles";
import AddressManagementSection from "../../../features/sureVotesSection/addressSection/addressManagementSection/addressManagementSection";
  
  interface Props {
    handleClose: any;
    open: any;
  }
  
  export default function AddressManagementModal({ handleClose, open }: Props) {
    const { classes } = useAddressManagementModalStyles();
  
    const [activity, setActivity] = useState<ActivityModel>(INIT_ACTIVITY);
    
    const handleFormChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setActivity((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    return (
      <>
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
          <DialogTitle>
          <Grid item md={12} className={classes.dialogTitleWrapper}>
              <Typography> Upravljanje adresama</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px" }}>
            <AddressManagementSection />
          </DialogContent>
          <DialogActions>
            <AppButton
              label="Zatvori"
              color={"rgba(255, 73, 71, 0.8)"}
              hover={"rgba(255, 73, 71, 0.8)"}
              onClick={handleClose}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
  