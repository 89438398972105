import { Container, Grid, Typography } from "@mui/material";
import Header from "../components/header/header";
import AppButton from "../components/basicButton/basicButtton";
import { colors } from "../app/theme/colors";
import { AppRoutes } from "../app/routes";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {};

export default function UnauthorizedPage({}: Props) {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <Header />
      <Grid item md={12} mt={11}>
        <Grid item md={12} textAlign={"center"}>
          <Typography>Stranica nije pronađena.</Typography>
        </Grid>
        <Grid item md={12} textAlign={"center"} mt={1}>
          <AppButton
          startIcon={<ArrowBackIcon />}
            label={"Idi na dodavanje sig. glasova"}
            color={colors.primary}
            hover={colors.primary}
            onClick={() => navigate(AppRoutes.SureVotes)}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
