import { api_auth, api_sureVotes } from "./api";
import { ApiResponse } from "../../models/app/apiResponse";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";
import { SureVoteRequest } from "../../models/sureVotes/sureVoteRequest";
import { ValidateSureVoteRequest } from "../../models/sureVotes/validateSureVoteRequest";
import SureVoteListRequest from "../../models/sureVotes/sureVoteListRequest";
import SureVoteListResponse from "../../models/sureVotes/sureVoteListResponse";
import { FastSearchRequest } from "../../models/sureVotes/fastSearchRequest";
import { UpdateSureVotesActivistRequest } from "../../models/sureVotes/updateSureVotesActivistRequest";
import { SureVotesForTransferResponse } from "../../models/sureVotes/sureVotesForTransferResponse";
import { UpdateSureVotesActivityRequest } from "../../models/sureVotes/updateSureVotesActivityRequest";
import TransferSureVotesRequest from "../../models/sureVotes/transferSureVotesRequest";
import { CorrectionRequest } from "../../models/sureVotes/correctionRequest";
import { ReportByDateRequest } from "../../models/sureVotes/reportByDateRequest";
import { ActivityCorrectionShort } from "../../models/sureVotes/activityCorrectionShort";
import { ActivityCorrectionView } from "../../models/sureVotes/activityCorrectionView";
import { CreateActivityCorrection } from "../../models/sureVotes/createActivityCorrection";
import { SureVoteCorrectionRequest } from "../../models/sureVotes/sureVoteCorrectionModel";
import SelectedSureVoteCorrection from "../../models/sureVotes/selectedSureVoteCorrection";
import SelectSureVoteCorrectionRequest from "../../models/sureVotes/sureVoteCorrectionRequest";

export const saveSureVote: any = async (request: any) => {
  return api_sureVotes.post("/SureVote", request) as Promise<
    ApiResponse<SureVoteModel | null>
  >;
};

export const updateSureVote: any = async (request: any) => {
  return api_sureVotes.post("/SureVote/update", request) as Promise<
    ApiResponse<SureVoteModel | null>
  >;
};

export const deleteSureVote: any = async (id: string) => {
  return api_sureVotes.delete("/SureVote/" + id) as Promise<
    ApiResponse<SureVoteModel | null>
  >;
};

export const saveActivity: any = async (request: any) => {
  return api_sureVotes.post("/Activity", request) as Promise<
    ApiResponse<ActivityModel | null>
  >;
};

export const saveActivist: any = async (request: any) => {
  return api_sureVotes.post("/Activist", request) as Promise<
    ApiResponse<ActivistModel | null>
  >;
};

export const getListSureVotes: any = async (
  sureVoteListRequest: SureVoteListRequest
) => {
  return api_sureVotes.post("/SureVote/list", sureVoteListRequest) as Promise<
    ApiResponse<SureVoteListResponse | null>
  >;
};

export const getSureVotesReport: any = async (
  sureVoteListRequest: SureVoteListRequest
) => {
  return api_sureVotes.post("/Report/create", sureVoteListRequest, {
    responseType: "blob",
  }) as Promise<Blob>;
};

export const createReportByDate: any = async (
  sureVoteListRequest: ReportByDateRequest
) => {
  return api_sureVotes.post("/Report/create-by-date", sureVoteListRequest, {
    responseType: "blob",
  }) as Promise<Blob>;
};

export const getSureVoteById: any = async (id: string) => {
  return api_sureVotes.get("/SureVote/" + id) as Promise<
    ApiResponse<SureVoteRequest | null>
  >;
};

export const getSureVotesByActivistId: any = async (activistId: string) => {
  return api_sureVotes.get("/SureVote/activistId/" + activistId) as Promise<
    ApiResponse<SureVoteModel | null>
  >;
};

export const getActivityById: any = async (id: string) => {
  return api_sureVotes.get("/Activity/"+ id) as Promise<
    ApiResponse<ActivityModel | null>
  >;
};

export const getActivities: any = async () => {
  return api_sureVotes.get("/Activity") as Promise<
    ApiResponse<ActivityModel | null>
  >;
};

export const getActivists: any = async (activityId: string) => {
  return api_sureVotes.get("/Activist/activityId/" + activityId) as Promise<
    ApiResponse<ActivistModel | null>
  >;
};

export const getActivistById: any = async (id: string) => {
  return api_sureVotes.get("/Activist/" + id) as Promise<
    ApiResponse<ActivistModel | null>
  >;
};

export const validateSureVote: any = async (
  request: ValidateSureVoteRequest
) => {
  return api_sureVotes.post("/SureVote/validate-sure-vote", request) as Promise<
    ApiResponse<SureVoteModel | null>
  >;
};

export const fastSearchSureVote: any = async (request: FastSearchRequest) => {
  return api_sureVotes.post(
    "/SureVote/existing-surevotes-memebers",
    request
  ) as Promise<ApiResponse<SureVoteRequest | null>>;
};
export const updateSureVoteActivists: any = async (
  request: UpdateSureVotesActivistRequest
) => {
  return api_sureVotes.post(
    "/SureVote/update-surevotes-activist",
    request
  ) as Promise<ApiResponse<string>>;
};

export const getSureVotesForTransfer: any = async (
  request: UpdateSureVotesActivityRequest
) => {
  return api_sureVotes.post(
    "/SureVote/get-surevotes-for-transfer",
    request
  ) as Promise<ApiResponse<SureVotesForTransferResponse[]>>;
};

export const transferSureVotes: any = async (
  request: TransferSureVotesRequest
) => {
  return api_sureVotes.post("/SureVote/transfer-surevotes", request) as Promise<
    ApiResponse<string>
  >;
};

export const createCorrectionSureVotes: any = async (
  request: CreateActivityCorrection
) => {
  return api_sureVotes.post("/Report/create-for-correction", request, {
    responseType: "blob",
  }) as Promise<Blob>;
};

export const createActivityCorrection: any = async (
  request: CreateActivityCorrection
) => {
  return api_sureVotes.post(
    "/ActivityCorrection/save-activity-correction",
    request
  ) as Promise<ApiResponse<ActivityCorrectionShort>>;
};

export const updateSurevoteCorrection: any = async (
  request: SureVoteCorrectionRequest
) => {
  return api_sureVotes.post(
    "/ActivityCorrection/update-surevote-correction",
    request
  ) as Promise<ApiResponse<string>>;
};

export const getSelectedSureVoteCorrection: any = async (
  request: SelectSureVoteCorrectionRequest
) => {
  return api_sureVotes.post(
    "/ActivityCorrection/get-surevote-correction",
    request
  ) as Promise<ApiResponse<SelectedSureVoteCorrection>>;
};

export const getActivityCorectionByActivityId: any = async (
  activityId: string
) => {
  return api_sureVotes.get(
    "/ActivityCorrection/activityId/" + activityId
  ) as Promise<ApiResponse<ActivityCorrectionShort[]>>;
};

export const getActivityCorectionById: any = async (id: string) => {
  return api_sureVotes.get("/ActivityCorrection/" + id) as Promise<
    ApiResponse<ActivityCorrectionView>
  >;
};

export const deleteActivityCorrection: any = async (id: string) => {
  return api_sureVotes.delete("/ActivityCorrection/" + id) as Promise<
    ApiResponse<string>
  >;
};
