import { Container, Grid } from "@mui/material";
import Header from "../components/header/header";
import { useAppDispatch } from "../app/hooks";
import TransferSureVotesTab from "../features/transferSureVotesSections/transferSureVotesTab";

type Props = {};

export default function TransferSureVotesPage({}: Props) {
  const dispatch = useAppDispatch();


  return (
    <Container maxWidth="xl">
      <Header headerTitle="Prenos glasova"/>
      <Grid item md={12} mt={11}>
        <TransferSureVotesTab />
      </Grid>
    </Container>
  );
}
