import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import UpdateSureVotesActivist from "./updateSureVotesActivist/updateSureVotesActivist";
import TransferSureVotes from "./updateSureVotesActivity/transverSureVotes";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function TransferSureVotesSection() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
      >
        <Tab label="Prenos glasova između glasanja" />
        <Tab label="Prenos glasova po aktivisti u istom glasanju" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TransferSureVotes />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UpdateSureVotesActivist />
      </TabPanel>
    </Box>
  );
}
