import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useAddressSectionStyles } from "../addressSectionStyles";
import BasicInput from "../../../../components/basicInput/basicInput";
import AppButton from "../../../../components/basicButton/basicButtton";
import { useEffect, useState } from "react";
import { Country } from "../../../../models/address/addressManagement/country";
import {
  INIT_CITY_ADDRESS_MANAGEMENT,
  INIT_COUNTRY,
  INIT_LOCAL_COMUNITY,
  INIT_MUNICIPALITY,
  INIT_PLACE,
  INIT_STATE,
  INIT_STREET_ADDRESS_MANAGEMENT,
} from "../../../../initData/initData";
import { Place } from "../../../../models/address/addressManagement/place";
import { CityAddressManagement } from "../../../../models/address/addressManagement/cityAddressManagement";
import { Municipality } from "../../../../models/address/addressManagement/municipality";
import { StreetAddressManagement } from "../../../../models/address/addressManagement/streetAddressManagement";
import { LocalComunity } from "../../../../models/address/addressManagement/localComunity";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { State } from "../../../../models/address/addressManagement/state";
import {
  getAllCountries,
  getAllStates,
  getCitiesByCountryId,
  getCountriesByStateId,
  getLocalComunityByPlaceId,
  getMunicipalitiesByCountryId,
  getPlacesByCountryId,
  getStreetsByPlaceId,
  resetCitiesByCountryId,
  resetCityStatus,
  resetCountryStatus,
  resetLocalComunity,
  resetLocalComunityStatus,
  resetMunicipalitiesByCountryId,
  resetMunicipalityStatus,
  resetPlaceStatus,
  resetPlacesByCountryId,
  resetStreetStatus,
  resetStreetsByPlaceId,
  saveCity,
  saveCountry,
  saveLocalComunity,
  saveMunicipality,
  savePlace,
  saveState,
  saveStreet,
  selectCitiesByCountryId,
  selectCountries,
  selectCountriesByState,
  selectCountryByName,
  selectLocalComunityByPlaceId,
  selectLocalComunityStatus,
  selectMunicipalitiesByCountryId,
  selectPlacesByCityId,
  selectPlacesByCountryId,
  selectPlacesByMunicipalityId,
  selectSaveCityStatus,
  selectSaveCountryStatus,
  selectSaveMunicipalityStatus,
  selectSavePlaceStatus,
  selectSaveStreetStatus,
  selectSelectedMunicipality,
  selectStates,
  selectStreetsByPlaceId,
} from "../../../../slices/addressSlice/addressSlice";
import { toast } from "react-toastify";
import { Street } from "../../../../models/address/street";

export default function AddressManagementSection() {
  const dispatch = useAppDispatch();
  const { classes } = useAddressSectionStyles();

  const {
    states,
    countries,
    countriesByState,
    countryByName,
    citiesByCountryId,
    municipalitiesByCountryId,
    placesByCountryId,
    placesByMunicipalityId,
    placesByCityId,
    selectedMunicipalityResponse,
    localComunityByPlaceId,
    streetsByPlaceId,
    countryStatus,
    cityStatus,
    municipalityStatus,
    placeStatus,
    streetStatus,
    localComunityStatus
  } = useAppSelector((state) => ({
    states: selectStates(state),
    countries: selectCountries(state),
    countriesByState: selectCountriesByState(state),
    countryStatus: selectSaveCountryStatus(state),
    countryByName: selectCountryByName(state),
    citiesByCountryId: selectCitiesByCountryId(state),
    municipalitiesByCountryId: selectMunicipalitiesByCountryId(state),
    placesByCountryId: selectPlacesByCountryId(state),
    placesByMunicipalityId: selectPlacesByMunicipalityId(state),
    placesByCityId: selectPlacesByCityId(state),
    localComunityByPlaceId: selectLocalComunityByPlaceId(state),
    selectedMunicipalityResponse: selectSelectedMunicipality(state),
    cityStatus: selectSaveCityStatus(state),
    municipalityStatus: selectSaveMunicipalityStatus(state),
    placeStatus: selectSavePlaceStatus(state),
    streetsByPlaceId: selectStreetsByPlaceId(state),
    streetStatus: selectSaveStreetStatus(state),
    localComunityStatus: selectLocalComunityStatus(state)
  }));

  const [state, setState] = useState<State>(INIT_STATE);
  const [country, setCountry] = useState<Country>(INIT_COUNTRY);
  const [city, setCity] = useState<CityAddressManagement>(
    INIT_CITY_ADDRESS_MANAGEMENT
  );
  const [place, setPlace] = useState<Place>(INIT_PLACE);
  const [municipality, setMunicipality] =
    useState<Municipality>(INIT_MUNICIPALITY);

  const [localComunity, setLocalComunity] =
    useState<LocalComunity>(INIT_LOCAL_COMUNITY);
  const [street, setStreet] = useState<StreetAddressManagement>(
    INIT_STREET_ADDRESS_MANAGEMENT
  );
  const [streetsByPlace, setStreetByPlace] = useState<Street | null>(null);

  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCity, setSelectedCity] =
    useState<CityAddressManagement | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<Municipality | null>(null);
  const [selectedLocalComunity, setSelectedLocalComunity] =
    useState<LocalComunity | null>(null);
  const [selectedStreet, setSelectedStreet] =
    useState<StreetAddressManagement | null>(null);

  const handleBasicInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const { name, value } = event.target;

    switch (inputName) {
      case "state":
        setState((prevPlace) => ({ ...prevPlace, name: value }));
        break;
      case "country":
        setCountry((prevCountry) => {
          switch (name) {
            case "name":
              return { ...prevCountry, name: value };
            case "countryCode":
              return { ...prevCountry, countryCode: value };
            default:
              return prevCountry;
          }
        });
        break;
      case "city":
        setCity((prevCity) => {
          switch (name) {
            case "name":
              return { ...prevCity, name: value };
            case "postalCode":
              return { ...prevCity, postalCode: value };
            default:
              return prevCity;
          }
        });
        break;
      case "place":
        setPlace((prevPlace) => {
          switch (name) {
            case "name":
              return { ...prevPlace, name: value };
            case "postalCode":
              return { ...prevPlace, postalCode: value };
            default:
              return prevPlace;
          }
        });
        break;
      // case "place":
      //   setPlace((prevPlace) => ({ ...prevPlace, name: value }));
      //   break;
      case "municipality":
        setMunicipality((prevMunicipality) => ({
          ...prevMunicipality,
          name: value,
        }));
        break;
      case "localComunity":
        setLocalComunity((prevMunicipality) => ({
          ...prevMunicipality,
          name: value,
        }));
        break;
      case "street":
        setStreet((prevStreet) => ({ ...prevStreet, name: value }));
        break;
      default:
        break;
    }
  };

  const handleStateChange = (
    event: React.ChangeEvent<{}>,
    value: State | null
  ) => {
    setSelectedState(value);
    if (value) {
      setState(value);
      setCountry((prevCountry) => ({
        ...prevCountry,
        stateId: value.id,
      }));
      dispatch(getCountriesByStateId(value.id));
    } else {
      resetFormFields();
    }
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{}>,
    value: Country | null
  ) => {
    setSelectedCountry(value);
    if (value) {
      setCountry((prevCountry) => ({
        ...prevCountry,
        id: value.id,
        name: value.name,
        countryCode: value.countryCode,
      }));
      dispatch(getCitiesByCountryId(value.id));
      dispatch(getMunicipalitiesByCountryId(value.id));
      dispatch(getPlacesByCountryId(value.id));
      setCity((prevCity) => ({
        ...prevCity,
        countryId: value.id || "",
      }));
      setMunicipality((prevMunicipality) => ({
        ...prevMunicipality,
        countryId: value.id || "",
      }));
      setPlace((prevPlace) => ({
        ...prevPlace,
        countryId: value.id || "",
      }));
    } else {
      setCountry((prevCountry) => ({
        ...prevCountry,
        id: null,
        name: "",
        countryCode: "",
      }));
      resetFormFields();
      dispatch(resetPlacesByCountryId());
      dispatch(resetMunicipalitiesByCountryId());
      dispatch(resetCitiesByCountryId());
      dispatch(resetStreetsByPlaceId());
    }
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    value: CityAddressManagement | null
  ) => {
    setSelectedCity(value);
    if (value) {
      setCity((prevCity) => ({
        ...prevCity,
        id: value.id,
        name: value.name,
        postalCode: value.postalCode,
      }));

      //  dispatch(getCitiesByCountryId(value.id))
    } else {
      setCity((prevCity) => ({
        ...prevCity,
        id: null,
        name: "",
        postalCode: "",
      }));
    }
  };

  const handleMunicipalityChange = (
    event: React.ChangeEvent<{}>,
    value: Municipality | null
  ) => {
    setSelectedMunicipality(value);
    if (value) {
      setMunicipality((prevMunicipality) => ({
        ...prevMunicipality,
        id: value.id,
        name: value.name,
      }));
      //  dispatch(getCitiesByCountryId(value.id))
    } else {
      setMunicipality((prevMunicipality) => ({
        ...prevMunicipality,
        id: null,
        name: "",
      }));
    }
  };

  const handlePlaceChange = (
    event: React.ChangeEvent<{}>,
    value: Place | null
  ) => {
    setSelectedPlace(value);
    if (value) {
      setPlace((prevPlace) => ({
        ...prevPlace,
        id: value.id,
        name: value.name,
        postalCode: value.postalCode,
      }));
      setLocalComunity((prevLocalComunity) => ({
        ...prevLocalComunity,
        placeId: value.id,
      }));
      setStreet((prevStreet) => ({
        ...prevStreet,
        cityId: value.id || "",
      }));
      dispatch(getStreetsByPlaceId(value.id));
      dispatch(getLocalComunityByPlaceId(value.id));
    } else {
      setPlace((prevPlace) => ({
        ...prevPlace,
        id: null,
        name: "",
        postalCode: "",
      }));
      setStreet((prevPlace) => ({
        ...prevPlace,
        id: null,
        name: "",
      }));
      setLocalComunity((prevLocalComunity) => ({
        ...prevLocalComunity,
        id: null,
        name: "",
      }));
      dispatch(resetStreetsByPlaceId());
      dispatch(resetLocalComunity());
      setSelectedStreet(null);
    }
  };

  const handleLocalComunityChange = (
    event: React.ChangeEvent<{}>,
    value: LocalComunity | null
  ) => {
    setSelectedLocalComunity(value);
    if (value) {
      setLocalComunity((prevLocalComunity) => ({
        ...prevLocalComunity,
        id: value.id,
        name: value.name,
        placeId: value.placeId,
      }));
      //  dispatch(getCitiesByCountryId(value.id))
    } else {
      setLocalComunity((prevLocalComunity) => ({
        ...prevLocalComunity,
        id: null,
        name: "",
      }));
    }
  };

  const handleStreetChange = (
    event: React.ChangeEvent<{}>,
    value: StreetAddressManagement | null
  ) => {
    setSelectedStreet(value);
    if (value) {
      setStreet((prevStreet) => ({
        ...prevStreet,
        id: value.id,
        name: value.name,
        cityId: value.cityId,
      }));
      //  dispatch((value.id))
    } else {
      setStreet((prevStreet) => ({
        ...prevStreet,
        id: null,
        name: "",
      }));
    }
  };

  const handleSaveState = () => {
    dispatch(saveState(state)).then(() => {
      dispatch(getAllStates());
    });
  };

  const handleSaveCountry = () => {
    if (selectedCountry && selectedCountry.id) {
      dispatch(saveCountry(country)).then((action) => {
        if (action.payload) {
          setSelectedCountry(action.payload);
          if (selectedCountry.stateId) {
            dispatch(getCountriesByStateId(selectedCountry.stateId));
          } else {
            dispatch(getAllCountries());
          }
        }
      });
    } else {
      dispatch(saveCountry(country)).then((action) => {
        if (action.payload) {
          setSelectedCountry(action.payload);
          dispatch(getCountriesByStateId(country.stateId));
        }
      });
    }
  };

  const handleSaveCity = () => {
    if (selectedCity && selectedCity.id) {
      dispatch(saveCity(city)).then((action) => {
        if (action.payload) {
          setSelectedCity(action.payload);
          dispatch(getCitiesByCountryId(selectedCity.countryId));
        }
      });
    } else {
      dispatch(saveCity(city)).then((action) => {
        if (action.payload) {
          setSelectedCity(action.payload);
          dispatch(getCitiesByCountryId(city.countryId));
        }
      });
    }
  };

  const handleSaveMunicipality = () => {
    if (selectedMunicipality && selectedMunicipality.id) {
      dispatch(saveMunicipality(municipality)).then((action) => {
        if (action.payload) {
          setSelectedMunicipality(action.payload);
          dispatch(
            getMunicipalitiesByCountryId(selectedMunicipality.countryId)
          );
        }
      });
    } else {
      dispatch(saveMunicipality(municipality)).then((action) => {
        if (action.payload) {
          setSelectedMunicipality(action.payload);
          dispatch(getMunicipalitiesByCountryId(municipality.countryId));
        }
      });
    }
  };

  const handleSavePlace = () => {
    if (selectedPlace && selectedPlace.id) {
      dispatch(savePlace(place)).then((action) => {
        if (action.payload) {
          setSelectedPlace(action.payload);
          dispatch(getPlacesByCountryId(selectedPlace.countryId));
        }
      });
    } else {
      dispatch(savePlace(place)).then((action) => {
        if (action.payload) {
          setSelectedPlace(action.payload);
          dispatch(getPlacesByCountryId(place.countryId));
        }
      });
    }
  };

  const handleSaveLocalComunity = () => {
    if (selectedLocalComunity && selectedLocalComunity.id) {
      dispatch(saveLocalComunity(localComunity)).then((action) => {
        if (action.payload) {
          setSelectedLocalComunity(action.payload);
          dispatch(getLocalComunityByPlaceId(selectedLocalComunity.placeId));
        }
      });
    } else {
      dispatch(saveLocalComunity(localComunity)).then((action) => {
        if (action.payload) {
          setSelectedLocalComunity(action.payload);
          dispatch(getLocalComunityByPlaceId(localComunity.placeId));
        }
      });
    }
  };

  const handleSaveStreet = () => {
    if (selectedStreet && selectedStreet.id) {
      dispatch(saveStreet(street)).then((action) => {
        if (action.payload) {
          setSelectedStreet(action.payload);
          dispatch(getStreetsByPlaceId(selectedStreet.cityId));
        }
      });
    } else {
      dispatch(saveStreet(street)).then((action) => {
        if (action.payload) {
          setSelectedStreet(action.payload);
          dispatch(getStreetsByPlaceId(street.cityId));
        }
      });
    }
  };

  useEffect(() => {
    if (countryStatus === "Fulfilled") {
      if (selectedCountry?.id) {
        toast.success("Zemlja je uspešno ažurirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetCountryStatus());
          },
        });
      } else {
        toast.success("Zemlja je uspešno kreirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetCountryStatus());
          },
        });
      }
    } else if (cityStatus === "Fulfilled") {
      if (selectedCity?.id) {
        toast.success("Grad je uspešno ažuriran!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetCountryStatus());
          },
        });
      } else {
        toast.success("Grad je uspešno kreiran!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetCityStatus());
          },
        });
      }
    }
    if (municipalityStatus === "Fulfilled") {
      if (selectedMunicipality?.id) {
        toast.success("Opština je uspešno ažurirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetMunicipalityStatus());
          },
        });
      } else {
        toast.success("Opština je uspešno kreirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetMunicipalityStatus());
          },
        });
      }
    }
    if (placeStatus === "Fulfilled") {
      if (selectedPlace?.id) {
        toast.success("Mesto je uspešno ažurirano!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetPlaceStatus());
          },
        });
      } else {
        toast.success("Mesto je uspešno kreirano!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetPlaceStatus());
          },
        });
      }
    }
    if (localComunityStatus === "Fulfilled") {
      if (selectedLocalComunity?.id) {
        toast.success("Mesna zajednica je uspešno ažurirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetLocalComunityStatus());
          },
        });
      } else {
        toast.success("Mesna zajednica je uspešno kreirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetLocalComunityStatus());
          },
        });
      }
    }
    if (streetStatus === "Fulfilled") {
      if (selectedStreet?.id) {
        toast.success("Ulica je uspešno ažurirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetStreetStatus());
          },
        });
      } else {
        toast.success("Ulica je uspešno kreirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetStreetStatus());
          },
        });
      }
    }
    dispatch(getAllCountries());

    const timer = setTimeout(() => {
      dispatch(resetCountryStatus());
    }, 3000);

    return () => clearTimeout(timer);
  }, [countryStatus, cityStatus, municipalityStatus, placeStatus, streetStatus, localComunityStatus]);

  useEffect(() => {
    if (selectedMunicipality?.id) {
      setMunicipality((prevMunicipality) => ({
        ...prevMunicipality,
        id: selectedMunicipality.id,
      }));
    }
    if (selectedCountry?.id) {
      setCountry((prevCountry) => ({
        ...prevCountry,
        id: selectedCountry.id,
      }));
    }
    if (selectedCity?.id) {
      setCity((prevCity) => ({
        ...prevCity,
        id: selectedCity.id,
      }));
    }
    if (selectedPlace?.id) {
      setPlace((prevPlace) => ({
        ...prevPlace,
        id: selectedPlace.id,
      }));
    }
    if (selectedLocalComunity?.id) {
      setLocalComunity((prevLocalComunity) => ({
        ...prevLocalComunity,
        id: selectedLocalComunity.id,
      }));
    }
    if (selectedStreet?.id) {
      setStreet((prevStreet) => ({
        ...prevStreet,
        id: selectedStreet.id,
      }));
    }
  }, [
    selectedMunicipality,
    selectedCountry,
    selectedCity,
    selectedPlace,
    selectedLocalComunity,
    selectedStreet,
  ]);

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const resetFormFields = () => {
    setState(INIT_STATE);
    setCity(INIT_CITY_ADDRESS_MANAGEMENT);
    setCountry(INIT_COUNTRY);
    setPlace(INIT_PLACE);
    setMunicipality(INIT_MUNICIPALITY);
    setLocalComunity(INIT_LOCAL_COMUNITY);
    setStreet(INIT_STREET_ADDRESS_MANAGEMENT);
    setSelectedCountry(null);
    setSelectedCity(null);
    setSelectedPlace(null);
    setSelectedMunicipality(null);
    setSelectedLocalComunity(null);
    setSelectedStreet(null);
  };

  return (
    <>
      <Grid container sx={{ padding: "10px" }} spacing={1}>
        {/* country */}
        <Grid item md={6} xs={12} mb={3}>
          <Typography variant="body1" className={classes.inputTitle}>
            Zemlja:
          </Typography>
          <Autocomplete
            options={states ? states : []}
            getOptionLabel={(option) => option.name}
            value={selectedState}
            onChange={handleStateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} mb={3}>
          <BasicInput
            label="Naziv zemlje"
            value={state.name}
            name="name"
            onChange={(event: any) => handleBasicInputChange(event, "state")}
          />
        </Grid>
        {selectedState !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton label="Ažuriraj" color="#4CAF50" hover="#4CAF50" />
            </Grid>
          </Grid>
        ) : (
          state.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveState}
              />
            </Grid>
          )
        )}
        <Grid item md={6} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            Država:
          </Typography>
          <Autocomplete
            options={
              !selectedState
                ? (countries as readonly Country[])
                : (countriesByState as readonly Country[])
            }
            getOptionLabel={(option) => option.name}
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <BasicInput
            label="Naziv države"
            value={country.name}
            name="name"
            onChange={(event: any) => handleBasicInputChange(event, "country")}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <BasicInput
            label="Pozivni broj"
            value={country.countryCode}
            name="countryCode"
            onChange={(event: any) => handleBasicInputChange(event, "country")}
          />
        </Grid>
        {selectedCountry !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                name="update_country"
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveCountry}
                disabled={countryStatus === "Pending" ? true : false}
              />
            </Grid>
          </Grid>
        ) : (
          country.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                name="save_country"
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveCountry}
                disabled={countryStatus === "Pending" ? true : false}
              />
            </Grid>
          )
        )}
        {/* city */}
        <Grid item md={6} xs={12} my={3}>
          <Typography variant="body1" className={classes.inputTitle}>
            Grad:
          </Typography>
          <Autocomplete
            // disabled={!selectedState || !selectedCountry}
            options={citiesByCountryId ? citiesByCountryId : []}
            getOptionLabel={(option) => option.name}
            value={selectedCity}
            onChange={handleCityChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12} my={3}>
          <BasicInput
            // disabled={!selectedState || !selectedCountry}
            label="Naziv grada"
            value={city.name}
            name="name"
            onChange={(event: any) => handleBasicInputChange(event, "city")}
          />
        </Grid>
        <Grid item md={3} xs={12} my={3}>
          <BasicInput
            // disabled={!selectedState || !selectedCountry}
            label="Poštanski broj"
            value={city.postalCode}
            name="postalCode"
            onChange={(event: any) => handleBasicInputChange(event, "city")}
          />
        </Grid>
        {selectedCity !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveCity}
                disabled={cityStatus === "Pending" ? true : false}
              />
            </Grid>
          </Grid>
        ) : (
          city.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveCity}
                disabled={cityStatus === "Pending" ? true : false}
              />
            </Grid>
          )
        )}
        {/* municipality */}
        <Grid item md={6} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            Opština:
          </Typography>
          <Autocomplete
            // disabled={!selectedState || !selectedCountry || !selectedCity}
            options={municipalitiesByCountryId ? municipalitiesByCountryId : []}
            getOptionLabel={(option) => option.name}
            value={selectedMunicipality}
            onChange={handleMunicipalityChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <BasicInput
            // disabled={!selectedState || !selectedCountry || !selectedCity}
            label="Naziv opštine"
            value={municipality.name}
            name="name"
            onChange={(event: any) =>
              handleBasicInputChange(event, "municipality")
            }
          />
        </Grid>
        {selectedMunicipality !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveMunicipality}
                disabled={municipalityStatus === "Pending" ? true : false}
              />
            </Grid>
          </Grid>
        ) : (
          municipality.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveMunicipality}
                disabled={municipalityStatus === "Pending" ? true : false}
              />
            </Grid>
          )
        )}
        {/* city */}
        <Grid item md={6} xs={12} my={3}>
          <Typography variant="body1" className={classes.inputTitle}>
            Mesto:
          </Typography>
          <Autocomplete
            options={placesByCountryId ? placesByCountryId : []}
            getOptionLabel={(option) => option.name}
            value={selectedPlace}
            onChange={handlePlaceChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12} my={3}>
          <BasicInput
            label="Naziv mesta"
            value={place.name}
            name="name"
            onChange={(event: any) => handleBasicInputChange(event, "place")}
          />
        </Grid>
        <Grid item md={3} xs={12} my={3}>
          <BasicInput
            label="Poštanski broj"
            value={place.postalCode}
            name="postalCode"
            onChange={(event: any) => handleBasicInputChange(event, "place")}
            disabled={placeStatus === "Pending" ? true : false}
          />
        </Grid>
        {selectedPlace !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSavePlace}
                disabled={placeStatus === "Pending" ? true : false}
              />
            </Grid>
          </Grid>
        ) : (
          place.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSavePlace}
              />
            </Grid>
          )
        )}

        {/* localComunity */}
        <Grid item md={6} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            Mesna zajednica:
          </Typography>
          <Autocomplete
            options={localComunityByPlaceId ? localComunityByPlaceId : []}
            getOptionLabel={(option) => option.name}
            value={selectedLocalComunity}
            onChange={handleLocalComunityChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <BasicInput
            label="Naziv mesne zajednice"
            value={localComunity.name}
            name="name"
            onChange={(event: any) =>
              handleBasicInputChange(event, "localComunity")
            }
          />
        </Grid>
        {selectedLocalComunity !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveLocalComunity}
                disabled={localComunityStatus === "Pending" ? true : false}
              />
            </Grid>
          </Grid>
        ) : (
          localComunity.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveLocalComunity}
              />
            </Grid>
          )
        )}
        {/* street */}
        <Grid item md={6} xs={12} my={3}>
          <Typography variant="body1" className={classes.inputTitle}>
            Ulica:
          </Typography>
          <Autocomplete
            options={streetsByPlaceId ? streetsByPlaceId : []}
            getOptionLabel={(option) => option.name}
            value={selectedStreet}
            onChange={handleStreetChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} my={3}>
          <BasicInput
            label="Naziv ulice"
            value={street.name}
            name="name"
            onChange={(event: any) => handleBasicInputChange(event, "street")}
          />
        </Grid>
        {selectedStreet !== null ? (
          <Grid item md={12} display="flex" justifyContent="flex-end">
            <Grid item>
              <AppButton
                label="Obriši"
                color="rgba(255, 73, 71, 0.8)"
                hover="rgba(255, 73, 71, 0.8)"
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label="Ažuriraj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveStreet}
              />
            </Grid>
          </Grid>
        ) : (
          street.name !== "" && (
            <Grid item md={12} display="flex" justifyContent="flex-end">
              <AppButton
                label="Sačuvaj"
                color="#4CAF50"
                hover="#4CAF50"
                onClick={handleSaveStreet}
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}
