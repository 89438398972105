import ActivistModel from "../models/activistModel/activistModel";
import ActivityModel from "../models/activityModel/activityModel";
import { CityAddressManagement } from "../models/address/addressManagement/cityAddressManagement";
import { Country } from "../models/address/addressManagement/country";
import { LocalComunity } from "../models/address/addressManagement/localComunity";
import { Municipality } from "../models/address/addressManagement/municipality";
import { Place } from "../models/address/addressManagement/place";
import { State } from "../models/address/addressManagement/state";
import { StreetAddressManagement } from "../models/address/addressManagement/streetAddressManagement";
import ElectionPlaceRequest from "../models/address/electionPlaceRequest";
import { Street } from "../models/address/street";
import { LoginRequest } from "../models/auth/loginRequest";
import { CorrectionRequest } from "../models/sureVotes/correctionRequest";
import { CreateActivityCorrection } from "../models/sureVotes/createActivityCorrection";
import { FastSearchRequest } from "../models/sureVotes/fastSearchRequest";
import {
  SureVoteCorrectionModel,
  SureVoteCorrectionRequest,
} from "../models/sureVotes/sureVoteCorrectionModel";
import { SureVoteRequest } from "../models/sureVotes/sureVoteRequest";
import { SureVoteModel } from "../models/sureVotes/sureVotes";
import TransferSureVotes from "../models/sureVotes/transferSureVotesRequest";
import { UpdateSureVotesActivistRequest } from "../models/sureVotes/updateSureVotesActivistRequest";
import { UpdateSureVotesActivityRequest } from "../models/sureVotes/updateSureVotesActivityRequest";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const INIT_SURE_VOTE_REQUEST: SureVoteRequest = {
  id: EmptyGuid,
  birthDate: "",
  cityId: "",
  cityName: "",
  homeNumber: "",
  name: "",
  phone: "",
  streetId: "",
  streetName: "",
  surname: "",
  electionPlaceId: "",
  municipalityName: "",
};

export const INIT_SURE_VOTE: SureVoteModel = {
  activistId: EmptyGuid,
  activityId: EmptyGuid,
  memberId: EmptyGuid,
  sureVoteRequest: {
    id: EmptyGuid,
    birthDate: "",
    cityId: "",
    cityName: "",
    homeNumber: "",
    name: "",
    phone: "",
    streetId: "",
    streetName: "",
    surname: "",
    electionPlaceId: "",
    activistDisplayName: "",
    municipalityName: "",
  },
};

// export const INIT_SURE_VOTE_CORRECTION: SureVoteCorrectionModel = {
//   activistId: EmptyGuid,
//   activityId: EmptyGuid,
//   memberId: EmptyGuid,
//   sureVoteCorrection: {
//     id: EmptyGuid,
//     birthDate: "",
//     cityId: "",
//     cityName: "",
//     homeNumber: "",
//     name: "",
//     phone: "",
//     streetId: "",
//     streetName: "",
//     surname: "",
//     municipalityName: "",
//     note: "",
//     activistId: "",
//     activityCorrectionId: "",
//     activityId: "",
//   },
// };

export const INIT_UPDATE_SUREVOTE_CORRECTION: SureVoteCorrectionRequest = {
  sureVoteCorrection: {
    id: EmptyGuid,
    birthDate: "",
    cityId: "",
    cityName: "",
    homeNumber: "",
    name: "",
    phone: "",
    streetId: "",
    streetName: "",
    surname: "",
    municipalityName: "",
    note: "",
    activistId: "",
    activityCorrectionId: "",
    activityId: "",
  },
};

export const INIT_ACTIVIST: ActivistModel = {
  id: null,
  firstName: "",
  streetNumber: "",
  lastName: "",
  phone: "",
  streetId: "",
  activityId: "",
  recommendation: "",
  placeId: null,
  placeName: null,
};

export const INIT_ACTIVITY: ActivityModel = {
  id: null,
  endDate: "",
  name: "",
  startDate: "",
};

export const INIT_LOGIN_REQUEST: LoginRequest = {
  email: "ognjen.rakita@midenas.rs",
  password: "Admin123!",
};

export const INIT_STATE: State = {
  id: null,
  name: "",
};

export const INIT_COUNTRY: Country = {
  id: null,
  countryCode: "",
  name: "",
  stateId: null,
};

export const INIT_PLACE: Place = {
  id: null,
  cityRegionId: null,
  countryId: "",
  name: "",
  postalCode: null,
  municipalityId: null,
};

export const INIT_CITY_ADDRESS_MANAGEMENT: CityAddressManagement = {
  id: null,
  name: "",
  postalCode: "",
  countryId: "",
};

export const INIT_MUNICIPALITY: Municipality = {
  id: null,
  name: "",
  countryId: "",
};

export const INIT_LOCAL_COMUNITY: LocalComunity = {
  id: null,
  placeId: "",
  name: "",
};

export const INIT_STREET_ADDRESS_MANAGEMENT: StreetAddressManagement = {
  id: null,
  name: "",
  cityId: "",
};

export const INIT_STREET: Street = {
  streetId: "",
  streetName: "",
};

export const INIT_ELECTION_PLACE_REQUEST: ElectionPlaceRequest = {
  cityId: "",
  cityName: "",
  streetName: "",
  streetNumber: "",
};

export const INIT_FAST_SEARCH: FastSearchRequest = {
  birthdate: "",
  name: "",
  phone: "",
  place: "",
  street: "",
  surname: "",
};

export const INIT_UPDATE_SURE_VOTES_ACTIVIST: UpdateSureVotesActivistRequest = {
  activityId: "",
  newActivist: "",
  oldActivist: "",
  sureVotes: [],
};
export const INIT_UPDATE_SURE_VOTES_ACTIVITY: UpdateSureVotesActivityRequest = {
  newActivistId: "",
  newActivityId: "",
  oldActivistId: "",
  oldActivityId: "",
};

export const INIT_TRANSFER_SURE_VOTES: TransferSureVotes = {
  newActivistId: "",
  newActivityId: "",
  oldActivistId: "",
  oldActivityId: "",
  sureVotes: [],
};

export const INIT_SURE_VOTES_CORRECTION: CorrectionRequest = {
  surevotesIds: [],
  activityCorrectionId: null,
};

export const INIT_ACTIVITY_CORRECTION: CreateActivityCorrection = {
  activityCorrectionId: null,
  activityId: EmptyGuid,
  sureVotes: [{ note: null, surevoteId: EmptyGuid }],
};
