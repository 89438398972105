import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoginStyles } from "./LoginStyles";
import LoginIcon from "@mui/icons-material/Login";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/basicButton/basicButtton";
import { ApiStatus } from "../../models/app/apiStatus";
import BasicInput from "../../components/basicInput/basicInput";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoginResponse } from "../../models/auth/loginResponse";
import { LoginRequest } from "../../models/auth/loginRequest";
import {
  login,
  selectCurrentAccount,
  selectErrorMessage,
  selectLoginResponse,
  selectLoginStatus,
} from "../../slices/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../app/routes";
import { Role } from "../../models/enums/roles";

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.primary,
    },
  },
});

export default function Login() {
  const { t } = useTranslation();
  const { classes } = useLoginStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const loginStatus = useAppSelector(selectLoginStatus);
  const response = useAppSelector(selectErrorMessage);


  const [showPassword, setShowPassword] = useState(false);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [loginResponse, setLoginResponse] = useState<LoginResponse>();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: "",
    password: "",
  });

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "login_input-email":
          var emailField = document.getElementById("login_input-email");
          emailField?.focus();
          break;
        case "login_input-password":
          var emailField = document.getElementById("login_input-password");
          emailField?.focus();
          break;
      }
    }
  };

  const onLogin = async () => {
    dispatch(login(loginRequest));
  };

  useEffect(() => {
    if (loginStatus === "Fulfilled") {
      if (currentAccount?.role === Role.User) {
        navigate(AppRoutes.SureVotes);
      } else {
        navigate(AppRoutes.Dashboard);
      }
    }
  }, [loginStatus, currentAccount]);

  useEffect(() => {
    const emailInput = document.getElementById("login_input-email");
    if (emailInput) {
      emailInput.focus();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <CssBaseline />
        <Grid
          container
          style={{
            background: "#F0FAFA",
            borderRadius: "15px",
          }}
        >
          {isMobile && (
            <Grid xs={12} className={classes.backgroundMobile}></Grid>
          )}
          <Grid lg={7} xs={12} className={classes.trapeze}></Grid>
          <Grid lg={5} xs={12} sx={{ padding: isMobile ? 3 : 2.5 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  style={{
                    color: "#696969",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  Sigurni glasovi
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{ fontSize: "0.8rem" }}
                >
                  Prijavite se
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                mt={1}
                className={classes.gridBasicInput}
              >
                <BasicInput
                  id="login_input-email"
                  type="email"
                  name="email"
                  label="E-pošta"
                  onChange={(e: any) =>
                    setLoginRequest({ ...loginRequest, email: e.target.value })
                  }
                  value={loginRequest.email}
                  height="35px"
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "login_input-password")
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                mt={1}
                className={classes.gridBasicInput}
              >
                <BasicInput
                  id="login_input-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label="Lozinka"
                  value={loginRequest.password}
                  onChange={(e: any) =>
                    setLoginRequest({
                      ...loginRequest,
                      password: e.target.value,
                    })
                  }
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onLogin();
                    }
                  }}
                  height="35px"
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12} lg={12} mb={3} mt={3}>
                <AppButton
                  id="login_button-login"
                  onClick={onLogin}
                  color={colors.primary}
                  label={
                    loginStatus === ApiStatus.Pending
                      ? "Prijavljivanje u toku..."
                      : "Ulogujte se"
                  }
                  hover={colors.primary}
                  width="100%"
                  startIcon={
                    loginStatus === ApiStatus.Pending ? (
                      <CircularProgress
                        size={15}
                        className={classes.circularProgress}
                      />
                    ) : (
                      <LoginIcon />
                    )
                  }
                  disabled={loginStatus === ApiStatus.Pending}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
