import { ApiResponse } from "../../models/app/apiResponse";
import { LoginRequest } from "../../models/auth/loginRequest";
import { LoginResponse } from "../../models/auth/loginResponse";
import { RefreshTokenRequest } from "../../models/auth/refreshTokenRequest";
import { api_auth } from "./api";

export const Auth = {
    Login: async (request: LoginRequest) => {
      return api_auth.post("/auth/login", request) as Promise<
        ApiResponse<LoginResponse>
      >;
    },
  };