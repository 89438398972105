import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import backgraundImage from "../../images/clanovi3.png";
import backgraundImageMobile from "../../images/clanovi.png";
import { colors } from "../../app/theme/colors";

export const useLoginStyles = makeStyles()((theme: Theme) => ({
  btn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.primary,
    marginBottom: "5px",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  btnResetPass: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.green,
    marginBottom: "5px",
    "&:hover": {
      background: colors.green,
      color: "white",
    },
  },
  circularProgress: {
    color: colors.primary,
  },
  gridBasicInput: {
    "> .MuiFormControl-root > .MuiOutlinedInput-root > input": {
      padding: "6px 12px 6px 12px",
    },
  },
  trapeze: {
    backgroundImage: `url(${backgraundImage})`,
    backgroundColor: "rgba(124, 125, 125, 0.5)",
    backgroundSize: "140%",
    backgroundPosition: "center ",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
  },

  backgroundMobile: {
    backgroundImage: `url(${backgraundImageMobile})`,
    height: "200px",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    backgroundPosition: "center",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(124, 125, 125, 0.5)",
    position: "relative",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.20)",
  },

  title: {
    textAlign: "center",
    color: "#696969",
  },
}));
