import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
  } from "@mui/material";
  import AppButton from "../../components/button/AppButton";
  
  interface Props {
    handleClose: () => void;
    open: boolean;
    handleDelete: (id: string) => Promise<void>;
    correctionName: string | null;
  }
  
  export default function DeleteConfirmationActivityCorrectionModal({
    handleClose,
    open,
    handleDelete,
    correctionName,
  }: Props) {
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
        <DialogTitle textAlign={"center"}></DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <Grid item md={12}>
            <Typography textAlign="center">
              Da li ste sigurni da želite da obrišete izabranu korekciju?
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography fontWeight="bold" textAlign="center">
              {correctionName}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} marginTop={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid item>
                <AppButton
                  label={"Da"}
                  color={"#4CAF50"}
                  hover={"#4CAF50"}
                  onClick={handleDelete}
                />
              </Grid>
              <Grid item ml={1}>
                <AppButton
                  label={"Ne"}
                  color={"rgba(255, 73, 71, 0.8)"}
                  hover={"rgba(255, 73, 71, 0.8)"}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
  