import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useViewAndPrintStyles = makeStyles()((theme: Theme) => ({
  tableCell: {
    background: "rgba(47, 68, 84, 0.5)",
    color: "white",
    textAlign: "center",
    marginLeft: "5px",
    border: "none",
  },
  selectionOfTableColumnsButton: {
    marginTop: "5px",
    marginRight: "5px",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: "#2f4454",
    marginBottom: "5px",
    "&:hover": {
      background: "#2f4454",
      color: "white",
    },
  },
  circularProgress: {
    color: "#2f4454",
    display: "block",
    margin: "auto",
  },
  circularProgressWrapper: {
    padding: "20px",
  },
  select: {
    outline: "none",
    border: "none",
    width: "40px",
    cursor: "pointer",
    "&:hover": {
      background: "lightGray",
    },
  },
  sortIcon: {
    marginLeft: "5px",
  },
  pagination: {
    marginBottom: "5px",
  },
  iconButton: {
    color: "#2f4454",
    "&:hover": {
      background: "#2f4454",
      color: "white",
    },
  },
  exportPdfButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    fontSize: "40px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    // float: "right",
    // color: colors.red,
    color:"grey",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      background: colors.red,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
  },
  exportExcButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    fontSize: "40px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    color: colors.green,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      background: colors.green,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    // margin: 0,
    // padding: 0,
    // marginTop: "15px",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {},
    },
  },
}));
