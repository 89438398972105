import { EmptyGuid } from "../initData/initData";
import { SureVoteRequest } from "../models/sureVotes/sureVoteRequest";
import { SureVoteModel } from "../models/sureVotes/sureVotes";

export const CanSaveSureVote = (sureVoteModel: SureVoteModel): boolean => {
    if(sureVoteModel.activityId === null || sureVoteModel.activityId === EmptyGuid) return false;
    if(sureVoteModel.activistId === null || sureVoteModel.activistId === EmptyGuid) return false;
    if(sureVoteModel.sureVoteRequest.name === '' || sureVoteModel.sureVoteRequest.name === undefined) return false;
    if(sureVoteModel.sureVoteRequest.surname === '' || sureVoteModel.sureVoteRequest.surname === undefined) return false;
    if(sureVoteModel.sureVoteRequest.birthDate === '' || sureVoteModel.sureVoteRequest.birthDate === undefined) return false;
    if(sureVoteModel.sureVoteRequest.streetId === null || sureVoteModel.sureVoteRequest.streetId === EmptyGuid) return false;
    if(sureVoteModel.sureVoteRequest.homeNumber === '' || sureVoteModel.sureVoteRequest.homeNumber === undefined) return false;

    return true;
}

export const CanUpdateSureVote = (sureVoteModel: SureVoteModel): boolean => {
    if(sureVoteModel.activityId === null || sureVoteModel.activityId === EmptyGuid) return false;
    if(sureVoteModel.activistId === null || sureVoteModel.activistId === EmptyGuid) return false;
    if(sureVoteModel.sureVoteRequest.name === '' || sureVoteModel.sureVoteRequest.name === undefined) return false;
    if(sureVoteModel.sureVoteRequest.surname === '' || sureVoteModel.sureVoteRequest.surname === undefined) return false;
    if(sureVoteModel.sureVoteRequest.birthDate === '' || sureVoteModel.sureVoteRequest.birthDate === undefined) return false;
    if(sureVoteModel.sureVoteRequest.streetId === null || sureVoteModel.sureVoteRequest.streetId === EmptyGuid) return false;
    if(sureVoteModel.sureVoteRequest.homeNumber === '' || sureVoteModel.sureVoteRequest.homeNumber === undefined) return false;
    if(sureVoteModel.sureVoteRequest.id === EmptyGuid) return false;
    return true;
}

export const CapitalizeFirstLetter = (input: string): string => {
    if(!input  || input.trim() == '') return input;

    return input.charAt(0).toUpperCase()+input.slice(1);
}