import { Container, Grid } from "@mui/material";
import Header from "../components/header/header";
import SureVotesCorrection from "../features/sureVotesCorrection/sureVotesCorrection";
import { useEffect, useState } from "react";
import {
  INIT_SURE_VOTE,
  INIT_SURE_VOTES_CORRECTION,
  INIT_UPDATE_SUREVOTE_CORRECTION,
} from "../initData/initData";
import { SureVoteModel } from "../models/sureVotes/sureVotes";
import SureVoteListDetails from "../models/sureVotes/sureVoteListDetails";
import {
  SureVoteCorrectionModel,
  SureVoteCorrectionRequest,
} from "../models/sureVotes/sureVoteCorrectionModel";
import { SureVoteRequest } from "../models/sureVotes/sureVoteRequest";

export default function SureVotesCorrectionPage() {
  const [updateSureVoteCorection, setUpdateSureVoteCorrection] =
    useState<SureVoteCorrectionRequest>(INIT_UPDATE_SUREVOTE_CORRECTION);
  const [selectedSureVotesForCorrection, setSelectedSureVotesForCorrection] =
    useState<SureVoteListDetails[]>([]);

  useEffect(() => {
    setSelectedSureVotesForCorrection([]);
  }, []);
  return (
    <Container maxWidth="xl">
      <Header headerTitle="Korekcija sigurnih glasova" />
      <Grid item md={12} mt={11}>
        <SureVotesCorrection
          setSureVote={setUpdateSureVoteCorrection}
          sureVote={updateSureVoteCorection}
          selectedSureVotesForCorrection={selectedSureVotesForCorrection}
          setSelectedSureVotesForCorrection={setSelectedSureVotesForCorrection}
        />
      </Grid>
    </Container>
  );
}
