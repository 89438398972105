import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useSureVotesSectionStyles } from "./sureVotesSectionStyles";
import { useEffect, useState } from "react";
import AppButton from "../../components/basicButton/basicButtton";
import ActivityDataAccordion from "../accordions/activityDataAccordion/activityDataAccordion";
import PersonalDataInformation from "./personalDataInformationSection/personalDataInformationSection";
import AddressSection from "./addressSection/addressSection";
import ContactSection from "./contactSection/contactSection";
import AddUpdateActivistModal from "../../components/modals/addUpdateActivistModal/addUpdateActivistModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import { INIT_SURE_VOTE } from "../../initData/initData";
import AddUpdateActivityModal from "../../components/modals/addUpdateActivityModal/addUpdateActivityModal";
import {
  resetActivists,
  resetSureVoteStatus,
  resetSureVotesByActivist,
  resetValidateSureVote,
  saveSureVote,
  selectActivities,
  selectExistingSureVote,
  selectSelectedSureVote,
  selectSureVoteStatus,
  selectSureVotes,
  selectValidateSureVotes,
  validateSureVote,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import { toast } from "react-toastify";
import { CanUpdateSureVote } from "../../utilities/sureVoteUtility";
import WarningSureVoteModal from "../../components/modals/warningSureVoteModal/warningSureVoteModal";
import Connector from "../../appSignalR";
import { City } from "../../models/address/city";
import { Street } from "../../models/address/street";
import ValidateSureVotesModal from "../../components/modals/validateSureVotesModal/validateSureVotesModal";
import { selectCurrentAccount } from "../../slices/auth/authSlice";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";

export default function SureVotesSection() {
  const dispatch = useAppDispatch();
  const { classes } = useSureVotesSectionStyles();

  const sureVoteStatus = useAppSelector(selectSureVoteStatus);
  const sureVotes = useAppSelector(selectSureVotes);
  const selectedSureVote = useAppSelector(selectSelectedSureVote);
  const activities = useAppSelector(selectActivities);
  const existingSureVote = useAppSelector(selectExistingSureVote);
  const validateSureVotes = useAppSelector(selectValidateSureVotes);
  const [sureVote, setSureVote] = useState<SureVoteModel>(INIT_SURE_VOTE);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [selectedActivists, setSelectedActivists] =
    useState<ActivistModel | null>(null);

  const currentAccount = useAppSelector(selectCurrentAccount);

  const [isOpenActivistModal, setIsOpenActivistModal] = useState(false);
  const [isOpenActivityModal, setIsOpenActivityModal] = useState(false);
  const [isOpenWarningSureVote, setIsOpenWarningSureVote] = useState(false);
  const [isOpenValidateSureVoteModal, setIsOpenValidateSureVoteModal] =
    useState(false);
  const [isSureVoteValidated, setIsSureVoteValidated] = useState(false);
  const [previousSureVote, setPreviousSureVote] =
    useState<SureVoteModel | null>(null);

  const [selectedVoteIndex, setSelectedVoteIndex] = useState<number | null>(
    null
  );

  const [place, setPlace] = useState<City | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<Street | null>(null);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    place: "",
    street: "",
    homeNumber: "",
  });
  const [canAddSureVote, setCanAddSureVote] = useState(false);

  const validateFields = () => {
    const errors = {
      firstName: "",
      lastName: "",
      birthDate: "",
      place: "",
      street: "",
      homeNumber: "",
      phone: "",
    };

    if (!sureVote.sureVoteRequest.name) {
      errors.firstName = "Ime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.surname) {
      errors.lastName = "Prezime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.cityName) {
      errors.place = "Mesto je obavezno polje.";
    }

    // if (!sureVote.sureVoteRequest.birthDate) {
    //   var newSureVote = {...sureVote, birthDate:'02-02-2000'};
    //   setSureVote(newSureVote);
    //   //errors.birthDate = "Datum rođenja je obavezno polje.";
    // }

    if (!sureVote.sureVoteRequest.streetId) {
      errors.street = "Ulica je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.homeNumber) {
      errors.homeNumber = "Kućni br. je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.phone) {
      errors.phone = "Telefon je obavezno polje.";
    }

    return errors;
  };
  const handleSave = async () => {
    const errors = validateFields();
    setValidationErrors(errors);

    if (Object.values(errors).every((error) => error === "")) {
      if (selectedSureVote) {
        setSureVote((prevState) => ({
          ...prevState,
          sureVoteRequest: {
            ...prevState.sureVoteRequest,
            id: selectedSureVote.id,
          },
        }));
      }

      const sureVoteForSave = { ...sureVote };
      if (sureVote.sureVoteRequest.birthDate === "") {
        sureVoteForSave.sureVoteRequest.birthDate = "01-01-1900";
      }

      await dispatch(saveSureVote(sureVoteForSave));
      setIsSureVoteValidated(false);
      setIsOpenValidateSureVoteModal(false);
      var nameField = document.getElementById("sure-vote-name-field");
      nameField?.focus();

      setPreviousSureVote(null);

      setSureVote((prevState) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          birthDate: "",
        },
      }));
    }
  };

  const handleRejectButton = () => {
    setSelectedVoteIndex(null);
    dispatch(resetValidateSureVote());
    dispatch(resetActivists());
    dispatch(resetSureVotesByActivist());

    if (previousSureVote) {
      setIsOpenValidateSureVoteModal(false);
      setSureVote(previousSureVote);
      return;
    }

    resetSureVoteState();
    setSelectedStreet(null);
    setPlace(null);
    setIsSureVoteValidated(false);
    setIsOpenWarningSureVote(false);

    setTimeout(() => {
      var nameField = document.getElementById("sure-vote-name-field");
      nameField?.focus();
    }, 100);
  };

  useEffect(() => {
    if (selectedSureVote) {
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        sureVoteRequest: {
          ...prevSureVote.sureVoteRequest,
          name: selectedSureVote.name,
          surname: selectedSureVote.surname,
          cityId: selectedSureVote.cityId,
          streetName: selectedSureVote.streetName,
          streetId: selectedSureVote.streetId,
          homeNumber: selectedSureVote.homeNumber,
          phone: selectedSureVote.phone,
          electionPlaceId: selectedSureVote.electionPlaceId,
        },
      }));
    } else {
      resetSureVoteState();
    }
  }, [selectedSureVote]);

  const resetSureVoteState = () => {
    var newInitSureVote = {
      ...INIT_SURE_VOTE,
      activistId: sureVote.activistId,
      activityId: sureVote.activityId,
    };
    setSureVote(newInitSureVote);
  };

  const resetFullSureVoteState = () => {
    setSureVote(INIT_SURE_VOTE);
    setPreviousSureVote(INIT_SURE_VOTE);
    setPlace(null);
    setSelectedStreet(null);
  };

  useEffect(() => {
    if (sureVoteStatus === "Fulfilled") {
      toast.success("Sigurni glas je uspešno dodat!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetSureVoteStatus());
          setSelectedVoteIndex(null);
          dispatch(resetValidateSureVote());
        },
      });
    }
  }, [sureVoteStatus]);

  useEffect(() => {
    if (
      existingSureVote &&
      existingSureVote.id !== "00000000-0000-0000-0000-000000000000" &&
      validateSureVotes?.isPossibleDuplicate !== false
    ) {
      setIsOpenWarningSureVote(true);
    } else {
      setIsOpenWarningSureVote(false);
      dispatch(resetValidateSureVote());
    }
  }, [existingSureVote]);

  useEffect(() => {
    if (
      validateSureVotes &&
      validateSureVotes.isPossibleDuplicate === false &&
      validateSureVotes.sureVotes.length !== 0
    ) {
      setIsOpenValidateSureVoteModal(true);
      setPreviousSureVote(sureVote);
    } else {
      setIsOpenValidateSureVoteModal(false);
      dispatch(resetValidateSureVote());
    }
  }, [validateSureVotes]);

  // useEffect(() => {
  //   if (validateSureVotes?.isPossibleDuplicate !== false) {
  //     setIsOpenWarningSureVote(true);
  //   } else {
  //     setIsOpenWarningSureVote(false);
  //     dispatch(resetValidateSureVote());
  //   }
  // }, [validateSureVotes]);

  // useEffect(() => {
  //   if (validateSureVotes?.isPossibleDuplicate === true) {
  //     setIsOpenValidateSureVoteModal(true);
  //   } else {
  //     setIsOpenValidateSureVoteModal(false);
  //     dispatch(resetValidateSureVote());
  //   }
  // }, [validateSureVotes]);

  useEffect(() => {
    if (sureVoteStatus === "Fulfilled") {
      setTimeout(() => {
        resetSureVoteState();
        setSelectedStreet(null);
        const nameField = document.getElementById("sure-vote-name-field");
        nameField?.focus();
      }, 0);
    }
  }, [sureVoteStatus]);

  // const handleOnKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>, id: string) => {
  //   if(event.key === 'Enter') {
  //     switch (id) {
  //       case 'sure-vote-save-btn':
  //         var surnameField = document.getElementById('sure-vote-name-field');
  //         surnameField?.focus();
  //         break;
  //     }
  //   }
  // };

  return (
    <>
      <Box
        sx={{ boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)", padding: "10px" }}
      >
        <Grid item md={12} mb={1}>
          <Typography sx={{ textAlign: "left" }}>
            Uređivanje sigurnih glasova
          </Typography>
        </Grid>
        <Grid item md={12}>
          <ActivityDataAccordion
            handleOpenActivistModal={() => setIsOpenActivistModal(true)}
            handleOpenActivityModal={() => setIsOpenActivityModal(true)}
            setSureVote={setSureVote}
            sureVote={sureVote}
            sureVotes={sureVotes}
            selectedSureVote={selectedSureVote}
            setCanAddSureVote={setCanAddSureVote}
            resetSureVoteState={resetFullSureVoteState}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
            selectedActivists={selectedActivists}
            setSelectedActivists={setSelectedActivists}
          />
        </Grid>
        {/* <Grid item md={12} mt={2}>
          <MembershipAccordion />
        </Grid> */}
        <Grid item md={12} mt={2}>
          <PersonalDataInformation
            setSureVote={setSureVote}
            sureVote={sureVote}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            canAddSureVote={canAddSureVote}
            selectedActivity={selectedActivity}
            selectedActivist={selectedActivists}
          />
        </Grid>
        <Grid item md={12} mt={2}>
          <AddressSection
            setSureVote={setSureVote}
            sureVote={sureVote}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            canAddSureVote={canAddSureVote}
            place={place}
            selectedStreet={selectedStreet}
            setPlace={setPlace}
            setSelectedStreet={setSelectedStreet}
          />
        </Grid>
        <Grid item md={12} mt={2}>
          <ContactSection
            setSureVote={setSureVote}
            sureVote={sureVote}
            canAddSureVote={canAddSureVote}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        </Grid>
        <Grid container spacing={1} mt={1} justifyContent={"flex-end"}>
          <Grid item>
            {CanUpdateSureVote(sureVote) === true && (
              <AppButton
                label="Izbriši"
                color={"rgba(255, 73, 71, 0.8)"}
                hover={"rgba(255, 73, 71, 0.8)"}
              />
            )}
          </Grid>
          <Grid item>
            <AppButton
              id="sure-vote-save-btn"
              label="Sačuvaj"
              color={"#4CAF50"}
              hover={"#4CAF50"}
              onClick={handleSave}
              startIcon={
                sureVoteStatus === "Pending" && (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={15}
                  />
                )
              }
              disabled={
                sureVoteStatus === "Pending" ||
                !selectedActivists ||
                !selectedActivity
              }
            />
          </Grid>
        </Grid>
      </Box>
      <AddUpdateActivistModal
        open={isOpenActivistModal}
        handleClose={() => setIsOpenActivistModal(false)}
      />
      <AddUpdateActivityModal
        handleClose={() => setIsOpenActivityModal(false)}
        open={isOpenActivityModal}
      />
      <WarningSureVoteModal
        handleRejectButton={handleRejectButton}
        handleClose={() => {
          setIsOpenWarningSureVote(false);
          dispatch(resetValidateSureVote());
        }}
        onRejectButtonClick={handleRejectButton}
        open={isOpenWarningSureVote}
        sureVoteName={existingSureVote?.name || ""}
        sureVoteLastName={existingSureVote?.surname || ""}
        sureVoteBirthDate={existingSureVote?.birthDate || ""}
        sureVoteCityName={existingSureVote?.cityName || ""}
        sureVoteStreetName={existingSureVote?.streetName || ""}
        sureVoteStreetNumber={existingSureVote?.homeNumber || ""}
        sureVoteActivist={existingSureVote?.activistDisplayName || ""}
      />
      <ValidateSureVotesModal
        handleRejectButton={handleRejectButton}
        handleClose={() => {
          setIsOpenWarningSureVote(false);
          dispatch(resetValidateSureVote());
        }}
        open={isOpenValidateSureVoteModal}
        sureVotes={validateSureVotes?.sureVotes}
        setSureVote={setSureVote}
        sureVote={sureVote}
        handleSave={handleSave}
        selectedVoteIndex={selectedVoteIndex}
        setSelectedVoteIndex={setSelectedVoteIndex}
      />
    </>
  );
}
