import { Grid, Pagination } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useViewAndPrintStyles } from "./useViewAndPrintStyles";

type Props = {
  totalCount: number;
  page: number;
  handleChangePage: any;
  currentPage: number;
  setCurrentPage: any;
};

const TablePagination = (props: Props) => {
  const { classes } = useViewAndPrintStyles()
  const theme = useTheme();

  const handlePagination = (e: any) => {
    const paginationIcon = e.target.getAttribute('data-testid');
    if(paginationIcon) {
        switch (paginationIcon) {
            case 'LastPageIcon':
                props.setCurrentPage(props.totalCount);
                props.handleChangePage(props.totalCount);
                break;
            case 'FirstPageIcon':
                props.setCurrentPage(1);
                props.handleChangePage(1);
                break;
            case 'NavigateNextIcon':
                if(props.totalCount > props.currentPage) {
                props.handleChangePage(props.currentPage+1);
                props.setCurrentPage(props.currentPage+1);
                }
                break;
            case 'NavigateBeforeIcon':
                if(props.currentPage>1) {
                props.handleChangePage(props.currentPage-1);
                props.setCurrentPage(props.currentPage-1);
                } 
                break;
        }
    } else {
        props.setCurrentPage(+e.target.innerText);
        props.handleChangePage(+e.target.innerText);
    }
  };

  return (
    <Grid md={12} xs={12}>
      <ThemeProvider theme={theme}>
        <Pagination
          className={classes.pagination}
          count={props.totalCount}
          page={props.page}
          onChange={(e:any) => handlePagination(e)}
          variant="outlined"
          showFirstButton
          showLastButton
        />
      </ThemeProvider>
    </Grid>
  );
};

export default TablePagination;