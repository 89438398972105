import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../slices/auth/authSlice";
import themeReducer from "../slices/theme/themeSlice";
import addressReducer from "../slices/addressSlice/addressSlice"
import snackbarReducer from "../slices/notificationSlice/notificationSlice"
import sureVotesReducer from "../slices/sureVotesSlice/sureVotesSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    address: addressReducer,
    snackbar: snackbarReducer,
    sureVotes: sureVotesReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
