import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import BasicInput from "../../basicInput/basicInput";
import AppButton from "../../basicButton/basicButtton";
import CustomDatePicker from "../../customDatePicker/customDatePicker";
import ActivityModel from "../../../models/activityModel/activityModel";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { INIT_ACTIVITY } from "../../../initData/initData";
import { useAddUpdateActivityModalStyles } from "./addUpdateActivityModalStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getActivities,
  resetActivistStatus,
  resetActivityStatus,
  saveActivity,
  selectActivities,
  selectActivityStatus,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import { toast } from "react-toastify";
import { ApiStatus } from "../../../models/app/apiStatus";
import { validateDate } from "../../../utilities/dateUtility";

interface Props {
  handleClose: () => void;
  open: boolean;
}

export default function AddUpdateActivityModal({ handleClose, open }: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useAddUpdateActivityModalStyles();
  const activities = useAppSelector(selectActivities);
  const activityStatus = useAppSelector(selectActivityStatus);

  const [validationErrors, setValidationErrors] = useState({
    startDate: "",
    endDate: "",
  });

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [activity, setActivity] = useState<ActivityModel>(INIT_ACTIVITY);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    if (name === "startDate" || name === "endDate") {
      value = value.replace(/\D/g, "");
      if (value.length >= 2) {
        value = value.substring(0, 2) + "-" + value.substring(2);
      }
      if (value.length >= 5) {
        value = value.substring(0, 5) + "-" + value.substring(5);
      }
      if (value.length > 10) {
        value = value.substring(0, 10);
      }
    }
    setActivity((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);
    if (value) {
      setActivity((prevActivity: ActivityModel) => ({
        ...prevActivity,
        id: value.id,
        name: value.name,
        startDate: value.startDate,
        endDate: value.endDate,
      }));
    } else {
      setActivity(INIT_ACTIVITY);
    }
  };

  const handleSaveActivity = () => {
    if (selectedActivity && selectedActivity.id) {
      dispatch(saveActivity(activity)).then((action) => {
        if (action.payload) {
          setSelectedActivity(action.payload);
        }
      });
    } else {
      dispatch(saveActivity(activity)).then((action) => {
        if (action.payload) {
          setSelectedActivity(action.payload);
          dispatch(getActivities());
        }
      });
    }
  };

  const handleDateBlur = <K extends keyof ActivityModel>(
    e: any,
    fieldName: K
  ) => {
    const value = activity[fieldName] || ""; 
    // const isValidDate = validateDate(value);

    if (!value) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    // } else if (!isValidDate) {
    //   setValidationErrors((prevErrors: any) => ({
    //     ...prevErrors,
    //     [fieldName]: "Unesite datum u formatu dd-mm-yyyy.",
    //   }));
    // } else {
    //   setValidationErrors((prevErrors: any) => ({
    //     ...prevErrors,
    //     [fieldName]: "",
    //   }));
    }
  };

  const resetActivityState = () => {
    setActivity(INIT_ACTIVITY);
    setSelectedActivity(null);
  };

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-name-field":
          var surnameField = document.getElementById("sure-vote-surname-field");
          surnameField?.focus();
          break;
        case "sure-vote-surname-field":
          var birthDateField = document.getElementById(
            "sure-vote-birthDate-field"
          );
          birthDateField?.focus();
          break;
        case "sure-vote-birthDate-field":
          var cityField = document.getElementById("sure-vote-city-field");
          cityField?.focus();
          break;
        case "sure-vote-city-field":
          var streetField = document.getElementById("sure-vote-street-field");
          streetField?.focus();
          break;
        case "sure-vote-street-field":
          var streetNumberField = document.getElementById(
            "sure-vote-streetNumber-field"
          );
          streetNumberField?.focus();
          break;
        case "sure-vote-streetNumber-field":
          var phoneField = document.getElementById("sure-vote-phone-field");
          phoneField?.focus();
          break;
        case "sure-vote-phone-field":
          var surnameField = document.getElementById("sure-vote-save-btn");
          surnameField?.focus();
          break;
      }
    }
  };

  useEffect(() => {
    if (activityStatus === "Fulfilled") {
      if (selectedActivity?.id) {
        toast.success("Aktivnost je uspešno ažurirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetActivityStatus());
          },
        });
      } else {
        toast.success("Aktivnost je uspešno kreirana!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetActivityStatus());
            resetActivityState();
          },
        });
      }

      dispatch(getActivities());

      const timer = setTimeout(() => {
        dispatch(resetActivistStatus());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [activityStatus, dispatch, selectedActivity]);

  useEffect(() => {
    if (selectedActivity?.id) {
      setActivity((prevActivity) => ({
        ...prevActivity,
        id: selectedActivity.id,
      }));
    }
  }, [selectedActivity]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          <Grid item md={12} className={classes.dialogTitleWrapper}>
            <Typography> Upravljanje aktivnostima (glasanje)</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ padding: "10px" }}>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Postojeće aktivnosti
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className={classes.inputTitle}>
                  Postojeće aktivnosti:
                </Typography>
                <Autocomplete
                  options={activities ? activities : []}
                  getOptionLabel={(option) => option.name}
                  value={selectedActivity}
                  onChange={handleActivityChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Podaci aktivnosti
              </Typography>
            </Grid>
            <Grid item md={6}>
              <BasicInput
                label={"Naziv aktivnosti"}
                value={activity.name}
                onChange={handleFormChange}
                name="name"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Trajanje aktivnosti
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <div >
                <BasicInput
                  id={"activity-startDate-field"}
                  required
                  label={"Datum početka"}
                  value={activity.startDate}
                  onChange={handleFormChange}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    handleDateBlur(e, "startDate")
                  }
                  name="startDate"
                  error={!!validationErrors.startDate}
                  inputProps={{
                    maxLength: 10,
                    placeholder: "dd-mm-yyyy",
                  }}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-birthDate-field")
                  }
                />
                {validationErrors.startDate && (
                  <FormHelperText
                    error
                  >
                    {validationErrors.startDate}
                  </FormHelperText>
                )}
              </div>
              <Typography variant="body1" mr={1} ml={1}>
                -
              </Typography>
              <div >
                <BasicInput
                  id={"activity-endDate-field"}
                  required
                  label={"Datum završetka"}
                  value={activity.endDate}
                  onChange={handleFormChange}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    handleDateBlur(e, "endDate")
                  }
                  name="endDate"
                  error={!!validationErrors.endDate}
                  inputProps={{
                    maxLength: 10,
                    placeholder: "dd-mm-yyyy",
                  }}
                />
                {validationErrors.endDate && (
                  <FormHelperText
                    error
                  >
                    {validationErrors.endDate}
                  </FormHelperText>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={handleClose}
            label="Zatvori"
            color={"rgba(255, 73, 71, 0.8)"}
            hover={"rgba(255, 73, 71, 0.8)"}
          />
          <AppButton
            label={selectedActivity ? "Azuriraj" : "Sačuvaj"}
            color={"#4CAF50"}
            hover={"#4CAF50"}
            onClick={handleSaveActivity}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
