import * as signalR from "@microsoft/signalr";
import SynchMessage from "./models/synch/synchMessage";

const MembershipEnv = {
    Local: "https://localhost:5001/hub/",
    Prod: "https://membership.api.easymembership.net/hub/",
    Local_Prod: "https://192.168.1.101:6501/hub/surevote",
    Local_tst: "https://localhost:6501/hub/surevote"
};

const URL = MembershipEnv.Local_Prod;

class Connector {
    private static instance: Connector | null = null; // Static instance
    private connection: signalR.HubConnection;
    public onMessageReceived!: (message: SynchMessage) => void;

    private constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL)
            .withAutomaticReconnect()
            .build();

        this.connection.on("messageReceived", (message) => {
            if (this.onMessageReceived) {
                this.onMessageReceived(message);
            }
        });

        this.connection.start().catch(err => console.error(err));
    }

    public static getInstance(): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector();
        }
        return Connector.instance;
    }

    public startConnection = () => {
        this.connection.start().catch(err => console.error(err));
    };

    public stopConnection = () => {
        this.connection.stop().catch(err => console.error(err));
    };

    public newMessage = (messages: string) => {
        this.connection.send("newMessage", "foo", messages).then(() => console.log("sent"));
    };
}

export default Connector;
