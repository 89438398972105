import { LoginRequest } from "../../models/auth/loginRequest";
import { LoginResponse } from "../../models/auth/loginResponse";
import { Street } from "../../models/address/street";
import { City } from "../../models/address/city";
import {
  api_address,
  api_address_management,
  api_auth,
  api_sureVotes,
} from "./api";
import { ApiResponse } from "../../models/app/apiResponse";
import { ElectionPlace } from "../../models/address/electionPlace";
import { ElectionPlaceRegion } from "../../models/address/electionPlaceRegion";
import { State } from "../../models/address/addressManagement/state";
import { Country } from "../../models/address/addressManagement/country";
import { CityAddressManagement } from "../../models/address/addressManagement/cityAddressManagement";
import { Municipality } from "../../models/address/addressManagement/municipality";
import { Place } from "../../models/address/addressManagement/place";
import { LocalComunity } from "../../models/address/addressManagement/localComunity";
import { StreetAddressManagement } from "../../models/address/addressManagement/streetAddressManagement";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";
import StreetDetailsModel from "../../models/address/streetDetailsModel";
import { UpdateSureVotesActivistRequest } from "../../models/sureVotes/updateSureVotesActivistRequest";

export const saveState: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/state",
    request
  ) as Promise<ApiResponse<State | null>>;
};

export const saveCountry: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/country",
    request
  ) as Promise<ApiResponse<Country | null>>;
};

export const saveCity: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/city-region",
    request
  ) as Promise<ApiResponse<CityAddressManagement | null>>;
};

export const saveMunicipality: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/municipality",
    request
  ) as Promise<ApiResponse<Municipality | null>>;
};

export const savePlace: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/place",
    request
  ) as Promise<ApiResponse<Place | null>>;
};

export const saveLocalComunity: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/local-community",
    request
  ) as Promise<ApiResponse<LocalComunity | null>>;
};

export const saveStreet: any = async (request: any) => {
  return api_address_management.post(
    "/midenas-address/street",
    request
  ) as Promise<ApiResponse<StreetAddressManagement | null>>;
};

export const getAllStates: any = async () => {
    return api_address_management.get("/midenas-address/state") as Promise<
      ApiResponse<State[]>
    >;
  };
  
  export const getAllCountries: any = async () => {
    return api_address_management.get("/midenas-address/country/") as Promise<
      ApiResponse<Country[]>
    >;
  };
  
  export const getCountriesByStateId: any = async (stateId: string | null) => {
    return api_address_management.get(
      "/midenas-address/country/stateId/" + stateId
    ) as Promise<ApiResponse<Country[]>>;
  };
  
  export const getCountryByName: any = async (countryName: string | null) => {
    return api_address_management.get(
      "/midenas-address/country/name?name=" + countryName
    ) as Promise<ApiResponse<Country>>;
  };
  
  export const getCitiesByCountryId: any = async (countryId: string | null) => {
    return api_address_management.get(
      "/midenas-address/city-region/countryId/" + countryId
    ) as Promise<ApiResponse<Country[]>>;
  };
  
  export const getMunicipalitiesByCountryId: any = async (
    countryId: string | null
  ) => {
    return api_address_management.get(
      "/midenas-address/municipality/countryId/" + countryId
    ) as Promise<ApiResponse<Municipality[]>>;
  };
  
  export const getPlaceByMunicipalityId: any = async (
    municipalityId: string | null
  ) => {
    return api_address_management.get(
      "/midenas-address/place/municipalityId/" + municipalityId
    ) as Promise<ApiResponse<Place[]>>;
  };
  
  export const getPlacesByCountryId: any = async (countryId: string | null) => {
    return api_address_management.get(
      "/midenas-address/place/countryId/" + countryId
    ) as Promise<ApiResponse<Place[]>>;
  };
  
  export const getPlacesByCityId: any = async (cityId: string | null) => {
    return api_address_management.get(
      "/midenas-address/place/cityRegionId/" + cityId
    ) as Promise<ApiResponse<Place[]>>;
  };
  
  export const getLocalComunityByPlaceId: any = async (
    placeId: string | null
  ) => {
    return api_address_management.get(
      "/midenas-address/local-community/placeId/" + placeId
    ) as Promise<ApiResponse<LocalComunity[]>>;
  };
  
  export const getStreetsByPlaceId: any = async (placeId: string | null) => {
    return api_address_management.get(
      "/midenas-address/street/list-items/" + placeId
    ) as Promise<ApiResponse<StreetAddressManagement[]>>;
  };

export const getAllCities: any = async () => {
  return api_address.get("/city") as Promise<ApiResponse<City[]>>;
};

export const getStreetsByCityId: any = async (cityId: string) => {
  return api_address.get("/street/city/" + cityId) as Promise<
    ApiResponse<StreetAddressManagement[]>
  >;
};

export const getStreetById: any = async (id: string) => {
  return api_sureVotes.get("/Address/street/" + id) as Promise<
    ApiResponse<StreetDetailsModel>
  >;
};

export const getElectionPlaceRegionByCityId: any = async (cityId: string) => {
  return api_address.get(
    "/election-place-region/city-id?cityId=" + cityId
  ) as Promise<ApiResponse<ElectionPlaceRegion[]>>;
};

export const getElectionPlaceByCityId: any = async (cityId: string) => {
  return api_address.get("/election-place/city-id?cityId=" + cityId) as Promise<
    ApiResponse<ElectionPlace[]>
  >;
};

export const getElectionPlaceById: any = async (id: string) => {
  return api_address.get("/election-place/" + id) as Promise<
    ApiResponse<ElectionPlace>
  >;
};

export const electionPlaceByCityAndStreet: any = async (
  request: ElectionPlace
) => {
  return api_address.post(
    "/election-place/find-by-city-id-and-region",
    request
  ) as Promise<ApiResponse<ElectionPlace>>;
};

