import { Container, Grid } from "@mui/material";
import Header from "../components/header/header";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { login } from "../slices/auth/authSlice";
import { INIT_LOGIN_REQUEST } from "../initData/initData";
import FastSearch from "../features/fastSearch/fastSearch";

type Props = {};

export default function DashboardPage({}: Props) {
  const dispatch = useAppDispatch();


  return (
    <Container maxWidth="xl">
      <Header headerTitle="Početna"/>
      <Grid item md={12} mt={11}>
        <FastSearch />
      </Grid>
    </Container>
  );
}
