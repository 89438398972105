import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "./routes";
import SureVotesPage from "../pages/SureVotesPage";
import ViewAndPrintPage from "../pages/ViewAndPrintPage";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import { useAppSelector } from "./hooks";
import { selectCurrentAccount } from "../slices/auth/authSlice";
import { Role } from "../models/enums/roles";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import TransferSureVotesPage from "../pages/TransferSureVotesPage";
import SureVotesCorrectionPage from "../pages/SureVotesCorrectionPage";

type Props = {};

export default function AppRouter({}: Props) {
  const currentAccount = useAppSelector(selectCurrentAccount);
  return (
    <Routes>
      <Route path={AppRoutes.Login} element={<LoginPage />} />
      <Route
        path={AppRoutes.Dashboard}
        element={
          currentAccount?.role === Role.User ? (
            <UnauthorizedPage />
          ) : (
            <DashboardPage />
          )
        }
      />
      <Route path={AppRoutes.SureVotes} element={<SureVotesPage />} />
      <Route
        path={AppRoutes.ViewAndPrint}
        element={
          currentAccount?.role === Role.User ? (
            <UnauthorizedPage />
          ) : (
            <ViewAndPrintPage />
          )
        }
      />
      <Route
        path={AppRoutes.TransferSureVotes}
        element={
          currentAccount?.role === Role.User ? (
            <UnauthorizedPage />
          ) : (
            <TransferSureVotesPage />
          )
        }
      />
      <Route
        path={AppRoutes.CorrectionSureVotes}
        element={
          currentAccount?.role === Role.User ? (
            <UnauthorizedPage />
          ) : (
            <SureVotesCorrectionPage />
          )
        }
      />
      <Route path="*" element={<></>} />
    </Routes>
  );
}
