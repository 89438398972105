import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import SnackbarModel from "../../models/snackbarModel/snackbarModel";

interface SnackbarState {
  snackbar: SnackbarModel;
}
let INIT_SNACKBAR: SnackbarModel = {
  message: "",
  severity: "success",
  show: false,
};
let initialState: SnackbarState = {
  snackbar: INIT_SNACKBAR,
};
export const selectSnackbar = createSelector(
  (state: RootState) => state.snackbar.snackbar,
  (snackbar: SnackbarModel) => {
    return snackbar;
  }
);
export const setSnackbar = createAsyncThunk(
  "snackbar/set_snackbar",
  async (snackbar: SnackbarModel) => {
    return snackbar;
  }
);
export const resetSnackbar = createAsyncThunk(
  "snackbar/resetSnackbar",
  async () => {
    return false;
  }
);
const snackbarSlice = createSlice({
  name: "sanckbar",
  initialState: initialState,
  reducers: {
    updateSanckbar(state, action: PayloadAction<SnackbarState>) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSnackbar.fulfilled, (state, { payload }) => {
        state.snackbar = payload;
      })
      .addCase(resetSnackbar.fulfilled, (state, {}) => {
        state.snackbar = initialState.snackbar;
      });
  },
});
export default snackbarSlice.reducer;
