import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  children: React.ReactNode;
};

const theme = createTheme({
  typography: {
    fontSize: 11.5,
  },
});

export default function Layout({ children }: Props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          autoClose={5000}
          position="bottom-left"
          theme="colored"
        />
        {children}
      </ThemeProvider>
    </Provider>
  );
}
