import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  FormControl,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import { useActivityDataAccordion } from "../accordions/activityDataAccordion/activityDataAccordionStyles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getSureVoteById,
  resetSelectedSureVote,
  selectActivists,
  selectActivities,
  selectSelectedActivityId,
  selectSureVotesByActivist,
  setSelectedActivistId,
  setSelectedActivityId,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import { useEffect, useState } from "react";
import ActivityModel from "../../models/activityModel/activityModel";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import ActivistModel from "../../models/activistModel/activistModel";
import { EmptyGuid } from "../../initData/initData";
import { SureVoteShortDetails } from "../../models/sureVotes/sureVoteShortDetails";
import Connector from "../../appSignalR";
import SureVoteListRequest from "../../models/sureVotes/sureVoteListRequest";
import AddUpdateActivistModal from "../../components/modals/addUpdateActivistModal/addUpdateActivistModal";

interface Props {
  sureVote?: SureVoteModel;
  setSureVote: any;
  selectedActivity: any;
  handleActivityChange: any;
  handleActivistChange: any;
  selectedActivists: any;
}

export default function ActivitiesSection({
  sureVote,
  setSureVote,
  handleActivityChange,
  selectedActivity,
  handleActivistChange,
  selectedActivists,
}: Props) {
  const dispatch = useAppDispatch();

  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);

  const sureVoteByActivist = useAppSelector(selectSureVotesByActivist);
  const selectedActivityId = useAppSelector(selectSelectedActivityId);

  const [selectedSureVoteShortDetails, setSelectedSureVoteShortDetails] =
    useState<SureVoteShortDetails | null>(null);

  const [isOpenActivistModal, setIsOpenActivistModal] = useState(false);

  const { classes } = useActivityDataAccordion();

  const handleSureVoteShortDetailsChange = (
    event: React.ChangeEvent<{}>,
    value: SureVoteShortDetails | null
  ) => {
    setSelectedSureVoteShortDetails(value);
    if (value) {
      dispatch(getSureVoteById(value?.id || EmptyGuid));
    } else {
      dispatch(resetSelectedSureVote());
    }
  };

  const handleOpenActivistModal = () => setIsOpenActivistModal(true);

  // useEffect(() => {
  //   if (selectedActivityId) {
  //     var request: SureVoteListRequest = {
  //       activityId: selectedActivityId,
  //     };
  //     dispatch(getListSureVotes(request));
  //   }
  // }, [selectedActivityId]);

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ background: "#f5f5f5" }}
        >
          <Typography sx={{ textAlign: "left" }}>Podaci aktivnosti</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item md={12} sx={{}} mt={1}>
            <Grid container spacing={1} mt={1}>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Aktivnost
                  </Typography>
                  <Autocomplete
                    options={activities ? activities : []}
                    getOptionLabel={(option) => option.name}
                    value={selectedActivity}
                    onChange={handleActivityChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Aktivista
                  </Typography>
                  <Autocomplete
                    options={activists ? activists : []}
                    getOptionLabel={(option) =>
                      option.lastName + " " + option.firstName
                    }
                    value={selectedActivists}
                    onChange={handleActivistChange}
                    disabled={selectedActivity === null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
                <Link
                  onClick={handleOpenActivistModal}
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Upravljaj aktivistima
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AddUpdateActivistModal
        open={isOpenActivistModal}
        handleClose={() => setIsOpenActivistModal(false)}
      />
    </div>
  );
}
