import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useHeaderStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    width: "100%",
    background: "#2f4454",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  userSettings: {
    flexGrow: 1,
    padding: "5px",
    fontSize: "16px",
    color: "#A2A89A",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  grow: {
    flexGrow: 1,
  },
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  menuIcon: {
    cursor: "pointer",
    color: "white",
  },
  sidebarIcon: {
    color: "white",
  },
  toolbar: {
    background: "#849BA4",
    width: "100%",
  },
  logo: {
    width: "150px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  logout_btn: {
    color: "#2f4454",
    textTransform: "capitalize",
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: "10px",
    },
  },
  logout_btn_disabled: {
    color: "#2f4454",
    textTransform: "capitalize",
    background: "#cccccc",
    "&:hover": {
      background: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: "10px",
    },
  },
  iconButton: {
    float: "right",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      float: "right",
    },
  },
  menuGridIcon: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "-10px",
    marginRight: "2px",
  },
  menuGridTypography: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "5px",
  },
  menuTypographyName: {
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#2f4454",
  },
  menuTypographyRole: {
    textTransform: "uppercase",
    color: "#6D7C87",
  },
  listItemText: {
    color: "white",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  divider: {
    backgroundColor: "white",
    opacity: 0.5,
  },
  dividerMargin: {
    background: "#9C9C9C",
    marginBottom: "8px",
  },
  menuItem: {
    padding: "6px 12px",
    "&:not(:last-child)": {
      marginBottom: 8,
    },
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  background: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "grey",
    clipPath:
      "polygon(50% 0, 100% 0, 100% 100%, 100% 100%, 50% 100%, 0 100%, 0 100%, 0 0)",
    height: "100%",
    width: "100%",
    marginTop: "-45px",
    zIndex: -1,
  },
  logoAvatar: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "3px",
    borderRadius: "50%",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0)",
    width: "85px",
    height: "85px",
    marginTop: "10px",
  },
  sidebarTitle: {
    flexGrow: 1,
    textAlign: "center",
    color: "#2f4454",
  },
  listItemIcon: {
    marginRight: "-20px",
  },
}));
