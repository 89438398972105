import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicInput from "../../components/basicInput/basicInput";
import { colors } from "../../app/theme/colors";
import { FastSearchRequest } from "../../models/sureVotes/fastSearchRequest";
import { useEffect, useState } from "react";
import { INIT_FAST_SEARCH } from "../../initData/initData";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fastSearchSureVote,
  resetFastSearch,
  selectFastSearchResults,
  selectFastSearchStatus,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import { SureVoteRequest } from "../../models/sureVotes/sureVoteRequest";
import { alpha, styled } from "@mui/material/styles";
import { useFastSearchStyles } from "./fastSearchStyles";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.primary,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function FastSearch() {
  const dispatch = useAppDispatch();
  const { classes } = useFastSearchStyles();

  const fastSearchResults = useAppSelector(selectFastSearchResults);
  const fastSearchStatus = useAppSelector(selectFastSearchStatus);
  const [fastSearch, setFastSearch] =
    useState<FastSearchRequest>(INIT_FAST_SEARCH);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFastSearch((prevFastSearch) => ({
      ...prevFastSearch,
      [name]: value,
    }));

    if (
      (name === "name" && value.length === 0) ||
      (name === "surname" && value.length === 0)
    ) {
      dispatch(resetFastSearch());
    }
  };

  const groupedResults: { [key: string]: SureVoteRequest[] } = {};

  if (fastSearchResults) {
    fastSearchResults.forEach((result) => {
      const displayName = result.activityDisplayName;
      if (displayName) {
        if (!groupedResults[displayName]) {
          groupedResults[displayName] = [];
        }
        groupedResults[displayName].push(result);
      }
    });
  }

  useEffect(() => {
    if (
      (fastSearch.name && fastSearch.name.length >= 3) ||
      (fastSearch.surname && fastSearch.surname.length >= 3)
    ) {
      dispatch(fastSearchSureVote(fastSearch));
    } else {
      dispatch(resetFastSearch());
    }
  }, [fastSearch.name, fastSearch.surname]);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <BasicInput
            name="name"
            label={"Ime"}
            value={fastSearch.name}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <BasicInput
            name="surname"
            label={"Prezime"}
            value={fastSearch.surname}
            onChange={handleFormChange}
          />
        </Grid>
      </Grid>
      {fastSearchStatus === "Pending" ? (
        <Grid container justifyContent={"center"} alignItems={"center"} mt={2}>
          <CircularProgress className={classes.circularProgress} />
        </Grid>
      ) : (
        <Grid container mt={2}>
          <Grid item md={12} xs={12}>
            <Accordion
              defaultExpanded={fastSearchResults !== null}
              key={"accordion_fast_search"}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-summary_fast-search"
                sx={{ background: "#f5f5f5" }}
              >
                <Typography sx={{ textAlign: "left" }}>
                  Rezultati brze pretrage
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.keys(groupedResults).map(
                  (activityDisplayName, index) => (
                    <Accordion key={index}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ textAlign: "left" }}>
                          {activityDisplayName === "Membership"
                            ? "Članstvo"
                            : activityDisplayName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table key={index}>
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell>Prezime i ime</StyledTableCell>
                                <StyledTableCell>Datum rođenja</StyledTableCell>
                                <StyledTableCell>Opština</StyledTableCell>
                                <StyledTableCell>Adresa</StyledTableCell>
                                <StyledTableCell>Telefon</StyledTableCell>
                                <StyledTableCell>Aktivista</StyledTableCell>
                                <StyledTableCell>Datum kreiranja</StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody key={index}>
                              {groupedResults[activityDisplayName].map(
                                (result) => (
                                  <StyledTableRow key={result.id}>
                                    <TableCell>
                                      {result.surname} {result.name}
                                    </TableCell>
                                    <TableCell>{result.birthDate}</TableCell>
                                    <TableCell>
                                      {result.municipalityName}
                                    </TableCell>
                                    <TableCell>
                                      {result.cityName}, {result.streetName},{" "}
                                      {result.homeNumber}
                                    </TableCell>
                                    <TableCell>{result.phone}</TableCell>
                                    <TableCell>
                                      {result.activistDisplayName}
                                    </TableCell>
                                    <TableCell>
                                      {result.createdDate}
                                    </TableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
