import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AppButton from "../../button/AppButton";
import { useEffect, useState } from "react";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  saveSureVote,
  selectSureVoteStatus,
} from "../../../slices/sureVotesSlice/sureVotesSlice";

interface Props {
  handleClose: any;
  open: boolean;
  sureVotes: any;
  handleRejectButton: any;
  sureVote: SureVoteModel;
  setSureVote: any;
  handleSave: any;
  selectedVoteIndex?: any;
  setSelectedVoteIndex?: any;
}

export default function ValidateSureVotesModal({
  handleClose,
  open,
  sureVotes,
  handleRejectButton,
  setSureVote,
  handleSave,
  selectedVoteIndex,
  setSelectedVoteIndex,
}: Props) {
  const dispatch = useAppDispatch();
  const sureVoteStatus = useAppSelector(selectSureVoteStatus);


  const handleCheckboxChange = (index: number) => {
    if (selectedVoteIndex === null || selectedVoteIndex !== index) {
      setSelectedVoteIndex(index);
  
      const selectedSureVote = sureVotes[index];
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        sureVoteRequest: {
          ...prevSureVote.sureVoteRequest,
          name: selectedSureVote.name,
          surname: selectedSureVote.surname,
          cityId: selectedSureVote.cityId,
          cityName: selectedSureVote.cityName,
          streetName: selectedSureVote.streetName,
          streetId: selectedSureVote.streetId,
          homeNumber: selectedSureVote.homeNumber,
          phone: selectedSureVote.phone,
          birthDate: selectedSureVote.birthDate,
          electionPlaceId: selectedSureVote.electionPlaceId,
          municipalityName: selectedSureVote.municipalityName,
        },
      }));
    } else {
      setSelectedVoteIndex(null);
      setSureVote((prevState: any) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          name: "",
          surname: "",
          cityId: "",
          cityName: "",
          streetName: "",
          streetId: "",
          homeNumber: "",
          phone: "",
          birthDate: "",
          electionPlaceId: "",
          municipalityName: "",
        },
      }));
    }
  };
  
  function mapActivityDisplayName(displayName: string) {
    switch (displayName) {
      case "Membership":
        return "Članstvo";
      default:
        return displayName;
    }
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Postojeci podaci</DialogTitle>
      <DialogContent sx={{ padding: "10px" }}>
        <Grid item md={12} mb={1}>
          <Typography>U bazi postoj postojeći podaci:</Typography>
        </Grid>
        {sureVotes &&
          sureVotes.map((sureVote: any, index: number) => (
            <Grid
              container
              sx={{ padding: "5px", alignItems: "center" }}
              spacing={1}
            >
              <Checkbox
                checked={selectedVoteIndex === index}
                onChange={() => handleCheckboxChange(index)}
              />
              <Typography>
                {sureVote && sureVote.name} {sureVote.surname},{" "}
                {sureVote.cityName}, {sureVote.streetName},{" "}
                {sureVote.homeNumber}, {sureVote.phone},{" "}
                {mapActivityDisplayName(sureVote.activityDisplayName)}
              </Typography>
            </Grid>
          ))}
        <Grid item lg={12} md={12} sm={12} xs={12} marginTop={3}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            textAlign={"right"}
            display={"flex"}
            justifyContent={"right"}
          >
            <Grid item>
              <AppButton
                label={"Odbaci"}
                color={"rgba(255, 73, 71, 0.8)"}
                hover={"rgba(255, 73, 71, 0.8)"}
                onClick={() => {
                  handleRejectButton();
                }}
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label={"Prihvati"}
                color={"#4CAF50"}
                hover={"#4CAF50"}
                onClick={handleSave}
                disabled={selectedVoteIndex === null}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
