import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useTransferSureVotesStyles = makeStyles()(
  (theme: Theme) => ({
    autocompleteTextField: {
      "& .MuiInputBase-root": {
        borderRadius: "5px",
        background: "white",
      },
    },
    autocomplete: {
      "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
        //   borderColor: colors.sectionTitle,
        },
      },
    },
    inputTitle: { textAlign: "start" },
   
    checkboxWhite: {
      color: "white !important"
    },
    
    checkboxPrimary: {
      color: `${colors.primary} !important`
    },
    smallCheckbox: {
      padding: 3, 
    },
  })
);
