import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useFastSearchStyles = makeStyles()(
  (theme: Theme) => ({
    deleteIcon: {
      color: colors.red,
    },
    circularProgress:{
        color: colors.primary
    }
  })
);
