import MenuIcon from "@mui/icons-material/Menu";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderStyles } from "./headerStyles";
import { AppRoutes } from "../../app/routes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllCities } from "../../slices/addressSlice/addressSlice";
import {
  logout,
  selectCurrentAccount,
  selectLoginResponse,
} from "../../slices/auth/authSlice";
import {
  getActivistsByActivityId,
  getActivities,
  resetSelectedActivistId,
  resetSelectedActivityId,
  resetSureVoteList,
  selectSelectedActivityId,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import Connector from "../../appSignalR";
import { EmptyGuid } from "../../initData/initData";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import { Role } from "../../models/enums/roles";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  title?: string | null;
  headerTitle?: string;
}

type Anchor = "left";

export default function Header({ title, headerTitle }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const loginResponse = useAppSelector(selectLoginResponse);

  const { classes } = useHeaderStyles();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const selectedActivityId = useAppSelector(selectSelectedActivityId);
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [refreshCurrentAccount, setRefreshCurrentAccount] = useState();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  // const onLogout = () => {
  //   dispatch(logout());
  //   navigate(AppRoutes.Login);
  // };

  useEffect(() => {
    dispatch(getActivities());
    dispatch(getAllCities());

    const activistConnector = Connector.getInstance();
    activistConnector.onMessageReceived = (message) => {
      //if(message.shouldUpdateActivist) {
      dispatch(getActivistsByActivityId(selectedActivityId || EmptyGuid));
      // }
    };
  }, []);
  const onLogout = async () => {
    await dispatch(logout());
    navigate(AppRoutes.Login);
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ background: "#2f4454", height: "100vh", width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <List style={{ flexGrow: 1 }}>
          {currentAccount?.role !== Role.User && (
            <>
              <ListItem
                button
                onClick={() => {
                  navigate(AppRoutes.Dashboard);
                  dispatch(resetSureVoteList());
                  dispatch(resetSelectedActivityId());
                  dispatch(resetSelectedActivistId());
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <DashboardOutlinedIcon className={classes.sidebarIcon} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  Početna
                </ListItemText>
              </ListItem>
              <Divider variant="middle" className={classes.divider} />
            </>
          )}

          <ListItem
            button
            onClick={() => {
              //dispatch(getAllStates());
              dispatch(getAllCities());
              //dispatch(getSureVotes());
              dispatch(getActivities());
              //dispatch(getActivists());
              navigate(AppRoutes.SureVotes);
              dispatch(resetSureVoteList());
              dispatch(resetSelectedActivityId());
              dispatch(resetSelectedActivistId());
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountCircleOutlinedIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Uređivanje glasova
            </ListItemText>
          </ListItem>
          {currentAccount?.role !== Role.User && (
            <>
              <Divider variant="middle" className={classes.divider} />
              <ListItem
                button
                onClick={() => {
                  navigate(AppRoutes.TransferSureVotes);
                  dispatch(resetSureVoteList());
                  dispatch(resetSelectedActivityId());
                  dispatch(resetSelectedActivistId());
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <MoveDownIcon className={classes.sidebarIcon} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  Prenos glasova
                </ListItemText>
              </ListItem>
            </>
          )}
          {currentAccount?.role !== Role.User && (
            <>
              <Divider variant="middle" className={classes.divider} />
              <ListItem
                button
                onClick={() => {
                  navigate(AppRoutes.ViewAndPrint);
                  dispatch(resetSureVoteList());
                  dispatch(resetSelectedActivityId());
                  dispatch(resetSelectedActivistId());
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <PermIdentityOutlinedIcon className={classes.sidebarIcon} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  Pregled i štampa
                </ListItemText>
              </ListItem>
            </>
          )}
          {currentAccount?.role !== Role.User && (
            <>
              <Divider variant="middle" className={classes.divider} />
              <ListItem
                button
                onClick={() => {
                  navigate(AppRoutes.CorrectionSureVotes);
                  dispatch(resetSureVoteList());
                  dispatch(resetSelectedActivityId());
                  dispatch(resetSelectedActivistId());
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AutoFixNormalIcon className={classes.sidebarIcon} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  Korekcija sig. glas.
                </ListItemText>
              </ListItem>
            </>
          )}
          <>
            <Divider variant="middle" className={classes.divider} />
            <Link href="https://clanstvo.rs/dashboard" rel="noreferrer" sx={{textDecoration: "none"}}>
              <ListItem
                button
                onClick={() => {
                  navigate(AppRoutes.CorrectionSureVotes);
                  dispatch(resetSureVoteList());
                  dispatch(resetSelectedActivityId());
                  dispatch(resetSelectedActivistId());
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ArrowBackIcon className={classes.sidebarIcon} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  Članstvo
                </ListItemText>
              </ListItem>
            </Link>
          </>
          <Divider variant="middle" className={classes.divider} />
          <ListItem
            style={{ position: "absolute", bottom: 0 }}
            button
            onClick={onLogout}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LogoutIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Odjavi se
            </ListItemText>
          </ListItem>
          <Divider variant="middle" className={classes.divider} />
        </List>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              {(["left"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                  <MenuIcon
                    id="header_menu-icon"
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.menuIcon}
                    style={{ marginRight: "16px" }}
                  />
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
              <Typography
                variant="h6"
                style={{ textAlign: "center", width: "100%" }}
              >
                {headerTitle}
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
