export enum Role {
  SuperUser = "SuperUser",
  Administrator = "Administrator",
  User = "User",
}

export enum RoleOrdinal {
  SuperUser = 2,
  Administrator = 3,
  User = 4,
}
