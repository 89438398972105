import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Autocomplete,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled as muiStyled,
  Popover,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useViewAndPrintStyles } from "./useViewAndPrintStyles";
import AppButton from "../button/AppButton";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  deleteSureVote,
  getActivistById,
  getActivistsByActivityId,
  getListSureVotes,
  getSureVoteById,
  getSureVoteReport,
  resetDeleteSureVoteStatus,
  resetReportStatus,
  resetSelectedActivistId,
  resetSelectedActivityId,
  resetSureVoteByIdStatus,
  resetSureVoteList,
  resetSureVoteStatus,
  selectDeletetSureVoteStatus,
  selectReport,
  selectReportStatus,
  selectSelectedActivistId,
  selectSelectedActivityId,
  selectSelectedSureVote,
  selectSureVoteById,
  selectSureVoteByIdStatus,
  selectSureVotes,
  selectSureVoteStatus,
  setSelectedActivistId,
  setSelectedActivityId,
  updateSureVote,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import TablePagination from "./pagination";
import SureVoteListRequest from "../../models/sureVotes/sureVoteListRequest";
import { EmptyGuid } from "../../initData/initData";
import { useEffect, useState } from "react";
import UpdateSureVoteModal from "../modals/updateSureVotesModal/updateSureVotesModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "../modals/deleteConfirmationModal/deleteConfirmationModal";
import BasicInput from "../basicInput/basicInput";
import AddIcon from "@mui/icons-material/Add";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import AddSureVoteModal from "../modals/addSureVoteModal/addSureVoteModal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { colors } from "../../app/theme/colors";
import { ReportByDateRequest } from "../../models/sureVotes/reportByDateRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveAs } from "file-saver";
import ActivitiesSection from "../../features/activities/activitiesSection";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";

interface Props {
  sureVote: SureVoteModel;
  setSureVote: any;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement={props.placement}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
}));

export default function ViewAndPrint({ setSureVote, sureVote }: Props) {
  const theme = useTheme();
  const filter_fields = [
    { name: "Akcija" },
    { name: "Red. br." },
    { name: "Prezime" },
    { name: "Ime" },
    { name: "Datum rođ." },
    { name: "Telefon" },
    { name: "Opština" },
    { name: "Mesto" },
    { name: "Ulica" },
    { name: "Kućni broj" },
    { name: "Aktivista" },
    { name: "Datum kreiranja" },
  ];
  const dispatch = useAppDispatch();
  const { classes } = useViewAndPrintStyles();
  const sureVoteList = useAppSelector(selectSureVotes);

  const sureVoteStatus = useAppSelector(selectSureVoteStatus);
  const sureVoteById = useAppSelector(selectSureVoteByIdStatus);
  const selectedSureVote = useAppSelector(selectSelectedSureVote);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const selectedActivityId = useAppSelector(selectSelectedActivityId);
  const selectedActivistId = useAppSelector(selectSelectedActivistId);
  const deleteStatus = useAppSelector(selectDeletetSureVoteStatus);
  const report = useAppSelector(selectReport);
  const reportStatus = useAppSelector(selectReportStatus);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [openEditSureVote, setOpenEditSureVote] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openAddSureVoteModal, setOpenAddSureVoteModal] = useState(false);

  const [operationType, setOperationType] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [selectedVoteIndex, setSelectedVoteIndex] = useState<number | null>(
    null
  );
  const [selectedSureVoteId, setSelectedSureVoteId] = useState("");
  const [selectedSureVoteName, setSelectedSureVoteName] = useState("");
  const [selectedSureVoteSurname, setSelectedSureVoteSurname] = useState("");
  const [selectedFilterField, setSelectedFilterField] = useState<string | null>(
    null
  );
  const [selectedFilters, setSelectedFilters] = useState<
    { field: string; value: string }[]
  >([]);
  const INIT_REPORT_BY_DATE: ReportByDateRequest = {
    activityId: selectedActivityId!,
    date: "",
    toDate: "",
  };

  const [createReportByDate, setCreateReportByDate] =
    useState<ReportByDateRequest>(INIT_REPORT_BY_DATE);
  const open = Boolean(anchorEl);
  const [filterValue, setFilterValue] = useState<string | null>(null);

  const [selectedFilterFieldValue, setSelectedFilterFieldValue] = useState<{
    name: string;
  } | null>(null);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [selectedActivists, setSelectedActivists] =
    useState<ActivistModel | null>(null);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    place: "",
    street: "",
    homeNumber: "",
  });

  const validateFields = () => {
    const errors = {
      firstName: "",
      lastName: "",
      birthDate: "",
      place: "",
      street: "",
      homeNumber: "",
    };

    if (!sureVote.sureVoteRequest.name) {
      errors.firstName = "Ime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.surname) {
      errors.lastName = "Prezime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.streetId) {
      errors.street = "Ulica je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.homeNumber) {
      errors.homeNumber = "Kućni br. je obavezno polje.";
    }

    return errors;
  };

  const INIT_TABLE_FILTER: SureVoteListRequest = {
    activityId: EmptyGuid,
    activistId: null,
    sureVoteFilter: {
      names: null,
      surnames: null,
      birthDate: null,
      minBirthDate: null,
      maxBirthDate: null,
      cities: null,
      streets: null,
      homeNumbers: null,
      phones: null,
      isMember: null,
      createdDate: null,
      toCreatedDate: null,
      municipalities: null,
    },
    sort: null,
    metaDataRequest: {
      pageNumber: currentPage,
      resultsPerPage: numberOfResultsPerPage,
    },
  };
  const [pageNumber, setPageNumber] = useState(1);

  const [tableFilter, setTableFilter] = useState<SureVoteListRequest>({
    activityId: EmptyGuid,
    activistId: null,
    sureVoteFilter: {
      names: null,
      surnames: null,
      birthDate: null,
      minBirthDate: null,
      maxBirthDate: null,
      cities: null,
      streets: null,
      homeNumbers: null,
      phones: null,
      isMember: null,
      createdDate: null,
      toCreatedDate: null,
      municipalities: null,
    },
    sort: null,
    metaDataRequest: {
      pageNumber: currentPage,
      resultsPerPage: numberOfResultsPerPage,
    },
  });

  const generateInitialTableFilter = () => ({
    activityId: selectedActivityId || EmptyGuid,
    activistId: selectedActivistId,
    sureVoteFilter: {
      names: null,
      surnames: null,
      birthDate: null,
      minBirthDate: null,
      maxBirthDate: null,
      cities: null,
      streets: null,
      homeNumbers: null,
      phones: null,
      isMember: null,
      createdDate: null,
      toCreatedDate: null,
      municipalities: null,
    },
    sort: null,
    metaDataRequest: {
      pageNumber: currentPage,
      resultsPerPage: numberOfResultsPerPage,
    },
  });

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);
    dispatch(getActivistsByActivityId(value?.id || ""));
    if (value) {
      setSelectedActivists(null);
      dispatch(setSelectedActivityId(value.id))
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        activityId: value.id || EmptyGuid,
        activistId: null,
      }));
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activityId: value.id,
      }));
      setSelectedFilterFieldValue(null);
    } else {
      setSelectedActivists(null);
      setTableFilter(INIT_TABLE_FILTER);
      setSelectedFilterFieldValue(null);
    }
  };

  const handleActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedActivists(value);
    if (value) {
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        activistId: value?.id,
        sureVoteFilter: {
          names: null,
          surnames: null,
          birthDate: null,
          minBirthDate: null,
          maxBirthDate: null,
          cities: null,
          streets: null,
          homeNumbers: null,
          phones: null,
          isMember: null,
          createdDate: null,
          toCreatedDate: null,
          municipalities: null,
        },
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      }));
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activistId: value.id,
      }));
      dispatch(setSelectedActivistId(value?.id || null));
      dispatch(getActivistById(value?.id!));
      setSelectedFilterField(null);
      setSelectedFilterFieldValue(null);
    } else {
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        activistId: null,
        sureVoteFilter: {
          names: null,
          surnames: null,
          birthDate: null,
          minBirthDate: null,
          maxBirthDate: null,
          cities: null,
          streets: null,
          homeNumbers: null,
          phones: null,
          isMember: null,
          createdDate: null,
          toCreatedDate: null,
          municipalities: null,
        },
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      }));

      setSelectedFilterFieldValue(null);
      setSelectedFilterField(null);
      setFilterValue("");
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    setTableFilter((prevFilter) => ({
      ...prevFilter,
      metaDataRequest: {
        pageNumber: 1,
        resultsPerPage: numberOfResultsPerPage,
      },
    }));
    dispatch(getListSureVotes(tableFilter));
  }, [selectedActivity, selectedActivists]);

  const handleFieldChange = (event: any, newValue: any) => {
    if (newValue) {
      setSelectedFilterField(newValue.name);
      setSelectedFilterFieldValue(newValue);
    } else {
      setSelectedFilterField(null);
      const initialFilter = generateInitialTableFilter();
      setTableFilter(initialFilter);
      setSelectedFilterFieldValue(null);
      setFilterValue("");
    }
  };
  const handleFormChange = (e: any) => {
    const fieldName = selectedFilterField;
    const updateTableFilter = { ...tableFilter };
    let { name, value } = e.target;
    if (name === "toDate" || name === "fromDate") {
      const cursorPosition = e.target.selectionStart || 0;
      if (
        e.nativeEvent instanceof InputEvent &&
        e.nativeEvent.inputType === "deleteContentBackward"
      ) {
        if (cursorPosition > 0) {
          value =
            value.slice(0, cursorPosition - 1) + value.slice(cursorPosition);
        }
      } else {
        value = value.replace(/\D/g, "");
        if (value.length >= 2) {
          value = value.substring(0, 2) + "-" + value.substring(2);
        }
        if (value.length >= 5) {
          value = value.substring(0, 5) + "-" + value.substring(5);
        }
        if (value.length > 10) {
          value = value.substring(0, 10);
        }
      }
    }
    switch (fieldName) {
      case "Ime":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.names = [value];
        }
        break;
      case "Prezime":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.surnames = [value];
        }
        break;
      case "Telefon":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.phones = [value];
        }
        break;
      case "Opština":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.municipalities = [value];
        }
        break;
      case "Mesto":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.cities = [value];
        }
        break;
      case "Ulica":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.streets = [value];
        }
        break;
      case "Kućni broj":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.homeNumbers = [value];
        }
        break;

      case "Ulica":
        if (updateTableFilter.sureVoteFilter) {
          updateTableFilter.sureVoteFilter.streets = [value];
        }
        break;
      case "Datum kreiranja":
        if (updateTableFilter.sureVoteFilter) {
          if (e.target.id === "sure-vote-filter-value-field-from-date") {
            setFromDate(value);

            updateTableFilter.sureVoteFilter.createdDate = value;
          } else if (e.target.id === "sure-vote-filter-value-field-to-date") {
            setToDate(value);

            updateTableFilter.sureVoteFilter.toCreatedDate = value;
          }
        }
        break;

      case "Datum kreiranja":
        if (updateTableFilter.sureVoteFilter) {
          if (e.target.id === "sure-vote-filter-value-field-from-date") {
            setFromDate(value);
            setCreateReportByDate((prevReport: ReportByDateRequest) => ({
              ...prevReport,
              activityId: selectedActivityId || EmptyGuid,
              date: value,
            }));
            updateTableFilter.sureVoteFilter.createdDate = value;
          } else if (e.target.id === "sure-vote-filter-value-field-to-date") {
            setToDate(value);
            setCreateReportByDate((prevReport: ReportByDateRequest) => ({
              ...prevReport,
              activityId: selectedActivityId || EmptyGuid,
              toDate: value,
            }));
            updateTableFilter.sureVoteFilter.toCreatedDate = value;
          }
        }
        break;
    }
    setFilterValue(value);
    setTableFilter(updateTableFilter);
  };

  const handleSave = () => {
    const errors = validateFields();
    setValidationErrors(errors);

    if (selectedSureVote) {
      const updatedSureVote = {
        ...sureVote,
        sureVoteRequest: {
          ...sureVote.sureVoteRequest,
          id: selectedSureVote.id,
        },
      };

      var sureVoteForSave = { ...updatedSureVote };
      if (sureVote.sureVoteRequest.birthDate === "") {
        sureVoteForSave.sureVoteRequest.birthDate = "01-01-1900";
      }

      setSureVote(updatedSureVote);

      dispatch(updateSureVote(sureVoteForSave));
    }
  };

  const handleAddFilter = () => {
    if (selectedFilterField && filterValue) {
      const newFilter = {
        field: selectedFilterField,
        value: filterValue,
      };
      setSelectedFilters([...selectedFilters, newFilter]);
      setFilterValue("");

      const updatedTableFilter = { ...tableFilter };
      dispatch(getListSureVotes(updatedTableFilter));
      setPageNumber(1);
    }
    setFilterValue("");
    setFromDate("");
    setToDate("");
  };

  const resetTableFilterState = () => {
    setTableFilter((prevFilter) => ({
      ...prevFilter,
      activityId: selectedActivity?.id || EmptyGuid,
      activistId: selectedActivists?.id,
      sureVoteFilter: {
        names: null,
        surnames: null,
        birthDate: null,
        minBirthDate: null,
        maxBirthDate: null,
        cities: null,
        streets: null,
        homeNumbers: null,
        phones: null,
        isMember: null,
        createdDate: null,
        toCreatedDate: null,
        municipalities: null,
      },
      sort: null,
      metaDataRequest: {
        pageNumber: 1,
        resultsPerPage: numberOfResultsPerPage,
      },
    }));
    setSelectedFilterFieldValue(null);
    setFilterValue("");
    setSelectedFilterField(null);
  };
  const handleRemoveFilter = () => {
    setSelectedFilters([]);
    setFilterValue(null);
    setFromDate("");
    setToDate("");
    setTableFilter((prevSureVote: SureVoteListRequest) => ({
      ...prevSureVote,
      activistId: selectedActivists?.id,
      activityId: selectedActivity?.id || EmptyGuid,
      sureVoteFilter: {
        names: null,
        surnames: null,
        birthDate: null,
        minBirthDate: null,
        maxBirthDate: null,
        cities: null,
        streets: null,
        homeNumbers: null,
        phones: null,
        isMember: null,
        createdDate: null,
        toCreatedDate: null,
        municipalities: null,
      },
    }));
    const request: SureVoteListRequest = {
      activityId: selectedActivity?.id || EmptyGuid,
      activistId: selectedActivists?.id,
      metaDataRequest: {
        pageNumber:pageNumber,
        resultsPerPage: numberOfResultsPerPage,
      },
      sureVoteFilter: {
        names: null,
        surnames: null,
        birthDate: null,
        minBirthDate: null,
        maxBirthDate: null,
        cities: null,
        streets: null,
        homeNumbers: null,
        phones: null,
        isMember: null,
        createdDate: null,
        toCreatedDate: null,
        municipalities: null,
      },
    };
    setCurrentPage(1);
    setPageNumber(pageNumber);
    dispatch(getListSureVotes(request));
    setSureVote((prevSureVote: SureVoteModel) => ({
      ...prevSureVote,
      activistId: selectedActivists?.id,
      activityId: selectedActivity?.id || EmptyGuid,
    }));
  };

  const handleChangePage = (pageNumber: number) => {
    const request: SureVoteListRequest = {
      activityId: selectedActivity?.id || EmptyGuid,
      activistId: selectedActivists?.id,
      metaDataRequest: {
        pageNumber: pageNumber,
        resultsPerPage: numberOfResultsPerPage,
      },
      sureVoteFilter: tableFilter.sureVoteFilter,
    };

    setPageNumber(pageNumber);

    dispatch(getListSureVotes(request));
  };

  const onChangePageSize = (value: number) => {
    setNumberOfResultsPerPage(value);
    setCurrentPage(1);
    const request: SureVoteListRequest = {
      activityId: selectedActivity?.id || EmptyGuid,
      activistId: selectedActivists?.id,
      metaDataRequest: {
        pageNumber: 1,
        resultsPerPage: value,
      },
    };
    dispatch(getListSureVotes(request));
  };

  const handleUpdateSureVote = (sureVoteId: string) => {
    dispatch(getSureVoteById(sureVoteId));
  };

  const handleCloseUpdateSureVoteModal = () => {
    setOpenEditSureVote(false);
    setTableFilter((prevFilter) => ({
      ...prevFilter,
      metaDataRequest: {
        pageNumber: currentPage,
        resultsPerPage: numberOfResultsPerPage,
      },
    }));
    dispatch(resetSureVoteByIdStatus());
  };

  const resetFilters = () => {
    const updatedTableFilter = { ...tableFilter };
    dispatch(getListSureVotes(updatedTableFilter));
  };

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deleteSureVote(id));
      if (selectedFilterFieldValue) {
        resetFilters();
      }

      setOpenConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting sure vote:", error);
    }
  };

  const handleCreateReport = () => {
    if (!tableFilter) {
      return;
    }

    const updatedTableFilter = { ...tableFilter };

    if (updatedTableFilter.metaDataRequest) {
      updatedTableFilter.metaDataRequest.pageNumber = currentPage;
    }

    setTableFilter(updatedTableFilter);

    dispatch(getListSureVotes(updatedTableFilter));
    dispatch(getSureVoteReport(tableFilter));
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (deleteStatus === "Fulfilled") {
      resetFilters();
      toast.success("Uspešno ste obrisali sigurni glas!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetDeleteSureVoteStatus());
        },
      });
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (!tableFilter) {
      return;
    }
    const updatedTableFilter = { ...tableFilter };
    if (updatedTableFilter.metaDataRequest) {
      updatedTableFilter.metaDataRequest.pageNumber = currentPage;
    }
    setTableFilter(updatedTableFilter);
    if (!openEditSureVote) {
      dispatch(getListSureVotes(updatedTableFilter));
    }
  }, [openEditSureVote]);

  useEffect(() => {
    if (sureVoteById === "Fulfilled") {
      setOpenEditSureVote(true);
    }
  }, [sureVoteById]);

  useEffect(() => {
    if (report && reportStatus === "Fulfilled") {
      saveAs(report, "report.xlsx");
      resetTableFilterState();
      toast.success("Uspešno ste izveli sig. glasove", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetReportStatus());
          resetTableFilterState();
          setSelectedFilterField(null);
        },
      });
    }
  }, [report, reportStatus]);

  useEffect(() => {
    if (sureVoteStatus === "Fulfilled") {
      setCurrentPage(currentPage);
      dispatch(getListSureVotes(tableFilter));
      if (operationType === "add") {
        setOpenAddSureVoteModal(false);
        toast.success("Sigurni glas je uspešno dodat!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSureVoteStatus());
            setSelectedVoteIndex(null);
          },
        });
      } else {
        toast.success("Sigurni glas je uspešno ažuriran!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSureVoteStatus());
            setSelectedVoteIndex(null);
          },
        });
      }
    }
  }, [sureVoteStatus, operationType]);

  return (
    <>
      <Grid item md={12} mt={11}>
        <ActivitiesSection
          setSureVote={setSureVote}
          sureVote={sureVote}
          handleActivistChange={handleActivistChange}
          handleActivityChange={handleActivityChange}
          selectedActivists={selectedActivists}
          selectedActivity={selectedActivity}
        />
      </Grid>
      <Grid sx={{ mx: "10%" }}>
        <Grid container direction="row">
          <Grid item md={12} display="flex" alignItems="center">
            <Grid item>
              {reportStatus === "Pending" ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={25}
                  style={{ alignItems: "center" }}
                />
              ) : (
                <Typography variant="button" fontSize={"24px"}>
                  <IconButton disabled={!selectedActivity}>
                    <LightTooltip title={"Izvezi Excel"} placement="top-start">
                      <TableViewIcon
                        id="tableComponent_icon_excel-report"
                        fontSize="inherit"
                        className={classes.exportExcButton}
                        onClick={() => handleCreateReport()}
                        sx={{
                          color: selectedActivity ? "" : "grey !important",
                        }}
                      />
                    </LightTooltip>
                  </IconButton>
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography variant="button" fontSize={"24px"}>
                <IconButton disabled>
                  <LightTooltip title={"Izvezi PDF"} placement="top-start">
                    <PictureAsPdfIcon
                      id="tableComponent_icon_pdf-report"
                      fontSize="inherit"
                      className={classes.exportPdfButton}
                    />
                  </LightTooltip>
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <AppButton
              id="tableComponent_button-selectionOfTableColumns"
              onClick={() => {}}
              startIcon={<ViewWeekIcon fontSize="small" />}
              float="left"
              label="izbor kolona tabele"
              color={"#2f4454"}
              hover={"#2f4454"}
              disabled
            />
            <IconButton
              className={classes.iconButton}
              onClick={handleOpenPopover}
              disabled={!selectedActivity}
            >
              <LightTooltip title="Filteri" placement="top">
                <FilterAltOutlinedIcon
                  fontSize="large"
                  onClick={() => handleOpenPopover}
                />
              </LightTooltip>
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                minWidth: "150%",
              }}
            >
              <DialogTitle>
                <Typography>Izaberite filtere</Typography>
              </DialogTitle>
              <DialogContent sx={{ padding: "10px" }}>
                <Grid item md={12}>
                  <Typography>Izaberite polje</Typography>
                  <Autocomplete
                    options={filter_fields}
                    getOptionLabel={(option) => option.name}
                    onChange={handleFieldChange}
                    value={selectedFilterFieldValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </Grid>
                {selectedFilterField === "Datum kreiranja" ? (
                  <>
                    <Grid item md={12}>
                      <BasicInput
                        label={"Od"}
                        id="sure-vote-filter-value-field-from-date"
                        value={fromDate}
                        name="fromDate"
                        onChange={handleFormChange}
                        disabled={!selectedFilterFieldValue}
                        inputProps={{
                          maxLength: 10,
                          placeholder: "dd-mm-yyyy",
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <BasicInput
                        label={"Do"}
                        id="sure-vote-filter-value-field-to-date"
                        name="toDate"
                        value={toDate}
                        onChange={handleFormChange}
                        disabled={!selectedFilterFieldValue}
                        inputProps={{
                          maxLength: 10,
                          placeholder: "dd-mm-yyyy",
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item md={12}>
                    <BasicInput
                      label={"Vrednost"}
                      id="sure-vote-filter-value-field"
                      value={filterValue}
                      onChange={handleFormChange}
                      disabled={!selectedFilterFieldValue}
                    />
                  </Grid>
                )}
                <Grid container my={2} spacing={2}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    textAlign={"center"}
                    display={"flex"}
                    justifyContent={"right"}
                  >
                    <Grid item mr={2}>
                      <AppButton
                        label={"Dodaj"}
                        color={"#4CAF50"}
                        hover={"#4CAF50"}
                        startIcon={<AddIcon />}
                        onClick={handleAddFilter}
                        disabled={!selectedFilterFieldValue}
                      />
                    </Grid>
                    <Grid item>
                      <AppButton
                        label={"Očisti filtere"}
                        color={colors.red}
                        hover={colors.red}
                        startIcon={<DeleteIcon />}
                        onClick={handleRemoveFilter}
                        // disabled={!selectedFilterField}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Popover>
          </Grid>
        </Grid>
        <TableContainer>
          <Table id="member" size="small">
            <TableHead
              style={{
                background: "#2f4454",
                color: "white",
                borderBottom: "none",
                border: "none",
              }}
              sx={{
                "& .MuiTableRow-root > .MuiTableCell-root": {
                  color: "white",
                },
              }}
            >
              <StyledTableRow>
                <StyledTableCell>Akcija</StyledTableCell>
                <StyledTableCell>Red. br.</StyledTableCell>
                <StyledTableCell>Prezime</StyledTableCell>
                <StyledTableCell>Ime</StyledTableCell>
                {/* <StyledTableCell>Prezime i ime</StyledTableCell> */}
                <StyledTableCell>Datum rođ.</StyledTableCell>
                <StyledTableCell>Telefon</StyledTableCell>
                <StyledTableCell>Opština</StyledTableCell>
                <StyledTableCell>Mesto</StyledTableCell>
                <StyledTableCell>Ulica</StyledTableCell>
                <StyledTableCell>Kućni broj</StyledTableCell>

                {/* <StyledTableCell>Ulica i broj</StyledTableCell> */}
                {/* <StyledTableCell>Mesto, ulica i broj</StyledTableCell> */}
                {/* <StyledTableCell>Biračko mesto</StyledTableCell> */}
                {/* <StyledTableCell>Adresa B.M.</StyledTableCell> */}
                {/* <StyledTableCell>Mesna Zajednica</StyledTableCell> */}
                <StyledTableCell>Aktivista</StyledTableCell>
                <StyledTableCell>Datum kreiranja</StyledTableCell>
                {/* <StyledTableCell>čl/sg</StyledTableCell> */}
                {/* <StyledTableCell>Datum kreiranja</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sureVoteList?.results.map((sureVote, index) => (
                <StyledTableRow>
                  <StyledTableCell>
                    <LightTooltip
                      title={"Izmeni"}
                      arrow
                      onClick={() => {
                        handleUpdateSureVote(sureVote.id);
                        setOperationType("Update");
                      }}
                    >
                      <CreateOutlinedIcon
                        name="createIcon"
                        style={{ color: "#00B2FF", cursor: "pointer" }}
                      />
                    </LightTooltip>
                    <LightTooltip title={"Detalji"} placement="top" arrow>
                      <VisibilityOutlinedIcon
                        style={{
                          color: "#3C7F9C",
                          marginLeft: "10px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        className="accountTable_visibility_icon"
                      />
                    </LightTooltip>
                    <LightTooltip title={"Obriši"} placement="right" arrow>
                      <DeleteOutlineOutlinedIcon
                        style={{ color: "#DE2C2C", cursor: "pointer" }}
                        onClick={() => {
                          setOpenConfirmationModal(true);
                          setSelectedSureVoteId(sureVote?.id);
                          setSelectedSureVoteName(sureVote.name);
                          setSelectedSureVoteSurname(sureVote.surname);
                        }}
                      />
                    </LightTooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    {(currentPage === 1
                      ? 0
                      : (currentPage - 1) * numberOfResultsPerPage) +
                      index +
                      1}
                  </StyledTableCell>
                  <StyledTableCell>{sureVote.surname}</StyledTableCell>
                  <StyledTableCell>{sureVote.name}</StyledTableCell>
                  {/* <StyledTableCell>
                    {sureVote.surname + " " + sureVote.name}
                  </StyledTableCell> */}
                  <StyledTableCell>{sureVote.birthDate}</StyledTableCell>
                  <StyledTableCell>{sureVote.phone}</StyledTableCell>
                  <StyledTableCell>{sureVote.municipalityName}</StyledTableCell>
                  <StyledTableCell>{sureVote.cityName}</StyledTableCell>
                  <StyledTableCell>{sureVote.streetName}</StyledTableCell>
                  <StyledTableCell>{sureVote.homeNumber}</StyledTableCell>
                  {/* <StyledTableCell>
                    {sureVote.streetName + " " + sureVote.homeNumber}
                  </StyledTableCell> */}
                  {/* <StyledTableCell>
                    {sureVote.cityName +
                      "," +
                      sureVote.streetName +
                      " " +
                      sureVote.homeNumber}
                  </StyledTableCell> */}
                  {/* <StyledTableCell>Biračko mesto</StyledTableCell> */}
                  {/* <StyledTableCell>ANĐE RANKOVIĆ BR. 2</StyledTableCell> */}
                  {/* <StyledTableCell>Mesna Zajednica</StyledTableCell> */}
                  <StyledTableCell>
                    {sureVote.activistDisplayName}
                  </StyledTableCell>
                  <StyledTableCell>{sureVote?.createdDate}</StyledTableCell>
                  {/* <StyledTableCell>
                    {sureVote.isMember ? "čl" : "sg"}
                  </StyledTableCell> */}
                  {/* <StyledTableCell>Datum kreiranja</StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container display={"flex"} alignItems="center">
          <Grid item md={6} xs={12} display={"flex"}>
            <LightTooltip title="Dodajte sigurni glas">
              <IconButton disabled={!selectedActivistId}>
                <ControlPointIcon
                  sx={{
                    cursor: "pointer",
                    color: selectedActivistId ? colors.primary : "lightgray",
                  }}
                  fontSize="large"
                  onClick={() => {
                    setOperationType("add");
                    setOpenAddSureVoteModal(true);
                  }}
                />
              </IconButton>
            </LightTooltip>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display={isMobile ? "" : "flex"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              md={12}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              <Grid item>
                <Typography>Rez. po stranici:</Typography>
              </Grid>
              <Grid item>
                <select
                  className={classes.select}
                  value={numberOfResultsPerPage}
                  onChange={(e: any) => {
                    onChangePageSize(e.target.value);
                  }}
                >
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={300}>300</option>
                </select>
              </Grid>
              <Grid item>
                <TablePagination
                  totalCount={sureVoteList?.totalPages || 0}
                  page={sureVoteList?.currentPage || 1}
                  handleChangePage={handleChangePage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <UpdateSureVoteModal
        open={openEditSureVote}
        handleClose={handleCloseUpdateSureVoteModal}
        handleSave={handleSave}
        setSureVote={setSureVote}
        setValidationErrors={setValidationErrors}
        sureVote={sureVote}
        validationErrors={validationErrors}
      />
      <DeleteConfirmationModal
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        handleDelete={() => handleDelete(selectedSureVoteId)}
        name={selectedSureVoteName}
        surname={selectedSureVoteSurname}
      />
      <AddSureVoteModal
        setSelectedVoteIndex={setSelectedVoteIndex}
        selectedVoteIndex={selectedVoteIndex}
        sureVote={sureVote}
        setSureVote={setSureVote}
        open={openAddSureVoteModal}
        handleClose={() => setOpenAddSureVoteModal(false)}
      />
    </>
  );
}
