import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getActivistsByActivityId,
  getListSureVotes,
  getSureVotesByActivistId,
  resetSelectedActivistId,
  resetSelectedActivityId,
  resetSureVoteList,
  resetUpdateSureVotesActivistStatus,
  selectActivists,
  selectActivities,
  selectSelectedActivistId,
  selectSelectedActivityId,
  selectSureVotes,
  selectUpdateSureVotesActivist,
  setSelectedActivistId,
  setSelectedActivityId,
  updateSureVotesActivists,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import ActivityModel from "../../../models/activityModel/activityModel";
import ActivistModel from "../../../models/activistModel/activistModel";
import {
  EmptyGuid,
  INIT_UPDATE_SURE_VOTES_ACTIVIST,
} from "../../../initData/initData";
import AppButton from "../../../components/basicButton/basicButtton";
import { colors } from "../../../app/theme/colors";
import SureVoteListRequest from "../../../models/sureVotes/sureVoteListRequest";
import { UpdateSureVotesActivistRequest } from "../../../models/sureVotes/updateSureVotesActivistRequest";
import { toast } from "react-toastify";
import { useUpdateSureVotesActivistStyles } from "./updateSureVotesActivistStyles";

export default function UpdateSureVotesActivist() {
  const dispatch = useAppDispatch();
  const { classes } = useUpdateSureVotesActivistStyles();

  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);
  const sureVoteList = useAppSelector(selectSureVotes);
  const selectedActivityId = useAppSelector(selectSelectedActivityId);
  const selectedActivistId = useAppSelector(selectSelectedActivistId);
  const updateSureVotesActivistStatus = useAppSelector(
    selectUpdateSureVotesActivist
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(15);

  const [selectedSureVoteIds, setSelectedSureVoteIds] = useState<string[]>([]);

  const [updateSureVotesActivist, setUpdateSureVotesActivist] =
    useState<UpdateSureVotesActivistRequest>(INIT_UPDATE_SURE_VOTES_ACTIVIST);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [selectedActivists, setSelectedActivists] =
    useState<ActivistModel | null>(null);
  const [selectedNewActivist, setSelectedNewActivist] =
    useState<ActivistModel | null>(null);
  const [selectedNewActivistId, setSelectedNewActivistId] = useState<
    string | null
  >(null);

  const [value, setValue] = useState(0);

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);
    if (value) {
      dispatch(setSelectedActivityId(value.id));
      dispatch(getActivistsByActivityId(value?.id || EmptyGuid));
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        activityId: value.id || "",
      }));
      // Update sureVote state with activityId
      //   setSureVote((prevSureVote: SureVoteModel) => ({
      //     ...prevSureVote,
      //     activityId: value.id,
      //   }));
    } else {
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        activityId: "",
      }));
      setSelectedActivists(null);
      dispatch(resetSureVoteList());
      dispatch(resetSelectedActivistId());
      dispatch(resetSelectedActivityId());
      setSelectedNewActivist(null);
      setSelectedNewActivistId(null);
      // Reset sureVote activityId
      //   setSureVote((prevSureVote: SureVoteModel) => ({
      //     ...prevSureVote,
      //     activityId: null,
      //   }));
    }
  };

  const handleActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedActivists(value);

    if (value) {
      dispatch(getSureVotesByActivistId(value?.id || ""));
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        oldActivist: value.id || "",
      }));
    } else {
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        oldActivist: "",
      }));
    }

    dispatch(setSelectedActivistId(value?.id || null));
  };

  const handleNewActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedNewActivist(value);
    setSelectedNewActivistId(value ? value.id : null);

    if (value) {
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        newActivist: value.id || "",
      }));
    } else {
      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        newActivist: "",
      }));
      setSelectedNewActivist(null);
    }

    dispatch(setSelectedActivistId(value?.id || null));
  };

  const handleSureVoteCheck = (sureVoteId: string) => {
    setSelectedSureVoteIds((prevSelectedIds) => {
      const updatedSelectedIds = prevSelectedIds.includes(sureVoteId)
        ? prevSelectedIds.filter((id) => id !== sureVoteId)
        : [...prevSelectedIds, sureVoteId];

      setUpdateSureVotesActivist((prevUpdateSureVotesActivist) => ({
        ...prevUpdateSureVotesActivist,
        sureVotes: updatedSelectedIds,
      }));

      return updatedSelectedIds;
    });
  };

  const handleSave = () => {
    dispatch(updateSureVotesActivists(updateSureVotesActivist));
    resetState();
    dispatch(resetSelectedActivistId());
    dispatch(resetSelectedActivityId());
  };

  const resetState = () => {
    setUpdateSureVotesActivist(INIT_UPDATE_SURE_VOTES_ACTIVIST);
    setSelectedNewActivist(null);
    setSelectedActivists(null);
    setSelectedActivity(null);
    setSelectedNewActivistId(null);
    dispatch(resetSelectedActivityId());
    dispatch(resetSelectedActivistId());
  };

  useEffect(() => {
    if (selectedActivityId) {
      var request: SureVoteListRequest = {
        activityId: selectedActivityId,
      };
      dispatch(getListSureVotes(request));
    }
  }, [selectedActivityId]);

  useEffect(() => {
    if (selectedNewActivistId) {
      console.log(
        "Autocomplete for new activist selected. Skipping useEffect."
      );
      return;
    }
    if (selectedActivistId) {
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    }
  }, [selectedActivistId, selectedNewActivistId]);

  useEffect(() => {
    if (updateSureVotesActivistStatus === "Fulfilled") {
      toast.success("Uspešno ste preneli sigurne glasove!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetUpdateSureVotesActivistStatus());
        },
      });
    }
  }, [updateSureVotesActivistStatus]);

  return (
    <Grid sx={{ boxShadow: "0 0 10px rgb(0 0 0 / 0.2)", padding: "20px" }}>
      <Grid container spacing={1} >
        <Grid item md={4} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className={classes.inputTitle}>
              Aktivnost
            </Typography>
            <Autocomplete
              options={activities ? activities : []}
              getOptionLabel={(option) => option.name}
              value={selectedActivity}
              onChange={handleActivityChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  className={classes.autocompleteTextField}
                  size="small"
                />
              )}
              // value={selectedResidenceStreet}
              // onChange={handleResidentStreet}
            />
          </FormControl>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className={classes.inputTitle}>
              Aktivista
            </Typography>
            <Autocomplete
              options={activists ? activists : []}
              getOptionLabel={(option) =>
                option.lastName + " " + option.firstName
              }
              value={selectedActivists}
              onChange={handleActivistChange}
              disabled={selectedActivity === null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  className={classes.autocompleteTextField}
                  size="small"
                />
              )}
            />
          </FormControl>
          {selectedActivists && (
            <Grid item md={12} xs={12}>
              {sureVoteList?.results && sureVoteList.results.length > 0 ? (
                sureVoteList.results.map((sureVote) => (
                  <Grid
                    container
                    sx={{ padding: "5px", alignItems: "center" }}
                    spacing={1}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedSureVoteIds.includes(sureVote.id)}
                          onChange={() => handleSureVoteCheck(sureVote.id)}
                        />
                      }
                      label={`${sureVote.name} ${sureVote.surname}, ${sureVote.cityName}, ${sureVote.streetName}, ${sureVote.homeNumber}, ${sureVote.activistDisplayName}`}
                    />
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" mt={1}>
                  Izabrani aktivista nema unesene sigurne glasove
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        display={"flex"}
        alignItems="center"
        justifyContent={"flex-end"}
        spacing={1}
        mt={1}
      >
        <Grid item md={8} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className={classes.inputTitle}>
              Novi aktivista
            </Typography>
            <Autocomplete
              id="autocomplete_new_activist"
              options={activists ? activists : []}
              getOptionLabel={(option) =>
                option.lastName + " " + option.firstName
              }
              value={selectedNewActivistId ? selectedNewActivist : null}
              onChange={handleNewActivistChange}
              disabled={!selectedActivity || !selectedActivists}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  className={classes.autocompleteTextField}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} textAlign={"right"}>
          <AppButton
            label={"Sacuvaj"}
            color={colors.green}
            hover={colors.green}
            disabled={selectedActivistId === null}
            onClick={handleSave}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
