import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import AppButton from "../../basicButton/basicButtton";
import { useElectionPlaceModalStyles } from "./electionPlaceDetailsModalStyles";

interface Props {
  handleClose: any;
  open: any;
  electionPlaceById: any;
}

export default function ElectionPlaceDetailsModal({
  handleClose,
  open,
  electionPlaceById,
}: Props) {
  const { classes } = useElectionPlaceModalStyles();

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Detalji biračkog mesta</DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <Grid container sx={{ padding: "10px" }} spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}>
              <Grid item md={1.5}>
                <Typography>Broj:</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {electionPlaceById?.electionPlaceNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}>
              <Grid item md={1.5}>
                <Typography>Naziv:</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {electionPlaceById?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}>
              <Grid item md={1.5}>
                <Typography>Adresa:</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {electionPlaceById?.address}
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}>
              <Grid item md={1.5}>
                <Typography>Mesto:</Typography>
              </Grid>
              <Grid item md={6}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {electionPlaceById?.cityName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            label="Zatvori"
            color={"#2f4454"}
            hover={"#2f4454"}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
