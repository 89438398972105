import { Container, Grid } from "@mui/material";
import Header from "../components/header/header";
import ViewAndPrint from "../components/viewAndPrint/ViewAndPrint";
import ActivitiesSection from "../features/activities/activitiesSection";
import { useState } from "react";
import { SureVoteModel } from "../models/sureVotes/sureVotes";
import { INIT_SURE_VOTE } from "../initData/initData";

type Props = {};

export default function ViewAndPrintPage({}: Props) {

  const [sureVote, setSureVote] = useState<SureVoteModel>(INIT_SURE_VOTE);
console.log("sureVote", sureVote)
  return (
    <Container maxWidth="xl">
      <Header headerTitle="Pregled i štampa"/>
      <Grid item md={12} mt={11}>
        <ViewAndPrint sureVote={sureVote} setSureVote={setSureVote}/>
      </Grid>
    </Container>
  );
}
