import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useSureVotesCorrectionStyles = makeStyles()((theme: Theme) => ({
  deleteIcon: {
    color: colors.red,
  },
  circularProgress: {
    color: colors.primary,
  },
  inputTitle: { textAlign: "start" },
  select: {
    outline: "none",
    border: "none",
    width: "40px",
    cursor: "pointer",
    "&:hover": {
      background: "lightGray",
    },
  },
  exportPdfButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    fontSize: "40px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    // float: "right",
    color: colors.red,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      background: colors.red,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
  },
  exportExcButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    fontSize: "40px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    color: colors.green,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      background: colors.green,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    // margin: 0,
    // padding: 0,
    // marginTop: "15px",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {},
    },
  },
  smallCheckbox: {
    padding: 3, 
  },
}));
