import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import { ReactNode, useEffect, useState } from "react";
import {
  INIT_ELECTION_PLACE_REQUEST,
  INIT_SURE_VOTE,
} from "../../../initData/initData";
import BasicInput from "../../basicInput/basicInput";
import {
  CanUpdateSureVote,
  CapitalizeFirstLetter,
} from "../../../utilities/sureVoteUtility";
import { useAddSureVoteModalStyles } from "./addSureVoteModalStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getElectionPlaceByCityAndStreet,
  getElectionPlaceByCityId,
  getElectionPlaceRegionByCityId,
  getStreetsByCityId,
  resetElectionPlace,
  resetElectionPlaceByCity,
  resetElectionPlaceByCityAndStreet,
  resetElectionPlaceById,
  selectCities,
  selectElectionPlace,
  selectElectionPlaceByCityAndStreet,
  selectElectionPlaceById,
  selectElectionPlaceRegion,
  selectStreets,
} from "../../../slices/addressSlice/addressSlice";
import { City } from "../../../models/address/city";
import { Street } from "../../../models/address/street";
import ElectionPlaceRequest from "../../../models/address/electionPlaceRequest";
import { ElectionPlaceRegion } from "../../../models/address/electionPlaceRegion";
import { ElectionPlace } from "../../../models/address/electionPlace";
import AppButton from "../../basicButton/basicButtton";
import {
  resetActivists,
  resetSureVotesByActivist,
  resetValidateSureVote,
  saveSureVote,
  selectExistingSureVote,
  selectSureVoteStatus,
  selectValidateSureVotes,
  validateSureVote,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import ValidateSureVotesModal from "../validateSureVotesModal/validateSureVotesModal";
import { ValidateSureVoteRequest } from "../../../models/sureVotes/validateSureVoteRequest";
import WarningSureVoteModal from "../warningSureVoteModal/warningSureVoteModal";
import { useRef } from "react";
import { validateDate } from "../../../utilities/dateUtility";

interface Props {
  handleClose: () => void;
  open: boolean;
  sureVote: SureVoteModel;
  setSureVote: React.Dispatch<React.SetStateAction<SureVoteModel>>;
  selectedVoteIndex: number | null;
  setSelectedVoteIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function AddSureVoteModal({
  handleClose,
  open,
  sureVote,
  setSureVote,
  selectedVoteIndex,
  setSelectedVoteIndex,
}: Props) {
  const dispatch = useAppDispatch();
  console.log("sureVote", sureVote)
  const { classes } = useAddSureVoteModalStyles();
  const sureVoteStatus = useAppSelector(selectSureVoteStatus);
  const streetsByCity = useAppSelector(selectStreets) || undefined;
  const { cities, electionPlaceByStreetAndCity } = useAppSelector((state) => ({
    cities: selectCities(state),
    electionPlaceRegionByCity: selectElectionPlaceRegion(state),
    electionPlaceById: selectElectionPlaceById(state),
    electionPlace: selectElectionPlace(state),
    electionPlaceByStreetAndCity: selectElectionPlaceByCityAndStreet(state),
  }));
  const existingSureVote = useAppSelector(selectExistingSureVote);
  const validateSureVotes = useAppSelector(selectValidateSureVotes);
  useEffect(() => {}, [existingSureVote, validateSureVote]);

  const [isOpenValidateSureVoteModal, setIsOpenValidateSureVoteModal] =
    useState(false);
  const [isOpenWarningSureVote, setIsOpenWarningSureVote] = useState(false);

  const [place, setPlace] = useState<City | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<Street | null>(null);
  const [electionPlaceRequest, setElectionPlaceRequest] =
    useState<ElectionPlaceRequest>(INIT_ELECTION_PLACE_REQUEST);
  const [selectedElectionById, setSelectedElectionPlaceById] =
    useState<ElectionPlaceRegion | null>(null);
  const [selectedElectionPlace, setSelectedElectionPlace] =
    useState<ElectionPlace | null>(null);
  const [isSureVoteValidated, setIsSureVoteValidated] = useState(false);

  const [previousSureVote, setPreviousSureVote] =
    useState<SureVoteModel | null>(null);

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    place: "",
    street: "",
    homeNumber: "",
    phone: "",
  });

  const validateFields = () => {
    const errors = {
      firstName: "",
      lastName: "",
      birthDate: "",
      place: "",
      street: "",
      homeNumber: "",
      phone: "",
    };

    if (!sureVote.sureVoteRequest.name) {
      errors.firstName = "Ime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.surname) {
      errors.lastName = "Prezime je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.cityName) {
      errors.place = "Mesto je obavezno polje.";
    }

    // if (!sureVote.sureVoteRequest.birthDate) {
    //   var newSureVote = {...sureVote, birthDate:'02-02-2000'};
    //   setSureVote(newSureVote);
    //   //errors.birthDate = "Datum rođenja je obavezno polje.";
    // }

    if (!sureVote.sureVoteRequest.streetId) {
      errors.street = "Ulica je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.homeNumber) {
      errors.homeNumber = "Kućni br. je obavezno polje.";
    }

    if (!sureVote.sureVoteRequest.phone) {
      errors.phone = "Telefon je obavezno polje.";
    }

    return errors;
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    if (name === "name" || name === "surname") {
      value = CapitalizeFirstLetter(value);
    } else if (name === "birthDate") {
      const cursorPosition = e.target.selectionStart || 0;
      if (
        e.nativeEvent instanceof InputEvent &&
        e.nativeEvent.inputType === "deleteContentBackward"
      ) {
        if (cursorPosition > 0) {
          value = "";
        }
      } else {
        value = value.replace(/\D/g, "");
        if (value.length >= 2) {
          value = value.substring(0, 2) + "-" + value.substring(2);
        }
        if (value.length >= 5) {
          value = value.substring(0, 5) + "-" + value.substring(5);
        }
        if (value.length > 10) {
          value = value.substring(0, 10);
        }
      }
    }
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        [name]: value,
      },
    }));
    if (value) {
      if (name === "name") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
      if (name === "surname") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
      if (name === "birthDate") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          birthDate: "",
        }));
      }
      if (name === "place") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          place: "",
        }));
      }
      if (name === "street") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          street: "",
        }));
      }
      if (name === "homeNumber") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          homeNumber: "",
        }));
      }
      if (name === "phone") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          phone: "",
        }));
      }
    }
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    newValue: City | null
  ) => {
    if (!newValue) {
      resetStateOnCityChange();
    } else {
      dispatchCityActions(newValue);
      updateStateOnCityChange(newValue);
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        place: "",
      }));
    }
  };

  const resetStateOnCityChange = () => {
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        cityId: "",
        cityName: "",
      },
    }));
    setElectionPlaceRequest({
      ...electionPlaceRequest,
      cityId: "",
      cityName: "",
      streetName: "",
      streetNumber: "",
    });
    setSelectedStreet(null);
    setPlace(null);
    setSelectedElectionPlaceById(null);
    setSelectedElectionPlace(null);
    dispatch(resetElectionPlaceByCity());
    dispatch(resetElectionPlaceByCityAndStreet());
    dispatch(resetElectionPlace());
    dispatch(resetElectionPlaceById());
  };

  const dispatchCityActions = (city: City) => {
    const cityId = city.id;
    dispatch(getStreetsByCityId(cityId));
    dispatch(getElectionPlaceRegionByCityId(cityId));
    dispatch(getElectionPlaceByCityId(cityId));
    setElectionPlaceRequest({
      ...electionPlaceRequest,
      cityId: cityId,
      cityName: city.name,
    });
  };

  const updateStateOnCityChange = (city: City) => {
    setSelectedStreet(null);
    setPlace(city);
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        cityId: city.id,
        cityName: city.name,
        municipalityName: city.municipalityName,
      },
    }));
  };

  const handleRejectButton = () => {
    setSelectedVoteIndex(null);
    dispatch(resetValidateSureVote());
    dispatch(resetActivists());
    dispatch(resetSureVotesByActivist());

    if (previousSureVote) {
      setIsOpenValidateSureVoteModal(false);
      setSureVote(previousSureVote);
      return;
    }

    resetSureVoteState();
    setSelectedStreet(null);
    setPlace(null);
    setIsSureVoteValidated(false);
    setIsOpenWarningSureVote(false);

    setTimeout(() => {
      var nameField = document.getElementById("sure-vote-name-field");
      nameField?.focus();
    }, 100);
  };

  const handleStreetChange = (
    event: React.ChangeEvent<{}>,
    newValue: Street | null
  ) => {
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        streetId: newValue ? newValue.streetId : null,
        streetName: newValue ? newValue.streetName : "",
      },
    }));

    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      street: "",
    }));

    setSelectedStreet(newValue);

    setElectionPlaceRequest((prevRequest) => ({
      ...prevRequest,
      streetName: newValue ? newValue.streetName : "",
    }));
  };

  const handleHomeNumberBlur = () => {
    dispatch(getElectionPlaceByCityAndStreet(electionPlaceRequest));
  };

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-name-field":
          var surnameField = document.getElementById("sure-vote-surname-field");
          surnameField?.focus();
          break;
        case "sure-vote-surname-field":
          var birthDateField = document.getElementById(
            "sure-vote-birthDate-field"
          );
          birthDateField?.focus();
          break;
        case "sure-vote-birthDate-field":
          var cityField = document.getElementById("sure-vote-city-field");
          cityField?.focus();
          break;
        case "sure-vote-city-field":
          var cityField = document.getElementById("sure-vote-street-field");
          cityField?.focus();
          break;
        case "sure-vote-street-field":
          var cityField = document.getElementById(
            "sure-vote-streetNumber-field"
          );
          cityField?.focus();
          break;
        case "sure-vote-streetNumber-field":
          var cityField = document.getElementById("sure-vote-phone-field");
          cityField?.focus();
          break;
        case "sure-vote-phone-field":
          if (!isSureVoteValidated) {
            handleSave();
          }
          break;
      }
    }
  };

  const handleSave = async () => {
    const errors = validateFields();
    setValidationErrors(errors);

    if (Object.values(errors).every((error) => error === "")) {
      const sureVoteForSave = { ...sureVote };
      if (sureVote.sureVoteRequest.birthDate === "") {
        sureVoteForSave.sureVoteRequest.birthDate = "01-01-1900";
      }

      await dispatch(saveSureVote(sureVoteForSave));
    }
  };

  useEffect(() => {
    if (sureVoteStatus === "Fulfilled") {
      resetSureVoteState();
      setSelectedStreet(null);
      setIsSureVoteValidated(false);
      setIsOpenValidateSureVoteModal(false);
      var nameField = document.getElementById("sure-vote-name-field");
      nameField?.focus();

      setPreviousSureVote(null);

      setSureVote((prevState) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          birthDate: "",
        },
      }));
    }
  }, [sureVoteStatus]);

  const resetSureVoteState = () => {
    var newInitSureVote = {
      ...INIT_SURE_VOTE,
      activistId: sureVote.activistId,
      activityId: sureVote.activityId,
    };
    setSureVote(newInitSureVote);
    setPlace(null);
  };

  useEffect(() => {
    if (electionPlaceByStreetAndCity) {
      const electionPlaceId = electionPlaceByStreetAndCity.id;
      setSureVote((prevState: SureVoteModel) => ({
        ...prevState,
        sureVoteRequest: {
          ...prevState.sureVoteRequest,
          electionPlaceId: electionPlaceId,
        },
      }));
    }
  }, [electionPlaceByStreetAndCity]);

  const handleBirthDateBlurChange = () => {
    var birthDate = sureVote.sureVoteRequest.birthDate;
    const isValidDate = validateDate(birthDate);

    if (!birthDate) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "",
      }));
    } else if (!isValidDate) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "Unesite datum u formatu dd-mm-yyyy.",
      }));
    } else {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "",
      }));

      var request: ValidateSureVoteRequest = {
        activityId: sureVote.activityId,
        birthDate: birthDate,
        name: sureVote.sureVoteRequest.name,
        surname: sureVote.sureVoteRequest.surname,
      };
      dispatch(validateSureVote(request));
    }
  };

  useEffect(() => {
    if (
      existingSureVote &&
      existingSureVote.id !== "00000000-0000-0000-0000-000000000000" &&
      validateSureVotes?.isPossibleDuplicate !== false
    ) {
      setIsOpenWarningSureVote(true);
    } else {
      setIsOpenWarningSureVote(false);
      dispatch(resetValidateSureVote());
    }
  }, [existingSureVote]);

  useEffect(() => {
    if (
      validateSureVotes &&
      validateSureVotes.isPossibleDuplicate === false &&
      validateSureVotes.sureVotes.length !== 0
    ) {
      setIsOpenValidateSureVoteModal(true);
      setPreviousSureVote(sureVote);
    } else {
      setIsOpenValidateSureVoteModal(false);
      dispatch(resetValidateSureVote());
    }
  }, [validateSureVotes]);

  useEffect(() => {
    setTimeout(() => {
      const nameField = document.getElementById("sure-vote-name-field");
      nameField?.focus();
    }, 100);
  }, [open]);

  return (
    <Dialog
      onClose={() => {
        handleClose();
        setSelectedVoteIndex(null);
        dispatch(resetValidateSureVote());
      }}
      open={open}
      id={"addSureVoteModal"}
      maxWidth="lg"
    >
      <DialogTitle>Dodajte sigurni glas</DialogTitle>
      <DialogContent sx={{ padding: "10px" }}>
        <Grid item md={12} mt={2}>
          <Grid item md={12} sx={{ padding: "10px" }}>
            <Grid item md={12} sx={{ padding: "10px" }}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Podaci glasača
              </Typography>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item md={4} xs={12}>
                <BasicInput
                  id="sure-vote-name-field"
                  autofocus={true}
                  required
                  label="Ime"
                  value={sureVote.sureVoteRequest.name}
                  onChange={handleFormChange}
                  name="name"
                  error={!!validationErrors.firstName}
                  helperText={validationErrors.firstName}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-name-field")
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <BasicInput
                  required
                  label="Prezime"
                  value={sureVote.sureVoteRequest.surname}
                  onChange={handleFormChange}
                  name="surname"
                  error={!!validationErrors.lastName}
                  helperText={validationErrors.lastName}
                  id="sure-vote-surname-field"
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-surname-field")
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <BasicInput
                  id="sure-vote-birthDate-field"
                  label="Datum rođ."
                  value={sureVote.sureVoteRequest.birthDate}
                  onChange={handleFormChange}
                  onBlur={handleBirthDateBlurChange}
                  name="birthDate"
                  error={!!validationErrors.birthDate}
                  helperText={validationErrors.birthDate}
                  inputProps={{
                    maxLength: 10,
                    placeholder: "dd-mm-yyyy",
                  }}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-birthDate-field")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* ADDRESS SECTION */}
        <Grid item md={12} sx={{ padding: "10px" }}>
          <Grid container spacing={1} mt={1}>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" className={classes.inputTitle}>
                Mesto
              </Typography>
              <Autocomplete
                id={"sure-vote-city-field"}
                options={cities ? cities : []}
                getOptionLabel={(option) => option.name}
                value={place}
                onChange={handleCityChange}
                // disabled={!canAddSureVote}
                onKeyDown={(e: any) =>
                  handleOnKeyPressed(e, "sure-vote-city-field")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    required
                    className={classes.autocompleteTextField}
                    size="small"
                    error={!!validationErrors.place}
                    helperText={validationErrors.place}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body1" className={classes.inputTitle}>
                Ulica
              </Typography>
              <Autocomplete
                id={"sure-vote-street-field"}
                options={streetsByCity ? streetsByCity : []}
                getOptionLabel={(option) => option.streetName}
                onChange={handleStreetChange}
                value={selectedStreet}
                disabled={!place}
                onKeyDown={(e: any) =>
                  handleOnKeyPressed(e, "sure-vote-street-field")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    required
                    className={classes.autocompleteTextField}
                    size="small"
                    disabled={!place}
                    error={!!validationErrors.street}
                    helperText={validationErrors.street}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <BasicInput
                id={"sure-vote-streetNumber-field"}
                label="Kućni broj"
                value={sureVote.sureVoteRequest.homeNumber}
                onChange={handleFormChange}
                name="homeNumber"
                disabled={!place || !selectedStreet}
                onBlur={handleHomeNumberBlur}
                error={!!validationErrors.homeNumber}
                helperText={validationErrors.homeNumber}
                required
                onKeyDown={(e) =>
                  handleOnKeyPressed(e, "sure-vote-streetNumber-field")
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sx={{ padding: "10px" }}>
          <Grid container spacing={1} mt={1}>
            <Grid item md={4} xs={12}>
              <BasicInput
                id={"sure-vote-phone-field"}
                label={"Kontakt telefon"}
                value={sureVote.sureVoteRequest.phone}
                onChange={handleFormChange}
                required
                name="phone"
                onKeyDown={(e) =>
                  handleOnKeyPressed(e, "sure-vote-phone-field")
                }
                error={!!validationErrors.phone}
                helperText={validationErrors.phone}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={1} justifyContent={"flex-end"}>
          <Grid item>
            <AppButton
              label="Zatvori"
              color={"rgba(255, 73, 71, 0.8)"}
              hover={"rgba(255, 73, 71, 0.8)"}
              onClick={handleClose}
            />
          </Grid>
          <Grid item>
            <AppButton
              id="sure-vote-save-btn"
              label="Sačuvaj"
              color={"#4CAF50"}
              hover={"#4CAF50"}
              onClick={handleSave}
              disabled={sureVoteStatus === "Pending"}
              startIcon={
                sureVoteStatus === "Pending" && (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={15}
                  />
                )
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <WarningSureVoteModal
        handleRejectButton={handleRejectButton}
        handleClose={() => {
          setIsOpenWarningSureVote(false);
          dispatch(resetValidateSureVote());
        }}
        onRejectButtonClick={handleRejectButton}
        open={isOpenWarningSureVote}
        sureVoteName={existingSureVote?.name || ""}
        sureVoteLastName={existingSureVote?.surname || ""}
        sureVoteBirthDate={existingSureVote?.birthDate || ""}
        sureVoteCityName={existingSureVote?.cityName || ""}
        sureVoteStreetName={existingSureVote?.streetName || ""}
        sureVoteStreetNumber={existingSureVote?.homeNumber || ""}
        sureVoteActivist={existingSureVote?.activistDisplayName || ""}
      />
      <ValidateSureVotesModal
        handleRejectButton={handleRejectButton}
        handleClose={() => {
          setIsOpenWarningSureVote(false);
          dispatch(resetValidateSureVote());
        }}
        open={isOpenValidateSureVoteModal}
        sureVotes={validateSureVotes?.sureVotes}
        setSureVote={setSureVote}
        sureVote={sureVote}
        handleSave={handleSave}
        selectedVoteIndex={selectedVoteIndex}
        setSelectedVoteIndex={setSelectedVoteIndex}
      />
    </Dialog>
  );
}
