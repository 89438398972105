import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../models/app/apiStatus";
import { LoginResponse } from "../../models/auth/loginResponse";
import { LoginRequest } from "../../models/auth/loginRequest";
import { Auth } from "../../app/redux/authApi";
import { RootState } from "../../app/store";
import { CurrentAccount } from "../../models/currentAccountModel/currentAccountModel";
import jwt_decode from "jwt-decode";

type AuthState = {
  accessToken: string | null;
  loginResponse: LoginResponse | null;
  currentAccount: CurrentAccount | null;
  errorMessage: string | null;

  requestStatus: {
    login: ApiStatus;
  };
};
const savedCurrentAccount = localStorage.getItem("currentAccount");

const initialState: AuthState = {
  accessToken: localStorage.getItem("accessToken") ?? null,
  loginResponse: null,
  errorMessage: "",
  currentAccount: savedCurrentAccount ? JSON.parse(savedCurrentAccount) : null,
  requestStatus: {
    login: ApiStatus.Idle,
  },
};

export const selectLoginStatus = (state: RootState) =>
  state.auth.requestStatus.login;

export const selectCurrentAccount = (state: RootState) =>
  state.auth.currentAccount;

export const selectLoginResponse = (state: RootState) =>
  state.auth.loginResponse;

  export const selectErrorMessage = (state: RootState) =>
  state.auth.errorMessage;


export const login = createAsyncThunk<
  LoginResponse | null,
  LoginRequest,
  { rejectValue: string }
>(
  "Auth/Login",
  async (request: LoginRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Auth.Login(request);
      const result = response.data;

      localStorage.setItem("accessToken", result?.data.accessToken ?? "");

      return result;
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue("Failed to login.");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loginResponse = initialState.loginResponse;
      state.requestStatus.login = initialState.requestStatus.login;
      state.accessToken = null;
      state.currentAccount = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("organizationId");
      localStorage.removeItem("currentAccount");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.requestStatus.login = ApiStatus.Pending;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.requestStatus.login = ApiStatus.Fulfilled;
        state.loginResponse = action.payload;

        let accessToken = action.payload?.data.accessToken;
        state.accessToken =
          action.payload?.data.accessToken ?? initialState.accessToken;
        localStorage.setItem(
          "accessToken",
          action.payload?.data.accessToken ?? ""
        );
        if (accessToken) {
          try {
            const decodedToken = jwt_decode(accessToken) as {
              given_name?: string;
              family_name?: string;
              email?: string;
              primarysid?: string;
              role?: string;
            };

            state.currentAccount = {
              firstName: decodedToken.given_name ?? "",
              lastName: decodedToken.family_name ?? "",
              email: decodedToken.email ?? "",
              id: decodedToken.primarysid ?? "",
              role: decodedToken.role ?? "",
            };
            localStorage.setItem(
              "currentAccount",
              JSON.stringify(state.currentAccount)
            );
          } catch (error) {
            console.error("Failed to decode the token:", error);
          }
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.requestStatus.login = ApiStatus.Rejected;
        state.errorMessage = action.payload || null; // Use null as a default value if action.payload is undefined
      });
      
      
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
