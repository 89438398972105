import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useAddSureVoteModalStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { textAlign: "start" },
  input: {
    borderRadius: "5px",
    height: "2rem",
    backgroundColor: "transparent",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {},
    },
  },
  circularProgress: {
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
}));
