import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AppButton from "../../button/AppButton";

interface Props {
  handleClose: any;
  open: boolean;
  sureVoteName: string;
  sureVoteLastName: string;
  sureVoteBirthDate: string;
  sureVoteCityName: string;
  sureVoteStreetName: string;
  sureVoteStreetNumber: string;
  sureVoteActivist: string;
  handleRejectButton: any;
  onRejectButtonClick: () => void; // Add this prop
}

export default function WarningSureVoteModal({
  handleClose,
  open,
  sureVoteName,
  sureVoteLastName,
  sureVoteBirthDate,
  sureVoteCityName,
  sureVoteStreetName,
  sureVoteStreetNumber,
  sureVoteActivist,
  handleRejectButton,
  onRejectButtonClick
}: Props) {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Potencijalni duplikat</DialogTitle>
      <DialogContent sx={{ padding: "10px" }}>
        <Grid container sx={{ padding: "10px" }} spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography>U bazi postoji siguran glas:</Typography>
              <br />
              <Typography>
                Ime i prezime:{" "}
                <b>
                  {sureVoteName} {sureVoteLastName}
                </b>
              </Typography>
              <Typography>
                Datum rođenja: <b>{sureVoteBirthDate}</b>
              </Typography>
              <Typography>
                Adresa:{" "}
                <b>
                  {sureVoteCityName}, {sureVoteStreetName}{" "}
                  {sureVoteStreetNumber}
                </b>
              </Typography>
              <Typography>
                Aktivista: <b>{sureVoteActivist}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} marginTop={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"right"}>
              <AppButton
                label={"Odbaci"}
                color={"rgba(255, 73, 71, 0.8)"}
                hover={"rgba(255, 73, 71, 0.8)"}
                onClick={() => onRejectButtonClick()}
              />

              <AppButton
                label={"Nastavi"}
                color={"#4CAF50"}
                hover={"#4CAF50"}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
