import { Grid, Typography } from "@mui/material";
import BasicInput from "../../../components/basicInput/basicInput";
import { useContactSectionStyles } from "./contactSectionStyles";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";

interface Props {
  sureVote: SureVoteModel;
  setSureVote: any;
  canAddSureVote: boolean | false;
  validationErrors: any;
  setValidationErrors: any;
}

export default function ContactSection({
  setSureVote,
  sureVote,
  canAddSureVote,
  setValidationErrors,
  validationErrors,
}: Props) {
  const { classes } = useContactSectionStyles();

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        [name]: value,
      },
    }));
    if(value){
      if (name === "phone") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          phone: "",
        }));
      }
    }
  };

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-phone-field":
          var surnameField = document.getElementById("sure-vote-save-btn");
          surnameField?.focus();
          break;
      }
    }
  };

  return (
    <Grid item md={12} sx={{ padding: "10px" }}>
      {/* <Grid item md={12} xs={12} sx={{ padding: "10px" }}>
        <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
          Kontakt podaci člana
        </Typography>
      </Grid> */}
      <Grid container spacing={1} mt={1}>
        <Grid item md={4} xs={12}>
          <BasicInput
            id={"sure-vote-phone-field"}
            label={"Kontakt telefon"}
            value={sureVote.sureVoteRequest.phone}
            onChange={handleFormChange}
            name="phone"
            disabled={!canAddSureVote}
            onKeyDown={(e) => handleOnKeyPressed(e, "sure-vote-phone-field")}
            error={!!validationErrors.phone}
            helperText={validationErrors.phone}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
