import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useUpdateSureVotesActivistStyles = makeStyles()(
  (theme: Theme) => ({
    autocompleteTextField: {
      "& .MuiInputBase-root": {
        borderRadius: "5px",
        background: "white",
      },
    },
    autocomplete: {
      "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
        //   borderColor: colors.sectionTitle,
        },
      },
    },
    inputTitle: { textAlign: "start" },
    smallCheckbox: {
      padding: 3, 
    },
  select: {
    outline: "none",
    border: "none",
    width: "40px",
    cursor: "pointer",
    "&:hover": {
      background: "lightGray",
    },
  },
  })
);
