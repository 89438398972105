import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  FormControl,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import { useActivityDataAccordion } from "./activityDataAccordionStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getActivistsByActivityId,
  getActivities,
  getSureVoteById,
  getSureVotesByActivistId,
  resetActivistStatus,
  resetSelectedSureVote,
  resetSureVote,
  resetValidateSureVote,
  selectActivists,
  selectActivities,
  selectSureVotesByActivist,
  setSelectedActivityId,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import { useEffect, useState } from "react";
import ActivityModel from "../../../models/activityModel/activityModel";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import ActivistModel from "../../../models/activistModel/activistModel";
import { EmptyGuid, INIT_SURE_VOTE } from "../../../initData/initData";
import { SureVoteShortDetails } from "../../../models/sureVotes/sureVoteShortDetails";
import { getAllCities } from "../../../slices/addressSlice/addressSlice";
import Connector from "../../../appSignalR";
import { useNavigate } from "react-router-dom";
import { selectCurrentAccount } from "../../../slices/auth/authSlice";
import { Role } from "../../../models/enums/roles";

interface Props {
  handleOpenActivityModal: any;
  handleOpenActivistModal: any;
  sureVote: SureVoteModel;
  setSureVote: any;
  sureVotes: any;
  selectedSureVote: any;
  setCanAddSureVote: any;
  resetSureVoteState: any;
  selectedActivity: any;
  setSelectedActivity: any;
  selectedActivists: ActivistModel | null;
  setSelectedActivists: any;
}

export default function ActivityDataAccordion({
  handleOpenActivistModal,
  handleOpenActivityModal,
  setSureVote,
  sureVote,
  sureVotes,
  selectedSureVote,
  setCanAddSureVote,
  resetSureVoteState,
  selectedActivity,
  setSelectedActivity,
  selectedActivists,
  setSelectedActivists,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);
  const sureVoteByActivist = useAppSelector(selectSureVotesByActivist);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [selectedSureVoteShortDetails, setSelectedSureVoteShortDetails] =
    useState<SureVoteShortDetails | null>(null);

  const { classes } = useActivityDataAccordion();

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);

    if (value) {
      dispatch(getActivistsByActivityId(value?.id || ""));
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activityId: value.id,
      }));
      setSelectedActivists(null);
      // dispatch(setSelectedActivityId(value?.id));
    } else {
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activityId: null,
      }));
      dispatch(setSelectedActivityId(null));
      setSelectedActivists(null);
      setSureVote(INIT_SURE_VOTE);
      dispatch(resetSureVote());
      dispatch(resetValidateSureVote());
    }
  };

  const handleActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedActivists(value);

    if (value) {
      dispatch(getSureVotesByActivistId(value.id || EmptyGuid));
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activistId: value.id,
      }));
      setCanAddSureVote(true);
    } else {
      setSureVote((prevSureVote: SureVoteModel) => ({
        ...prevSureVote,
        activistId: null,
      }));
      setCanAddSureVote(false);
    }
  };

  const handleSureVoteShortDetailsChange = (
    event: React.ChangeEvent<{}>,
    value: SureVoteShortDetails | null
  ) => {
    setSelectedSureVoteShortDetails(value);
    if (value) {
      dispatch(getSureVoteById(value?.id || EmptyGuid));
    } else {
      dispatch(resetSelectedSureVote());
    }
  };

  useEffect(() => {
    if (selectedSureVote) {
      setSureVote({ selectedSureVote });
    }
  }, [selectedSureVote]);

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ background: "#f5f5f5" }}
        >
          <Typography sx={{ textAlign: "left" }}>Podaci aktivnosti</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item md={12} sx={{}} mt={1}>
            <Grid container spacing={1} mt={1}>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Aktivnost
                  </Typography>
                  <Autocomplete
                    options={activities ? activities : []}
                    getOptionLabel={(option) => option.name}
                    value={selectedActivity}
                    onChange={handleActivityChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                    // value={selectedResidenceStreet}
                    // onChange={handleResidentStreet}
                  />
                </FormControl>
                {currentAccount?.role !== Role.User && (
                  <Link
                    onClick={handleOpenActivityModal}
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Upravljaj aktivnostima
                  </Link>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Aktivista
                  </Typography>
                  <Autocomplete
                    options={activists ? activists : []}
                    getOptionLabel={(option) =>
                      option.lastName + " " + option.firstName
                    }
                    value={selectedActivists}
                    onChange={handleActivistChange}
                    disabled={selectedActivity === null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                    // value={selectedResidenceStreet}
                    // onChange={handleResidentStreet}
                  />
                </FormControl>
                <Link
                  onClick={handleOpenActivistModal}
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Upravljaj aktivistima
                </Link>
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className={classes.inputTitle}>
                    Postojeći sigurni glasovi
                  </Typography>
                  <Autocomplete
                    options={sureVoteByActivist ? sureVoteByActivist : []}
                    getOptionLabel={(option) => option.displayName}
                    onChange={handleSureVoteShortDetailsChange}
                    value={selectedSureVoteShortDetails}
                    disabled={selectedActivists === null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                    // onChange={handleResidentStreet}
                  />
                </FormControl>
              </Grid> */}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
