import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { City } from "../../models/address/city";
import { Street } from "../../models/address/street";
import { RootState } from "../../app/store";
import {
  getAllCities as getAllCitiesApi,
  getStreetsByCityId as getStreetsByCityIdApi,
  getElectionPlaceRegionByCityId as getElectionPlaceRegionByCityIdApi,
  getElectionPlaceByCityId as getElectionPlaceByCityIdApi,
  electionPlaceByCityAndStreet as electionPlaceByCityAndStreetApi,
  getElectionPlaceById as getElectionPlaceByIdApi,
  getAllStates as getAllStatesApi,
  saveState as saveStateApi,
  saveCountry as saveCountryApi,
  getCountriesByStateId as getCountriesByStateIdApi,
  getCountryByName as getCountryByNameApi,
  saveCity as saveCityApi,
  getCitiesByCountryId as getCitiesByCountryIdApi,
  getMunicipalitiesByCountryId as getMunicipalitiesByCountryIdApi,
  saveMunicipality as saveMunicipalityApi,
  getPlaceByMunicipalityId as getPlacesByMunicipalityIdApi,
  getPlacesByCountryId as getPlacesByCountryIdApi,
  getPlacesByCityId as getPlacesByCityIdApi,
  getAllCountries as getAllCountriesApi,
  savePlace as savePlaceApi,
  saveLocalComunity as saveLocalComunityApi,
  getLocalComunityByPlaceId as getLocalComunityByPlaceIdApi,
  getStreetsByPlaceId as getStreetsByPlaceIdApi,
  saveStreet as saveStreetApi,
  getStreetById as getStreetByIdApi
} from "../../app/redux/addressApi";
import { ElectionPlaceRegion } from "../../models/address/electionPlaceRegion";
import { ElectionPlace } from "../../models/address/electionPlace";
import ElectionPlaceRequest from "../../models/address/electionPlaceRequest";
import { State } from "../../models/address/addressManagement/state";
import { Country } from "../../models/address/addressManagement/country";
import { ApiStatus } from "../../models/app/apiStatus";
import { CityAddressManagement } from "../../models/address/addressManagement/cityAddressManagement";
import { Municipality } from "../../models/address/addressManagement/municipality";
import { Place } from "../../models/address/addressManagement/place";
import { LocalComunity } from "../../models/address/addressManagement/localComunity";
import { StreetAddressManagement } from "../../models/address/addressManagement/streetAddressManagement";
import StreetDetailsModel from "../../models/address/streetDetailsModel";

type AddressState = {
  states: State[] | null;
  countries: Country[] | null;
  countriesByState: Country[] | null;
  countryByName: Country | null;
  citiesByCountry: CityAddressManagement[] | null;
  municipalitiesByCountryiId: Municipality[] | null;
  selectedMunicipality: Municipality | null;
  placesByMunicipalityId: Place[] | null;
  placesByCountryId: Place[] | null;
  placesByCityId: Place[] | null;
  localComunityByPlaceId: LocalComunity[] | null;
  streetsByPlaceId: StreetAddressManagement[] | null;
  cities: City[] | null;
  streets: Street[] | null;
  streetById: StreetDetailsModel | null;
  electionPlaceRegion: ElectionPlaceRegion[] | null;
  electionPlace: ElectionPlace[] | null;
  electionPlaceByCityAndStreet: ElectionPlace | null;
  electionPlaceById: ElectionPlace | null;
  electionPlaceByCityId: ElectionPlace[] | null;
  statuses: {
    savedCountry: ApiStatus;
    savedCity: ApiStatus;
    savedMunicipality: ApiStatus;
    savedPlace: ApiStatus;
    savedStreet: ApiStatus;
    savedLocalComunity: ApiStatus;
  };
};

const initialState: AddressState = {
  states: [],
  countries: [],
  countriesByState: [],
  countryByName: null,
  citiesByCountry: [],
  municipalitiesByCountryiId: [],
  selectedMunicipality: null,
  placesByMunicipalityId: [],
  placesByCountryId: [],
  placesByCityId: [],
  localComunityByPlaceId: [],
  streetsByPlaceId: [],
  cities: [],
  streets: [],
  streetById: null,
  electionPlaceRegion: [],
  electionPlace: null,
  electionPlaceByCityAndStreet: null,
  electionPlaceById: null,
  electionPlaceByCityId: null,
  statuses: {
    savedCountry: ApiStatus.Idle,
    savedCity: ApiStatus.Idle,
    savedMunicipality: ApiStatus.Idle,
    savedPlace: ApiStatus.Idle,
    savedStreet: ApiStatus.Idle,
    savedLocalComunity: ApiStatus.Idle,
  },
};

export const selectSaveCountryStatus = (state: RootState) =>
  state.address.statuses.savedCountry;
export const selectSaveCityStatus = (state: RootState) =>
  state.address.statuses.savedCity;
export const selectSaveMunicipalityStatus = (state: RootState) =>
  state.address.statuses.savedMunicipality;
export const selectSavePlaceStatus = (state: RootState) =>
  state.address.statuses.savedPlace;
export const selectSaveStreetStatus = (state: RootState) =>
  state.address.statuses.savedStreet;
export const selectLocalComunityStatus = (state: RootState) =>
  state.address.statuses.savedLocalComunity;

export const selectSelectedMunicipality = (state: RootState) =>
  state.address.selectedMunicipality;

  export const selectStreetById = (state: RootState) =>
  state.address.streetById;

export const selectStreetsByPlaceId = (state: RootState) =>
  state.address.streetsByPlaceId;
export const selectLocalComunityByPlaceId = (state: RootState) =>
  state.address.localComunityByPlaceId;
export const selectPlacesByCountryId = (state: RootState) =>
  state.address.placesByCountryId;
export const selectPlacesByMunicipalityId = (state: RootState) =>
  state.address.placesByMunicipalityId;
export const selectPlacesByCityId = (state: RootState) =>
  state.address.placesByCityId;
export const selectMunicipalitiesByCountryId = (state: RootState) =>
  state.address.municipalitiesByCountryiId;
export const selectCitiesByCountryId = (state: RootState) =>
  state.address.citiesByCountry;
export const selectStates = (state: RootState) => state.address.states;
export const selectCountries = (state: RootState) => state.address.countries;
export const selectCountriesByState = (state: RootState) =>
  state.address.countriesByState;
export const selectCountryByName = (state: RootState) =>
  state.address.countryByName;
export const selectCities = (state: RootState) => state.address.cities;
export const selectStreets = (state: RootState) => state.address.streets;
export const selectElectionPlaceRegion = (state: RootState) =>
  state.address.electionPlaceRegion;
export const selectElectionPlaceByCityAndStreet = (state: RootState) =>
  state.address.electionPlaceByCityAndStreet;
export const selectElectionPlaceById = (state: RootState) =>
  state.address.electionPlaceById;
export const selectElectionPlace = (state: RootState) =>
  state.address.electionPlace;

export const selectStreetsByCityId = (state: RootState) =>
  state.address.streets;

export const getAllStates = createAsyncThunk(
  "address/get_all_states",
  async () => {
    const response = await getAllStatesApi();
    return response.data;
  }
);

export const getAllCountries = createAsyncThunk(
  "address/get_all_countries",
  async () => {
    const response = await getAllCountriesApi();
    return response.data;
  }
);

export const getCountriesByStateId = createAsyncThunk(
  "address/get_countries_by_state_id",
  async (stateId: string | null) => {
    const response = await getCountriesByStateIdApi(stateId);
    return response.data;
  }
);

export const getCountryByName = createAsyncThunk(
  "address/get_countries_by_name",
  async (countryName: string | null) => {
    const response = await getCountryByNameApi(countryName);
    return response.data;
  }
);

export const getCitiesByCountryId = createAsyncThunk(
  "address/get_cities_by_country_id",
  async (countryId: string | null) => {
    const response = await getCitiesByCountryIdApi(countryId);
    return response.data;
  }
);

export const getMunicipalitiesByCountryId = createAsyncThunk(
  "address/get_municipalitiy_by_country_id",
  async (cityId: string | null) => {
    const response = await getMunicipalitiesByCountryIdApi(cityId);
    return response.data;
  }
);

export const getPlacesByMunicipalityId = createAsyncThunk(
  "address/get_places_by_municipality_id",
  async (municipalityId: string | null) => {
    const response = await getPlacesByMunicipalityIdApi(municipalityId);
    return response.data;
  }
);

export const getPlacesByCountryId = createAsyncThunk(
  "address/get_places_by_country_id",
  async (countryId: string | null) => {
    const response = await getPlacesByCountryIdApi(countryId);
    return response.data;
  }
);

export const getPlacesByCityId = createAsyncThunk(
  "address/get_places_by_city_id",
  async (cityId: string | null) => {
    const response = await getPlacesByCityIdApi(cityId);
    return response.data;
  }
);

export const getLocalComunityByPlaceId = createAsyncThunk(
  "address/get_local-comunity_by_place_id",
  async (placeId: string | null) => {
    const response = await getLocalComunityByPlaceIdApi(placeId);
    return response.data;
  }
);

export const getStreetsByPlaceId = createAsyncThunk(
  "address/get_streets_by_place_id",
  async (placeId: string | null) => {
    const response = await getStreetsByPlaceIdApi(placeId);
    return response.data;
  }
);

export const getStreetById = createAsyncThunk(
  "address/get_street_by_id",
  async (id: string | null) => {
    const response = await getStreetByIdApi(id);
    return response.data;
  }
);

export const saveState = createAsyncThunk(
  "address/save_state",
  async (request: State) => {
    const response = await saveStateApi(request);
    return response.data;
  }
);

export const saveCountry = createAsyncThunk(
  "address/save_state",
  async (request: Country) => {
    const response = await saveCountryApi(request);
    return response.data;
  }
);

export const saveCity = createAsyncThunk(
  "address/save_city",
  async (request: CityAddressManagement) => {
    const response = await saveCityApi(request);
    return response.data;
  }
);

export const saveMunicipality = createAsyncThunk(
  "address/save_municipality",
  async (request: Municipality) => {
    const response = await saveMunicipalityApi(request);
    return response.data;
  }
);

export const savePlace = createAsyncThunk(
  "address/save_place",
  async (request: Place) => {
    const response = await savePlaceApi(request);
    return response.data;
  }
);

export const saveLocalComunity = createAsyncThunk(
  "address/save_local_comunity",
  async (request: LocalComunity) => {
    const response = await saveLocalComunityApi(request);
    return response.data;
  }
);

export const saveStreet = createAsyncThunk(
  "address/save_street",
  async (request: StreetAddressManagement) => {
    const response = await saveStreetApi(request);
    return response.data;
  }
);

export const getAllCities = createAsyncThunk(
  "address/get_all_cities",
  async () => {
    const response = await getAllCitiesApi();
    return response.data;
  }
);

export const getStreetsByCityId = createAsyncThunk(
  "address/get_city_by_place_id",
  async (cityId: string) => {
    const response = await getStreetsByCityIdApi(cityId);
    return response.data;
  }
);

export const getElectionPlaceRegionByCityId = createAsyncThunk(
  "address/get_election_place_region_by_city_id",
  async (cityId: string) => {
    const response = await getElectionPlaceRegionByCityIdApi(cityId);
    return response.data;
  }
);

export const getElectionPlaceByCityId = createAsyncThunk(
  "address/get_election_place_by_city_id",
  async (cityId: string) => {
    const response = await getElectionPlaceByCityIdApi(cityId);
    return response.data;
  }
);

export const getELectionPlaceById = createAsyncThunk(
  "address/get_election_place_by_id",
  async (cityId: string) => {
    const response = await getElectionPlaceByIdApi(cityId);
    return response.data;
  }
);

export const getElectionPlaceByCityAndStreet = createAsyncThunk(
  "address/election_place_by_city_and_street",
  async (request: ElectionPlaceRequest) => {
    const response = await electionPlaceByCityAndStreetApi(request);
    return response.data;
  }
);

export const resetCountryStatus = createAsyncThunk(
  "address/reset_countryStatus",
  async () => {
    return initialState.statuses.savedCountry;
  }
);
export const resetCityStatus = createAsyncThunk(
  "address/reset_city_status",
  async () => {
    return initialState.statuses.savedCity;
  }
);

export const resetMunicipalityStatus = createAsyncThunk(
  "address/reset_municipality_status",
  async () => {
    return initialState.statuses.savedMunicipality;
  }
);

export const resetPlaceStatus = createAsyncThunk(
  "address/reset_place_status",
  async () => {
    return initialState.statuses.savedPlace;
  }
);

export const resetLocalComunityStatus = createAsyncThunk(
  "address/reset_local-comunity_statuss",
  async () => {
    return initialState.statuses.savedLocalComunity;
  }
);

export const resetStreetStatus = createAsyncThunk(
  "address/reset_street",
  async () => {
    return initialState.statuses.savedStreet;
  }
);

export const resetMunicipalitiesByCountryId = createAsyncThunk(
  "address/reset_municipalities_by_country_id",
  async () => {
    return initialState.municipalitiesByCountryiId;
  }
);

export const resetCitiesByCountryId = createAsyncThunk(
  "address/reset_cities_by_country_id",
  async () => {
    return initialState.citiesByCountry;
  }
);

export const resetPlacesByCountryId = createAsyncThunk(
  "address/reset_places_by_country_id",
  async () => {
    return initialState.placesByCountryId;
  }
);

export const resetStreetsByPlaceId = createAsyncThunk(
  "address/reset_streets_by_place_id",
  async () => {
    return initialState.streetsByPlaceId;
  }
);

export const resetStreetById = createAsyncThunk(
  "address/reset_street_by_id",
  async () => {
    return initialState.streetById;
  }
);

export const resetElectionPlaceByCityAndStreet = createAsyncThunk(
  "address/reset_election_place_by_city_and_id",
  async () => {
    return initialState.electionPlaceByCityAndStreet;
  }
);

export const resetElectionPlaceByCity = createAsyncThunk(
  "address/reset_election_place_by_city",
  async () => {
    return initialState.electionPlaceById;
  }
);
export const resetLocalComunity = createAsyncThunk(
  "address/reset_local-comunity",
  async () => {
    return initialState.localComunityByPlaceId;
  }
);
export const resetElectionPlaceById = createAsyncThunk(
  "address/reset_election_place_by_id",
  async () => {
    return initialState.electionPlace;
  }
);
export const resetElectionPlace = createAsyncThunk(
  "address/reset_election_place",
  async () => {
    return initialState.electionPlace;
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    clearPlaces: (state) => {
      state.cities = initialState.cities;
    },
    clearStreets: (state) => {
      state.streets = initialState.streets;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCountry.fulfilled, (state, action) => {
        state.statuses.savedCountry = ApiStatus.Fulfilled;
      })
      .addCase(saveCountry.pending, (state, action) => {
        state.statuses.savedCountry = ApiStatus.Pending;
      })
      .addCase(saveCountry.rejected, (state, action) => {
        state.statuses.savedCountry = ApiStatus.Rejected;
      })
      .addCase(saveCity.fulfilled, (state, action) => {
        state.statuses.savedCity = ApiStatus.Fulfilled;
      })
      .addCase(saveCity.pending, (state, action) => {
        state.statuses.savedCity = ApiStatus.Pending;
      })
      .addCase(saveCity.rejected, (state, action) => {
        state.statuses.savedCity = ApiStatus.Rejected;
      })
      .addCase(saveMunicipality.fulfilled, (state, action) => {
        state.statuses.savedMunicipality = ApiStatus.Fulfilled;
      })
      .addCase(saveMunicipality.pending, (state, action) => {
        state.statuses.savedMunicipality = ApiStatus.Pending;
      })
      .addCase(saveMunicipality.rejected, (state, action) => {
        state.statuses.savedMunicipality = ApiStatus.Rejected;
      })
      .addCase(savePlace.fulfilled, (state, action) => {
        state.statuses.savedPlace = ApiStatus.Fulfilled;
      })
      .addCase(savePlace.pending, (state, action) => {
        state.statuses.savedPlace = ApiStatus.Pending;
      })
      .addCase(savePlace.rejected, (state, action) => {
        state.statuses.savedPlace = ApiStatus.Rejected;
      })
      .addCase(saveLocalComunity.fulfilled, (state, action) => {
        state.statuses.savedLocalComunity = ApiStatus.Fulfilled;
      })
      .addCase(saveLocalComunity.pending, (state, action) => {
        state.statuses.savedLocalComunity = ApiStatus.Pending;
      })
      .addCase(saveLocalComunity.rejected, (state, action) => {
        state.statuses.savedLocalComunity = ApiStatus.Rejected;
      })
      .addCase(saveStreet.fulfilled, (state, action) => {
        state.statuses.savedStreet = ApiStatus.Fulfilled;
      })
      .addCase(saveStreet.pending, (state, action) => {
        state.statuses.savedStreet = ApiStatus.Pending;
      })
      .addCase(saveStreet.rejected, (state, action) => {
        state.statuses.savedStreet = ApiStatus.Rejected;
      })
      .addCase(getAllStates.fulfilled, (state, action) => {
        state.states = action.payload;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(getCountriesByStateId.fulfilled, (state, action) => {
        state.countriesByState = action.payload;
      })
      .addCase(getCountryByName.fulfilled, (state, action) => {
        state.countryByName = action.payload;
      })
      .addCase(getCitiesByCountryId.fulfilled, (state, action) => {
        state.citiesByCountry = action.payload;
      })
      .addCase(getStreetsByPlaceId.fulfilled, (state, action) => {
        state.streetsByPlaceId = action.payload;
      })
      .addCase(getStreetById.fulfilled, (state, action) => {
        state.streetById = action.payload.data;
      })
      .addCase(getMunicipalitiesByCountryId.fulfilled, (state, action) => {
        state.municipalitiesByCountryiId = action.payload;
        state.selectedMunicipality = action.payload[0] || null;
      })
      .addCase(getPlacesByMunicipalityId.fulfilled, (state, action) => {
        state.placesByMunicipalityId = action.payload;
      })
      .addCase(getPlacesByCountryId.fulfilled, (state, action) => {
        state.placesByCountryId = action.payload;
      })
      .addCase(getPlacesByCityId.fulfilled, (state, action) => {
        state.placesByCityId = action.payload;
      })
      .addCase(getLocalComunityByPlaceId.fulfilled, (state, action) => {
        state.localComunityByPlaceId = action.payload;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.cities = action.payload.data;
      })
      .addCase(getStreetsByCityId.fulfilled, (state, action) => {
        state.streets = action.payload.data;
      })
      .addCase(getElectionPlaceRegionByCityId.fulfilled, (state, action) => {
        state.electionPlaceRegion = action.payload.data;
      })
      .addCase(getElectionPlaceByCityId.fulfilled, (state, action) => {
        state.electionPlace = action.payload.data;
      })
      .addCase(getElectionPlaceByCityAndStreet.fulfilled, (state, action) => {
        state.electionPlaceByCityAndStreet = action.payload.data;
      })
      .addCase(getELectionPlaceById.fulfilled, (state, action) => {
        state.electionPlaceById = action.payload.data;
      })
      .addCase(resetElectionPlaceByCityAndStreet.fulfilled, (state, action) => {
        state.electionPlaceByCityAndStreet =
          initialState.electionPlaceByCityAndStreet;
      })
      .addCase(resetElectionPlace.fulfilled, (state, action) => {
        state.electionPlace = initialState.electionPlace;
      })
      .addCase(resetElectionPlaceByCity.fulfilled, (state, action) => {
        state.electionPlaceByCityId = initialState.electionPlaceByCityId;
      })
      .addCase(resetElectionPlaceById.fulfilled, (state, action) => {
        state.electionPlaceById = initialState.electionPlaceById;
      })
      .addCase(resetCountryStatus.fulfilled, (state, action) => {
        state.statuses.savedCountry = initialState.statuses.savedCountry;
      })
      .addCase(resetCityStatus.fulfilled, (state, action) => {
        state.statuses.savedCity = initialState.statuses.savedCity;
      })
      .addCase(resetMunicipalityStatus.fulfilled, (state, action) => {
        state.statuses.savedMunicipality =
          initialState.statuses.savedMunicipality;
      })
      .addCase(resetPlaceStatus.fulfilled, (state, action) => {
        state.statuses.savedPlace = initialState.statuses.savedPlace;
      })
      .addCase(resetLocalComunityStatus.fulfilled, (state, action) => {
        state.statuses.savedLocalComunity =
          initialState.statuses.savedLocalComunity;
      })
      .addCase(resetStreetStatus.fulfilled, (state, action) => {
        state.statuses.savedStreet = initialState.statuses.savedStreet;
      })
      .addCase(resetPlacesByCountryId.fulfilled, (state, action) => {
        state.placesByCountryId = initialState.placesByCountryId;
      })
      .addCase(resetMunicipalitiesByCountryId.fulfilled, (state, action) => {
        state.municipalitiesByCountryiId =
          initialState.municipalitiesByCountryiId;
      })
      .addCase(resetCitiesByCountryId.fulfilled, (state, action) => {
        state.citiesByCountry = initialState.citiesByCountry;
      })
      .addCase(resetStreetsByPlaceId.fulfilled, (state, action) => {
        state.streetsByPlaceId = initialState.streetsByPlaceId;
      })
      .addCase(resetStreetById.fulfilled, (state, action) => {
        state.streetById = initialState.streetById;
      })
      .addCase(resetLocalComunity.fulfilled, (state, action) => {
        state.localComunityByPlaceId = initialState.localComunityByPlaceId;
      });
  },
});

export const { clearPlaces, clearStreets } = addressSlice.actions;

export default addressSlice.reducer;
