export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  if (date && !isNaN(date.getDate())) {
    const day = date.getDay();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  } else {
    return "";
  }
};

export const validateDate = (input: string, endDate?: string): boolean => {
  const date = input.split("-");
  if (date.length === 3) {
    const day = parseInt(date[0], 10);
    const month = parseInt(date[1], 10);
    const year = parseInt(date[2], 10);

    const currentYear = new Date().getFullYear();

    if (year <= currentYear && year >= 1000) {
      const validDate = new Date(`${year}-${month}-${day}`);

      if (!isNaN(validDate.getDate())) {
        const [validDay, validMonth, validYear] = [
          validDate.getDate(),
          validDate.getMonth() + 1,
          validDate.getFullYear(),
        ];

        if (day === validDay && month === validMonth && year === validYear) {
          if (endDate) {
            const endDateParts = endDate.split("T")[0].split("-");
            if (endDateParts.length === 3) {
              const [endYear, endMonth, endDay] = endDateParts;
              const formattedEndDate = `${endDay}/${endMonth}/${endYear}`;
            }
          }
          return true;
        }
      }
    }
  }

  return false;
};
