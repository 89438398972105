import { Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import BasicInput from "../../../components/basicInput/basicInput";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import {
  selectSelectedActivityId,
  validateSureVote,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import { useEffect } from "react";
import { CapitalizeFirstLetter } from "../../../utilities/sureVoteUtility";
import { ValidateSureVoteRequest } from "../../../models/sureVotes/validateSureVoteRequest";
import ActivityModel from "../../../models/activityModel/activityModel";
import ActivistModel from "../../../models/activistModel/activistModel";
import { validateDate } from "../../../utilities/dateUtility";

interface Props {
  sureVote: SureVoteModel;
  setSureVote: React.Dispatch<React.SetStateAction<SureVoteModel>>;
  validationErrors: {
    firstName: string;
    lastName: string;
    birthDate: string;
    place: string;
    street: string;
    homeNumber: string;
  };
  setValidationErrors: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      birthDate: string;
      place: string;
      street: string;
      homeNumber: string;
    }>
  >;
  canAddSureVote?: boolean;
  selectedActivity: ActivityModel | null;
  selectedActivist: ActivistModel | null;
}

export default function PersonalDataInformation({
  setSureVote,
  sureVote,
  validationErrors,
  setValidationErrors,
  canAddSureVote,
  selectedActivity,
  selectedActivist,
}: Props) {
  const dispatch = useAppDispatch();
  const activityId = useAppSelector(selectSelectedActivityId);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    if (name === "name" || name === "surname") {
      value = CapitalizeFirstLetter(value);
    } else if (name === "birthDate") {
      const cursorPosition = e.target.selectionStart || 0;
      if (
        e.nativeEvent instanceof InputEvent &&
        e.nativeEvent.inputType === "deleteContentBackward"
      ) {
        if (cursorPosition > 0) {
          value = "";
        } 
      } else {
        value = value.replace(/\D/g, "");
        if (value.length >= 2) {
          value = value.substring(0, 2) + "-" + value.substring(2);
        }
        if (value.length >= 5) {
          value = value.substring(0, 5) + "-" + value.substring(5);
        }
        if (value.length > 10) {
          value = value.substring(0, 10);
        }
      }
    }
    setSureVote((prevState: SureVoteModel) => ({
      ...prevState,
      sureVoteRequest: {
        ...prevState.sureVoteRequest,
        [name]: value,
      },
    }));
  
    if (value) {
      if (name === "name") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          firstName: "",
        }));
      }
      if (name === "surname") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          lastName: "",
        }));
      }
    }
  };
  

  const handleBirthDateBlurChange = () => {
    var birthDate = sureVote.sureVoteRequest.birthDate;
    const isValidDate = validateDate(birthDate, selectedActivity?.endDate);

    if (!birthDate) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "",
      }));
    } else if (!isValidDate) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "Unesite datum u formatu dd-mm-yyyy.",
      }));
    } else {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "",
      }));

      var request: ValidateSureVoteRequest = {
        activityId: sureVote.activityId,
        birthDate: birthDate,
        name: sureVote.sureVoteRequest.name,
        surname: sureVote.sureVoteRequest.surname,
      };
      dispatch(validateSureVote(request));
    }
  };

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-name-field":
          var surnameField = document.getElementById("sure-vote-surname-field");
          surnameField?.focus();
          break;
        case "sure-vote-surname-field":
          var birthDateField = document.getElementById(
            "sure-vote-birthDate-field"
          );
          birthDateField?.focus();
          break;
        case "sure-vote-birthDate-field":
          var cityField = document.getElementById("sure-vote-city-field");
          cityField?.focus();
          break;
      }
    }
  };


  return (
    <Grid item md={12} sx={{ padding: "10px" }}>
      <Grid item md={12} sx={{ padding: "10px" }}>
        <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
          Podaci glasača
        </Typography>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item md={4} xs={12}>
          <BasicInput
            required
            label="Ime"
            value={sureVote.sureVoteRequest.name}
            onChange={handleFormChange}
            name="name"
            error={!!validationErrors.firstName}
            helperText={validationErrors.firstName}
            disabled={!selectedActivity || !selectedActivist}
            id="sure-vote-name-field"
            onKeyDown={(e) => handleOnKeyPressed(e, "sure-vote-name-field")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <BasicInput
            required
            label="Prezime"
            value={sureVote.sureVoteRequest.surname}
            onChange={handleFormChange}
            name="surname"
            error={!!validationErrors.lastName}
            helperText={validationErrors.lastName}
            disabled={!selectedActivity || !selectedActivist}
            id="sure-vote-surname-field"
            onKeyDown={(e) => handleOnKeyPressed(e, "sure-vote-surname-field")}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <BasicInput
            id="sure-vote-birthDate-field"
            required
            label="Datum rođ."
            value={sureVote.sureVoteRequest.birthDate}
            onChange={handleFormChange}
            onBlur={handleBirthDateBlurChange}
            name="birthDate"
            error={!!validationErrors.birthDate}
            helperText={validationErrors.birthDate}
            disabled={!selectedActivity || !selectedActivist}
            inputProps={{
              maxLength: 10,
              placeholder: "dd-mm-yyyy",
            }}
            onKeyDown={(e) =>
              handleOnKeyPressed(e, "sure-vote-birthDate-field")
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
