import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  resetSelectedSureVote,
  selectActivityCorrectionById,
  selectCorrectionId,
  selectSelectedSureVote,
  selectSelectedSureVoteCorrection,
  selectSureVoteStatus,
} from "../../../slices/sureVotesSlice/sureVotesSlice";
import BasicInput from "../../basicInput/basicInput";
import { useEffect, useState } from "react";
import { CapitalizeFirstLetter } from "../../../utilities/sureVoteUtility";
import { SureVoteModel } from "../../../models/sureVotes/sureVotes";
import { EmptyGuid, INIT_SURE_VOTE } from "../../../initData/initData";
import { City } from "../../../models/address/city";
import {
  getStreetsByCityId,
  selectCities,
  selectStreets,
} from "../../../slices/addressSlice/addressSlice";
import { Street } from "../../../models/address/street";
import AppButton from "../../button/AppButton";
import { validateDate } from "../../../utilities/dateUtility";
import { useUpdateSureVoteCorrectionModalStyle } from "./updateSureVoteCorrectionModalStyles";
import {
  SureVoteCorrectionModel,
  SureVoteCorrectionRequest,
} from "../../../models/sureVotes/sureVoteCorrectionModel";

interface Props {
  handleClose: any;
  open: boolean;
  sureVote: SureVoteCorrectionRequest;
  setSureVote: any;
  handleSave: any;
  setValidationErrors: any;
  validationErrors: any;
}

export default function UpdateSureVoteCorrectionModal({
  handleClose,
  open,
  setSureVote,
  sureVote,
  handleSave,
  setValidationErrors,
  validationErrors,
}: Props) {
  const { classes } = useUpdateSureVoteCorrectionModalStyle();
  const dispatch = useAppDispatch();
  const selectedSureVote = useAppSelector(selectSelectedSureVoteCorrection);
  console.log("selectedSureVote", selectedSureVote)
  const cities = useAppSelector(selectCities);
  const streetsByCity = useAppSelector(selectStreets) || undefined;
  const sureVoteStatus = useAppSelector(selectSureVoteStatus);
  const [place, setPlace] = useState<City | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<Street | null>(null);
  const activityCorrectionId = useAppSelector(selectCorrectionId)

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {

    let { name, value } = e.target;
    if (name === "name" || name === "surname") {
      value = CapitalizeFirstLetter(value);
    } else if (name === "birthDate") {
      value = value.replace(/\D/g, "");
      if (value.length >= 2) {
        value = value.substring(0, 2) + "-" + value.substring(2);
      }
      if (value.length >= 5) {
        value = value.substring(0, 5) + "-" + value.substring(5);
      }
      if (value.length > 10) {
        value = value.substring(0, 10);
      }
    }
    setSureVote((prevState: SureVoteCorrectionRequest) => ({
      sureVoteCorrection: {
        ...prevState.sureVoteCorrection,
        [name]: value,
      },
    }));
    setValidationErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBirthDateBlurChange = () => {
    var birthDate = sureVote.sureVoteCorrection.birthDate;
    const isValidDate = validateDate(birthDate);

    if (!isValidDate) {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "Unesite datum u formatu dd-mm-yyyy.",
      }));
    } else {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        birthDate: "",
      }));
    }
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    newValue: City | null
  ) => {
    if (!newValue) {
      setSureVote((prevState: SureVoteCorrectionRequest) => ({
        ...prevState,
        sureVoteCorrection: {
          cityId: "",
          cityName: "",
        },
      }));
      setSureVote((prevState: SureVoteCorrectionRequest) => ({
        sureVoteCorrection: {
          ...prevState.sureVoteCorrection,
          homeNumber: ""
        },
      }));
      setSelectedStreet(null);
      setPlace(null);

    } else {
      setSelectedStreet(null);
      setPlace(newValue);
      setSureVote((prevState: SureVoteCorrectionRequest) => ({
        sureVoteCorrection: {
          ...prevState.sureVoteCorrection,
          cityId: newValue.id,
          cityName: newValue.name,
        },
      }));
    }
  };

  const handleStreetChange = (
    event: React.ChangeEvent<{}>,
    newValue: Street | null
  ) => {
    setSureVote((prevState: SureVoteCorrectionRequest) => ({
      sureVoteCorrection: {
        ...prevState.sureVoteCorrection,
        streetId: newValue ? newValue.streetId : "",
        streetName: newValue ? newValue.streetName : "",
      },
    }));
    setSelectedStreet(newValue);
  };

  useEffect(() => {
    if (sureVote && sureVote.sureVoteCorrection?.cityId != "") {
      if (place === null || sureVote.sureVoteCorrection?.cityId !== place.id) {
        const sureVotePlace = cities?.find(
          (x) => x.id === sureVote.sureVoteCorrection?.cityId
        );
        setPlace(sureVotePlace || null);
        // dispatch(getStreetsByCityId(sureVotePlace?.id || EmptyGuid));
      }
    } else {
      setPlace(null);
    }
  }, [sureVote]);

  useEffect(() => {
    if (open && selectedSureVote) {
      setSureVote((prevCorrection: SureVoteCorrectionRequest) => ({
        ...prevCorrection,
        sureVoteCorrection: {
          name: selectedSureVote.name,
          surname: selectedSureVote.surname,
          birthDate: selectedSureVote.birthDate,
          homeNumber: selectedSureVote.homeNumber,
          cityId: selectedSureVote.cityId,
          id: selectedSureVote.id,
          cityName: selectedSureVote.cityName,
          streetName: selectedSureVote.streetName,
          streetId: selectedSureVote.streetId || "",
          phone: selectedSureVote.phone,
          activityCorrectionId: activityCorrectionId,
          municipalityName: selectedSureVote.municipalityName,
          note: selectedSureVote.note
        },
      }));
    }
  }, [selectedSureVote, open]);

  useEffect(() => {
    if (streetsByCity && streetsByCity.length > 0) {
      var street = streetsByCity.find(
        (x) => x.streetId === sureVote.sureVoteCorrection.streetId
      );
      if (street) {
        setSelectedStreet(street);
      }
    }
  }, [streetsByCity]);
  

  useEffect(() => {
    if (place) {
      dispatch(getStreetsByCityId(place.id));
      setSelectedStreet(null);
    }
  }, [place, dispatch]);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      switch (id) {
        case "sure-vote-name-field":
          var surnameField = document.getElementById("sure-vote-surname-field");
          surnameField?.focus();
          break;
        case "sure-vote-surname-field":
          var birthDateField = document.getElementById(
            "sure-vote-birthDate-field"
          );
          birthDateField?.focus();
          break;
        case "sure-vote-birthDate-field":
          var cityField = document.getElementById("sure-vote-city-field");
          cityField?.focus();
          break;
        case "sure-vote-city-field":
          var streetField = document.getElementById("sure-vote-street-field");
          streetField?.focus();
          break;
        case "sure-vote-street-field":
          var streetNumberField = document.getElementById(
            "sure-vote-streetNumber-field"
          );
          streetNumberField?.focus();
          break;
        case "sure-vote-streetNumber-field":
          var phoneField = document.getElementById("sure-vote-phone-field");
          phoneField?.focus();
          break;
        case "sure-vote-phone-field":
          var surnameField = document.getElementById("sure-vote-save-btn");
          surnameField?.focus();
          break;
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => {
          handleClose();
          dispatch(resetSelectedSureVote());
          setSureVote(INIT_SURE_VOTE);
          setSelectedStreet(null);
          setPlace(null);
        }}
      >
        <DialogTitle>
          <Grid item md={12} className={classes.dialogTitleWrapper}>
            <Typography>
              Ažuriranje sig. glasa:{" "}
              <b>{selectedSureVote?.surname + " " + selectedSureVote?.name}</b>
            </Typography>
            <IconButton
              onClick={() => {
                handleClose();
                dispatch(resetSelectedSureVote());
                setSureVote(INIT_SURE_VOTE);
                setSelectedStreet(null);
                setPlace(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          <Grid item md={12} sx={{ padding: "10px" }}>
            <Grid item md={12} sx={{ padding: "10px" }}>
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Podaci glasača
              </Typography>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item md={4} xs={12}>
                <BasicInput
                  required
                  label="Ime"
                  value={sureVote.sureVoteCorrection?.name}
                  onChange={handleFormChange}
                  name="name"
                  error={!!validationErrors.firstName}
                  helperText={validationErrors.firstName}
                  id="sure-vote-name-field"
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-name-field")
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <BasicInput
                  required
                  label="Prezime"
                  value={sureVote.sureVoteCorrection?.surname}
                  onChange={handleFormChange}
                  name="surname"
                  error={!!validationErrors.lastName}
                  helperText={validationErrors.lastName}
                  id="sure-vote-surname-field"
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-surname-field")
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <BasicInput
                  id="sure-vote-birthDate-field"
                  required
                  label="Datum rođ."
                  value={sureVote.sureVoteCorrection?.birthDate}
                  onChange={handleFormChange}
                  onBlur={handleBirthDateBlurChange}
                  name="birthDate"
                  error={!!validationErrors.birthDate}
                  helperText={validationErrors.birthDate}
                  inputProps={{
                    maxLength: 10,
                    placeholder: "dd-mm-yyyy",
                  }}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-birthDate-field")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/*End sure vote personal data*/}

          {/*Sure vote address data*/}
          <Grid item md={12} sx={{ padding: "10px" }}>
            <Grid container spacing={1} mt={1}>
              <Grid item md={4} xs={12}>
                <Typography variant="body1" className={classes.inputTitle}>
                  Mesto
                </Typography>
                <Autocomplete
                  id={"sure-vote-city-field"}
                  options={cities ? cities : []}
                  getOptionLabel={(option) => option.name}
                  value={place}
                  onChange={handleCityChange}
                  onKeyDown={(e: any) =>
                    handleOnKeyPressed(e, "sure-vote-city-field")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography variant="body1" className={classes.inputTitle}>
                  Ulica
                </Typography>
                <Autocomplete
                  id={"sure-vote-street-field"}
                  options={streetsByCity ? streetsByCity : []}
                  getOptionLabel={(option) => option.streetName}
                  onChange={handleStreetChange}
                  value={selectedStreet}
                  disabled={!place}
                  onKeyDown={(e: any) =>
                    handleOnKeyPressed(e, "sure-vote-street-field")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                      disabled={!place}
                      error={!!validationErrors.street} // Add this to show error state
                      helperText={validationErrors.street}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <BasicInput
                  id={"sure-vote-streetNumber-field"}
                  label="Kućni broj"
                  value={sureVote.sureVoteCorrection?.homeNumber}
                  onChange={handleFormChange}
                  name="homeNumber"
                  disabled={!place || !selectedStreet}
                  error={!!validationErrors.homeNumber}
                  helperText={validationErrors.homeNumber}
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-streetNumber-field")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/*End sure vote address data*/}

          {/*Sure vote contact data*/}
          <Grid item md={12} sx={{ padding: "10px" }}>
            <Grid container spacing={1} mt={1}>
              <Grid item md={4} xs={12}>
                <BasicInput
                  id={"sure-vote-phone-field"}
                  label={"Kontakt telefon"}
                  value={sureVote.sureVoteCorrection?.phone}
                  onChange={handleFormChange}
                  name="phone"
                  onKeyDown={(e) =>
                    handleOnKeyPressed(e, "sure-vote-phone-field")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/*End sure vote contact data*/}
          <Grid item md={12} sx={{ padding: "10px" }}>
            <BasicInput
              id={"sure-vote-phone-field"}
              label={"Napomena"}
              value={sureVote.sureVoteCorrection?.note}
              onChange={handleFormChange}
              name="note"
              onKeyDown={(e) => handleOnKeyPressed(e, "sure-vote-phone-field")}
              multiline={true}
              rows={4}
            />
          </Grid>
          <Grid container spacing={1} mt={1} justifyContent={"flex-end"}>
            <Grid item>
              <AppButton
                label="Zatvori"
                color={"rgba(255, 73, 71, 0.8)"}
                hover={"rgba(255, 73, 71, 0.8)"}
                onClick={() => {
                  handleClose();
                  dispatch(resetSelectedSureVote());
                  setSureVote(INIT_SURE_VOTE);
                  setSelectedStreet(null);
                  setPlace(null);
                }}
              />
            </Grid>
            <Grid item>
              <AppButton
                id="sure-vote-save-btn"
                label="Sačuvaj"
                color={"#4CAF50"}
                hover={"#4CAF50"}
                onClick={() => {
                  handleSave();
                  dispatch(resetSelectedSureVote());
                  setSureVote(INIT_SURE_VOTE);
                  setSelectedStreet(null);
                  setPlace(null);
                  handleClose();
                }}
                disabled={sureVoteStatus === "Pending"}
                startIcon={
                  sureVoteStatus === "Pending" && (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={15}
                    />
                  )
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
