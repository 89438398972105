import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  saveSureVote as saveSureVoteApi,
  saveActivity as saveActivityApi,
  getActivities as getActivitiesApi,
  getActivists as getActivistsApi,
  saveActivist as saveActivistApi,
  getListSureVotes as getListSureVotesApi,
  getSureVotesByActivistId as getSureVotesByActivistIdApi,
  getSureVoteById as getSureVoteByIdApi,
  validateSureVote as validateSureVoteApi,
  updateSureVote as updateSureVoteApi,
  deleteSureVote as deleteSureVoteApi,
  getSureVotesReport as getSureVotesReportApi,
  getActivistById as getActivistByIdApi,
  fastSearchSureVote as fastSearchSureVoteApi,
  updateSureVoteActivists as updateSureVoteActivistsApi,
  getSureVotesForTransfer as getSureVotesForTransferApi,
  transferSureVotes as trasnferSureVotesApi,
  createCorrectionSureVotes as createCorrectionSureVotesApi,
  createReportByDate as createReportByDateApi,
  getActivityCorectionByActivityId as getActivityCorectionByActivityIdApi,
  getActivityCorectionById as getActivityCorectionByIdApi,
  createActivityCorrection as createActivityCorrectionApi,
  deleteActivityCorrection as deleteActivityCorrectionApi,
  updateSurevoteCorrection as updateSurevoteCorrectionApi,
  getSelectedSureVoteCorrection as getSelectedSureVoteCorrectionApi,
  getActivityById as getActivityByIdApi
} from "../../app/redux/sureVotesApi";
import { ApiStatus } from "../../models/app/apiStatus";
import { SureVoteModel } from "../../models/sureVotes/sureVotes";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";
import { SureVoteShortDetails } from "../../models/sureVotes/sureVoteShortDetails";
import { SureVoteRequest } from "../../models/sureVotes/sureVoteRequest";
import { formatDate } from "../../utilities/dateUtility";
import dayjs from "dayjs";
import { ValidateSureVoteRequest } from "../../models/sureVotes/validateSureVoteRequest";
import SureVoteListRequest from "../../models/sureVotes/sureVoteListRequest";
import SureVoteListResponse from "../../models/sureVotes/sureVoteListResponse";
import { SureVoteValidate } from "../../models/sureVotes/sureVoteValidate";
import { ValidateSureVoteRequestModel } from "../../models/sureVotes/validateSureVoteResponse";
import { FastSearchRequest } from "../../models/sureVotes/fastSearchRequest";
import { UpdateSureVotesActivistRequest } from "../../models/sureVotes/updateSureVotesActivistRequest";
import { UpdateSureVotesActivityRequest } from "../../models/sureVotes/updateSureVotesActivityRequest";
import { SureVotesForTransferResponse } from "../../models/sureVotes/sureVotesForTransferResponse";
import TransferSureVotesRequest from "../../models/sureVotes/transferSureVotesRequest";
import { CurrentAccount } from "../../models/currentAccountModel/currentAccountModel";
import { CorrectionRequest } from "../../models/sureVotes/correctionRequest";
import { ReportByDateRequest } from "../../models/sureVotes/reportByDateRequest";
import { CreateActivityCorrection } from "../../models/sureVotes/createActivityCorrection";
import { ActivityCorrectionShort } from "../../models/sureVotes/activityCorrectionShort";
import { ActivityCorrectionView } from "../../models/sureVotes/activityCorrectionView";
import SureVoteListDetails from "../../models/sureVotes/sureVoteListDetails";
import { SureVoteCorrectionRequest } from "../../models/sureVotes/sureVoteCorrectionModel";
import SelectSureVoteCorrectionRequest from "../../models/sureVotes/sureVoteCorrectionRequest";
import SelectedSureVoteCorrection from "../../models/sureVotes/selectedSureVoteCorrection";

type SureVotesState = {
  sureVotesList: SureVoteListResponse | null;
  sureVotes: SureVoteModel[] | null;
  sureVotesByActivist: SureVoteShortDetails[] | null;
  sureVotesByNewActivist: SureVoteShortDetails[] | null;
  selectedSureVote: SureVoteRequest | null;
  sureVoteById: SureVoteListDetails | null;
  activityById: ActivityModel | null,
  activities: ActivityModel[] | null;
  activists: ActivistModel[] | null;
  newActivists: ActivistModel[] | null;
  oldActivists: ActivistModel[] | null;
  activistById: ActivistModel | null;
  statuses: {
    savedSureVote: ApiStatus;
    savedActivity: ApiStatus;
    savedActivist: ApiStatus;
    deletedSureVote: ApiStatus;
    fastSearch: ApiStatus;
    updateSureVotesActivist: ApiStatus;
    transferSureVotes: ApiStatus;
    report: ApiStatus;
    sureVoteById: ApiStatus;
    deletedActivityCorrection: ApiStatus;
    savedActivityCorrection: ApiStatus;
    sureVoteListStatus: ApiStatus;
    updateSureVoteCorrection: ApiStatus;
    selectedSureVoteCorrection: ApiStatus;
  };
  isActivistSaved: boolean | null;
  existingSureVote: SureVoteRequest | null;
  validateSureVotes: ValidateSureVoteRequestModel | null;
  selectedActivityId: string | null;
  selectedActivistId: string | null;
  createdReport: Blob | null;
  correctSureVotes: Blob | null;
  fastSearch: SureVoteRequest[] | null;
  transferSureVotes: SureVotesForTransferResponse[] | null;
  activityCorrectionByActivity: ActivityCorrectionShort[] | null;
  activityCorrectionById: ActivityCorrectionView | null;
  savedActivityCorrection: ActivityCorrectionShort | null;
  activityCorrectionId: string | null;
  selectedSureVoteCorrection: SelectedSureVoteCorrection | null;
};

const initialState: SureVotesState = {
  sureVotesList: null,
  sureVotes: [],
  sureVotesByActivist: [],
  sureVotesByNewActivist: [],
  selectedSureVote: null,
  sureVoteById: null,
  activityById: null,
  activities: [],
  activists: [],
  newActivists: [],
  oldActivists: [],
  isActivistSaved: null,
  activistById: null,
  validateSureVotes: null,
  correctSureVotes: null,
  statuses: {
    savedSureVote: ApiStatus.Idle,
    savedActivist: ApiStatus.Idle,
    savedActivity: ApiStatus.Idle,
    deletedSureVote: ApiStatus.Idle,
    fastSearch: ApiStatus.Idle,
    updateSureVotesActivist: ApiStatus.Idle,
    transferSureVotes: ApiStatus.Idle,
    report: ApiStatus.Idle,
    sureVoteById: ApiStatus.Idle,
    deletedActivityCorrection: ApiStatus.Idle,
    savedActivityCorrection: ApiStatus.Idle,
    sureVoteListStatus: ApiStatus.Idle,
    updateSureVoteCorrection: ApiStatus.Idle,
    selectedSureVoteCorrection: ApiStatus.Idle
  },
  existingSureVote: null,
  selectedActivityId: null,
  selectedActivistId: null,
  createdReport: null,
  fastSearch: [],
  transferSureVotes: [],
  activityCorrectionByActivity: [],
  activityCorrectionById: null,
  savedActivityCorrection: null,
  activityCorrectionId: null,
  selectedSureVoteCorrection: null
};

export const selectSureVotes = (state: RootState) =>
  state.sureVotes.sureVotesList;

export const selectExistingSureVote = (state: RootState) =>
  state.sureVotes.existingSureVote;

export const selectValidateSureVotes = (state: RootState) =>
  state.sureVotes.validateSureVotes;

export const selectSureVotesByActivist = (state: RootState) =>
  state.sureVotes.sureVotesByActivist;

  export const selectSureVotesByNewActivist = (state: RootState) =>
  state.sureVotes.sureVotesByNewActivist;

export const selectActivists = (state: RootState) => state.sureVotes.activists;

export const selectNewActivists = (state: RootState) =>
  state.sureVotes.newActivists;

export const selectOldActivists = (state: RootState) =>
  state.sureVotes.oldActivists;

  export const selectActivityById = (state: RootState) =>
  state.sureVotes.activityById;

export const selectActivities = (state: RootState) =>
  state.sureVotes.activities;

export const selectSelectedSureVote = (state: RootState) =>
  state.sureVotes.selectedSureVote;

export const selectSureVoteById = (state: RootState) =>
  state.sureVotes.sureVoteById;

export const selectSureVoteStatus = (state: RootState) =>
  state.sureVotes.statuses.savedSureVote;

export const selectActivistStatus = (state: RootState) =>
  state.sureVotes.statuses.savedActivist;

export const selectActivityStatus = (state: RootState) =>
  state.sureVotes.statuses.savedActivity;

export const selectStreetsByPlaceId = (state: RootState) =>
  state.address.streetsByPlaceId;

export const selectIsActivistSaved = (state: RootState) =>
  state.sureVotes.isActivistSaved;

export const selectSelectedActivityId = (state: RootState) =>
  state.sureVotes.selectedActivityId;

export const selectSelectedActivistId = (state: RootState) =>
  state.sureVotes.selectedActivistId;

  export const selectCorrectionId = (state: RootState) =>
  state.sureVotes.activityCorrectionId;

export const selectActivistById = (state: RootState) =>
  state.sureVotes.activistById;

export const selectDeletetSureVoteStatus = (state: RootState) =>
  state.sureVotes.statuses.deletedSureVote;

export const selectFastSearchResults = (state: RootState) =>
  state.sureVotes.fastSearch;

export const selectFastSearchStatus = (state: RootState) =>
  state.sureVotes.statuses.fastSearch;

export const selectUpdateSureVotesActivist = (state: RootState) =>
  state.sureVotes.statuses.updateSureVotesActivist;

export const selectSureVotesForTransfer = (state: RootState) =>
  state.sureVotes.transferSureVotes;

export const selectSureVotesForTransferStatus = (state: RootState) =>
  state.sureVotes.statuses.transferSureVotes;

export const selectReport = (state: RootState) => state.sureVotes.createdReport;

export const selectReportStatus = (state: RootState) =>
  state.sureVotes.statuses.report;

export const selectReportCorrect = (state: RootState) =>
  state.sureVotes.correctSureVotes;

export const selectSureVoteByIdStatus = (state: RootState) =>
  state.sureVotes.statuses.sureVoteById;

export const selectActivityCorrectionByActivity = (state: RootState) =>
  state.sureVotes.activityCorrectionByActivity;

export const selectActivityCorrectionById = (state: RootState) =>
  state.sureVotes.activityCorrectionById;

export const selectDeletedActivityCorrection = (state: RootState) =>
  state.sureVotes.statuses.deletedActivityCorrection;

export const selectSavedActivityCorrection = (state: RootState) =>
  state.sureVotes.savedActivityCorrection;

export const selectSavedActivityCorrectionStatus = (state: RootState) =>
  state.sureVotes.statuses.savedActivityCorrection;

export const selectListStatus = (state: RootState) =>
  state.sureVotes.statuses.sureVoteListStatus;

  export const selectUpdateSureVoteCorrectionStatus = (state: RootState) =>
  state.sureVotes.statuses.updateSureVoteCorrection;

  export const selectSelectedSureVoteCorrection = (state: RootState) =>
  state.sureVotes.selectedSureVoteCorrection;

  export const selectSelectedSureVoteCorrectionStatus = (state: RootState) =>
  state.sureVotes.statuses.selectedSureVoteCorrection;

export const saveSureVote = createAsyncThunk(
  "sureVotes/save_sure-vote",
  async (request: SureVoteModel) => {
    const response = await saveSureVoteApi(request);
    return response.data;
  }
);

export const updateSureVote = createAsyncThunk(
  "sureVotes/update_sure-vote",
  async (request: SureVoteModel) => {
    const response = await updateSureVoteApi(request);
    return response.data;
  }
);

export const deleteSureVote = createAsyncThunk(
  "sureVotes/delete_sure-vote",
  async (sureVoteId: string) => {
    const response = await deleteSureVoteApi(sureVoteId);
    return response.data;
  }
);

export const deleteActivityCorrection = createAsyncThunk(
  "sureVotes/delete_activity_correction_sure_vote",
  async (id: string) => {
    const response = await deleteActivityCorrectionApi(id);
    return response.data;
  }
);

export const saveActivity = createAsyncThunk(
  "sureVotes/save_activity",
  async (request: ActivityModel) => {
    const response = await saveActivityApi(request);
    return response.data;
  }
);

export const saveActivist = createAsyncThunk(
  "sureVotes/save_activist",
  async (request: ActivistModel) => {
    const response = await saveActivistApi(request);
    return response.data;
  }
);

export const getListSureVotes = createAsyncThunk(
  "sureVotes/get_list_sure-votes",
  async (sureVoteListRequest: SureVoteListRequest) => {
    const response = await getListSureVotesApi(sureVoteListRequest);
    return response.data;
  }
);

export const getSureVotesByActivistId = createAsyncThunk(
  "sureVotes/get_all_sure_votes_by_activist",
  async (activistId: string) => {
    const response = await getSureVotesByActivistIdApi(activistId);
    return response.data;
  }
);

export const getSureVotesByNewActivistId = createAsyncThunk(
  "sureVotes/get_all_sure_votes_by_new_activist",
  async (activistId: string) => {
    const response = await getSureVotesByActivistIdApi(activistId);
    return response.data;
  }
);

export const getSureVoteById = createAsyncThunk(
  "sureVotes/get_sure_vote_by_id",
  async (id: string) => {
    const response = await getSureVoteByIdApi(id);
    return response.data;
  }
);

export const getActivityById = createAsyncThunk(
  "sureVotes/get_activity_by_id",
  async (id: string) => {
    const response = await getActivityByIdApi(id);
    return response.data;
  }
);

export const getActivities = createAsyncThunk(
  "sureVotes/get_all_activities",
  async () => {
    const response = await getActivitiesApi();
    return response.data;
  }
);

export const getActivistsByActivityId = createAsyncThunk(
  "sureVotes/get_activists_by_activity",
  async (activityId: string) => {
    const response = await getActivistsApi(activityId);
    return response.data;
  }
);

export const getNewActivistsByActivityId = createAsyncThunk(
  "sureVotes/get_new_activists_by_activity",
  async (activityId: string) => {
    const response = await getActivistsApi(activityId);
    return response.data;
  }
);

export const getOldActivistsByActivityId = createAsyncThunk(
  "sureVotes/get_old_activists_by_activity",
  async (activityId: string) => {
    const response = await getActivistsApi(activityId);
    return response.data;
  }
);

export const getActivistById = createAsyncThunk(
  "sureVotes/get_activist_by_id",
  async (id: string) => {
    const response = await getActivistByIdApi(id);
    return response.data;
  }
);

export const getSelectedSureVoteCorrection = createAsyncThunk(
  "sureVotes/get_selected_surevote_correction",
  async (request: SelectSureVoteCorrectionRequest) => {
    const response = await getSelectedSureVoteCorrectionApi(request);
    return response.data;
  }
);

export const getSureVoteReport = createAsyncThunk(
  "sureVotes/get_sure_votes_report",
  async (sureVoteListRequest: SureVoteListRequest) => {
    const response = await getSureVotesReportApi(sureVoteListRequest);
    return response;
  }
);

export const updateSureVotesActivists = createAsyncThunk(
  "sureVotes/update_sure_votes_activist",
  async (request: UpdateSureVotesActivistRequest) => {
    const response = await updateSureVoteActivistsApi(request);
    return response;
  }
);

export const getSureVotesForTransfer = createAsyncThunk(
  "sureVotes/get_sure_votes_for_transfer",
  async (request: UpdateSureVotesActivityRequest) => {
    const response = await getSureVotesForTransferApi(request);
    return response;
  }
);

export const saveTrasnferSureVotes = createAsyncThunk(
  "sureVotes/transfer_sure_votes",
  async (request: TransferSureVotesRequest) => {
    const response = await trasnferSureVotesApi(request);
    return response;
  }
);

export const createCorrectionSureVotes = createAsyncThunk(
  "sureVotes/create_correction_sure_votes",
  async (request: CreateActivityCorrection, thunkAPI) => {
    const response = await createCorrectionSureVotesApi(request);
    return response;
  }
);

export const updateSurevoteCorrection = createAsyncThunk(
  "ActivityCorrection/update_correction_sure_votes",
  async (request: SureVoteCorrectionRequest) => {
    const response = await updateSurevoteCorrectionApi(request);
    return response;
  }
);

export const saveActivityCorrection = createAsyncThunk(
  "ActivityCorrection/save_activity_correction",
  async (request: CreateActivityCorrection) => {
    const response = await createActivityCorrectionApi(request);
    return response;
  }
);

export const getActivityCorectionByActivityId = createAsyncThunk(
  "sureVotes/get_activity_correction_by_activity_id",
  async (activityId: string) => {
    const response = await getActivityCorectionByActivityIdApi(activityId);
    return response;
  }
);

export const getActivityCorrectionById = createAsyncThunk(
  "sureVotes/get_activity_correction_by_id",
  async (id: string) => {
    const response = await getActivityCorectionByIdApi(id);
    return response;
  }
);

export const resetSelectedSureVote = createAsyncThunk(
  "sureVotes/reset_selected_sure_vote",
  async () => {
    return null;
  }
);

export const resetActivityById = createAsyncThunk(
  "sureVotes/reset_activity_by_id",
  async () => {
    return null;
  }
);

export const resetSureVoteStatus = createAsyncThunk(
  "sureVotes/reset_sure-vote_status",
  async () => {
    return initialState.statuses.savedSureVote;
  }
);

export const resetUpdateSureVoteCorrectionStatus = createAsyncThunk(
  "sureVotes/reset_update_sure-vote_correction_status",
  async () => {
    return initialState.statuses.updateSureVoteCorrection;
  }
);

export const resetDeleteSureVoteStatus = createAsyncThunk(
  "sureVotes/reset_delete_sure-vote_status",
  async () => {
    return initialState.statuses.deletedSureVote;
  }
);

export const resetDeleteActivityCorrectionStatus = createAsyncThunk(
  "sureVotes/reset_delete_activity_correction_status",
  async () => {
    return initialState.statuses.deletedActivityCorrection;
  }
);

export const resetActivistStatus = createAsyncThunk(
  "sureVotes/reset_activist_status",
  async () => {
    return initialState.statuses.savedActivist;
  }
);

export const resetReport = createAsyncThunk(
  "sureVotes/reset_report",
  async () => {
    return initialState.createdReport;
  }
);

export const resetReportCorrection = createAsyncThunk(
  "sureVotes/reset_report",
  async () => {
    return initialState.correctSureVotes;
  }
);

export const resetActivityStatus = createAsyncThunk(
  "sureVotes/reset_activity_status",
  async () => {
    return initialState.statuses.savedActivity;
  }
);

export const resetListStatus = createAsyncThunk(
  "sureVotes/reset_list_status",
  async () => {
    return initialState.statuses.sureVoteListStatus;
  }
);

export const resetIsActivistSaved = createAsyncThunk(
  "sureVotes/reset_is_activist_saved",
  async () => {
    return null;
  }
);

export const resetValidateSureVote = createAsyncThunk(
  "sureVotes/reset_validate_sure_vote",
  async () => {
    return null;
  }
);

export const resetSureVoteList = createAsyncThunk(
  "sureVotes/reset_sure_vote_list",
  async () => {
    return null;
  }
);

export const resetSelectedActivistId = createAsyncThunk(
  "sureVotes/reset_selected_activist_id",
  async () => {
    return null;
  }
);

export const resetActivists = createAsyncThunk(
  "sureVotes/reset_activists",
  async () => {
    return [];
  }
);

export const resetSureVote = createAsyncThunk(
  "sureVotes/reset_sure_vote",
  async () => {
    return null;
  }
);

export const resetTransferSureVotes = createAsyncThunk(
  "sureVotes/reset_transfer_sure_vote",
  async () => {
    return null;
  }
);

export const resetSelectedActivityId = createAsyncThunk(
  "sureVotes/reset_selected_activity_id",
  async () => {
    return null;
  }
);

export const resetSelectedSureVoteCorrection = createAsyncThunk(
  "sureVotes/reset_selected_sure_vote_correction",
  async () => {
    return null;
  }
);

export const resetSureVotesByActivist = createAsyncThunk(
  "sureVotes/reset_sure_votes_by_activist",
  async () => {
    return null;
  }
);

export const resetFastSearch = createAsyncThunk(
  "sureVotes/reset_fast_search",
  async () => {
    return null;
  }
);

export const resetFastSearchStatus = createAsyncThunk(
  "sureVotes/reset_fast_search_status",
  async () => {
    return null;
  }
);

export const resetUpdateSureVotesActivistStatus = createAsyncThunk(
  "sureVotes/reset_update_sure_votes_activist_status",
  async () => {
    return null;
  }
);

export const resetTransferSureVotesStatus = createAsyncThunk(
  "sureVotes/reset_transfer_sure_votes_status",
  async () => {
    return null;
  }
);

export const resetReportStatus = createAsyncThunk(
  "sureVotes/reset_report_status",
  async () => {
    return initialState.statuses.report;
  }
);

export const resetSureVoteByIdStatus = createAsyncThunk(
  "sureVotes/reset_sure_vote_by_id_status",
  async () => {
    return initialState.statuses.sureVoteById;
  }
);

export const resetSavedActivityCorrection = createAsyncThunk(
  "sureVotes/reset_saved_activity_correction",
  async () => {
    return null;
  }
);

export const resetSavedActivityCorrectionStatus = createAsyncThunk(
  "sureVotes/reset_saved_activity_correction_status",
  async () => {
    return initialState.statuses.sureVoteById;
  }
);

export const setSelectedActivityId = createAsyncThunk(
  "sureVotes/set_selected_activity_id",
  async (activityId: string | null) => {
    return activityId;
  }
);

export const setSelectedActivistId = createAsyncThunk(
  "sureVotes/set_selected_activist_id",
  async (activistId: string | null) => {
    return activistId;
  }
);

export const setSelectedCorrectionId = createAsyncThunk(
  "sureVotes/set_selected_correction_id",
  async (correctionId: string | null) => {
    return correctionId;
  }
);

export const validateSureVote = createAsyncThunk(
  "sureVotes/validate-sure-vote",
  async (request: ValidateSureVoteRequest) => {
    const response = await validateSureVoteApi(request);
    return response.data;
  }
);

export const fastSearchSureVote = createAsyncThunk(
  "sureVotes/fast-search-sure-vote",
  async (request: FastSearchRequest) => {
    const response = await fastSearchSureVoteApi(request);
    return response.data;
  }
);

const sureVotesSlice = createSlice({
  name: "sure-votes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveSureVote.pending, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Pending;
      })
      .addCase(saveSureVote.fulfilled, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Fulfilled;
      })
      .addCase(saveSureVote.rejected, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Rejected;
      })

      .addCase(updateSureVote.pending, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Pending;
      })
      .addCase(updateSureVote.fulfilled, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Fulfilled;
        state.selectedSureVote = null;
      })
      .addCase(updateSureVote.rejected, (state, action) => {
        state.statuses.savedSureVote = ApiStatus.Rejected;
      })
      .addCase(getSureVoteReport.pending, (state, action) => {
        state.statuses.report = ApiStatus.Pending;
      })

      .addCase(getSureVoteReport.fulfilled, (state, action) => {
        const fileBlob = action.payload.data;
        const file = new Blob([fileBlob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        state.createdReport = file;
        state.statuses.report = ApiStatus.Fulfilled;
      })
      .addCase(createCorrectionSureVotes.pending, (state, action) => {
  
        state.statuses.report = ApiStatus.Pending;
      })
      .addCase(createCorrectionSureVotes.fulfilled, (state, action) => {
        const fileBlob = action.payload.data;
        const file = new Blob([fileBlob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        state.correctSureVotes = file;
        state.statuses.report = ApiStatus.Fulfilled;
      })
      .addCase(getActivityCorectionByActivityId.fulfilled, (state, action) => {
        state.activityCorrectionByActivity = action.payload.data;
      })
      .addCase(getActivityCorrectionById.fulfilled, (state, action) => {
        state.activityCorrectionById = action.payload.data;
      })
      .addCase(getSelectedSureVoteCorrection.fulfilled, (state, action) => {
        state.selectedSureVoteCorrection = action.payload;
        state.statuses.selectedSureVoteCorrection = ApiStatus.Fulfilled;
      })
      .addCase(getSelectedSureVoteCorrection.pending, (state, action) => {
        state.statuses.selectedSureVoteCorrection = ApiStatus.Pending;
      })
      .addCase(resetSelectedSureVoteCorrection.fulfilled, (state, action) => {
        state.selectedSureVoteCorrection = null;
      })
      .addCase(deleteSureVote.pending, (state, action) => {
        state.statuses.deletedSureVote = ApiStatus.Pending;
      })
      .addCase(deleteSureVote.fulfilled, (state, action) => {
        state.selectedSureVote = null;
        state.statuses.deletedSureVote = ApiStatus.Fulfilled;
      })
      .addCase(deleteSureVote.rejected, (state, action) => {
        state.statuses.deletedSureVote = ApiStatus.Rejected;
      })

      .addCase(saveActivityCorrection.pending, (state, action) => {
        state.statuses.savedActivityCorrection = ApiStatus.Pending;
      })
      .addCase(updateSurevoteCorrection.pending, (state, action) => {
        state.statuses.updateSureVoteCorrection = ApiStatus.Pending;
      })
      .addCase(updateSurevoteCorrection.fulfilled, (state, action) => {
        state.statuses.updateSureVoteCorrection = ApiStatus.Fulfilled;
      })
      .addCase(saveActivityCorrection.fulfilled, (state, action) => {
        state.savedActivityCorrection = action.payload.data;
        state.statuses.savedActivityCorrection = ApiStatus.Fulfilled;
      })
      .addCase(saveActivityCorrection.rejected, (state, action) => {
        state.statuses.deletedActivityCorrection = ApiStatus.Rejected;
      })

      .addCase(deleteActivityCorrection.pending, (state, action) => {
        state.statuses.deletedActivityCorrection = ApiStatus.Pending;
      })
      .addCase(deleteActivityCorrection.fulfilled, (state, action) => {
        state.selectedSureVote = null;
        state.statuses.deletedActivityCorrection = ApiStatus.Fulfilled;
      })
      .addCase(deleteActivityCorrection.rejected, (state, action) => {
        state.statuses.deletedActivityCorrection = ApiStatus.Rejected;
      })

      .addCase(saveActivity.pending, (state, action) => {
        state.statuses.savedActivity = ApiStatus.Pending;
      })
      .addCase(saveActivity.fulfilled, (state, action) => {
        state.statuses.savedActivity = ApiStatus.Fulfilled;
      })
      .addCase(saveActivity.rejected, (state, action) => {
        state.statuses.savedActivity = ApiStatus.Rejected;
      })

      .addCase(saveActivist.pending, (state, action) => {
        state.statuses.savedActivist = ApiStatus.Pending;
      })
      .addCase(saveActivist.fulfilled, (state, action) => {
        state.statuses.savedActivist = ApiStatus.Fulfilled;
        state.isActivistSaved = true;
      })
      .addCase(saveActivist.rejected, (state, action) => {
        state.statuses.savedActivist = ApiStatus.Rejected;
      })
      .addCase(resetSavedActivityCorrection.fulfilled, (state, action) => {
        state.savedActivityCorrection = initialState.savedActivityCorrection;
      })
      .addCase(
        resetSavedActivityCorrectionStatus.fulfilled,
        (state, action) => {
          state.statuses.savedActivityCorrection =
            initialState.statuses.savedActivityCorrection;
        }
      )

      .addCase(resetSureVoteStatus.fulfilled, (state, action) => {
        state.statuses.savedSureVote = initialState.statuses.savedSureVote;
      })
      .addCase(resetUpdateSureVoteCorrectionStatus.fulfilled, (state, action) => {
        state.statuses.updateSureVoteCorrection = initialState.statuses.updateSureVoteCorrection;
      })
      .addCase(resetSelectedSureVote.fulfilled, (state) => {
        state.selectedSureVote = null;
      })
      .addCase(resetActivistStatus.fulfilled, (state, action) => {
        state.statuses.savedActivist = initialState.statuses.savedActivist;
      })
      .addCase(resetActivityStatus.fulfilled, (state, action) => {
        state.statuses.savedActivity = initialState.statuses.savedActivity;
      })
      .addCase(resetIsActivistSaved.fulfilled, (state) => {
        state.isActivistSaved = null;
      })
      .addCase(resetActivists.fulfilled, (state) => {
        state.activists = initialState.activists;
      })
      .addCase(resetActivityById.fulfilled, (state) => {
        state.activityById = initialState.activityById;
      })
      .addCase(resetSureVotesByActivist.fulfilled, (state) => {
        state.sureVotesByActivist = initialState.sureVotesByActivist;
      })
      .addCase(getListSureVotes.pending, (state, action) => {
        state.statuses.sureVoteListStatus = ApiStatus.Pending;
      })
      .addCase(getListSureVotes.fulfilled, (state, action) => {
        state.sureVotesList = action.payload;
        state.statuses.sureVoteListStatus = ApiStatus.Fulfilled;
      })
      .addCase(getSureVotesByActivistId.fulfilled, (state, action) => {
        state.sureVotesByActivist = action.payload;
      })
      .addCase(getSureVotesByNewActivistId.fulfilled, (state, action) => {
        state.sureVotesByNewActivist = action.payload;
      })
      .addCase(getSureVoteById.fulfilled, (state, action) => {
        state.selectedSureVote = action.payload;
        state.sureVoteById = action.payload;
        state.statuses.sureVoteById = ApiStatus.Fulfilled;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.activities = action.payload;
      })
      .addCase(getActivityById.fulfilled, (state, action) => {
        state.activityById = action.payload;
      })
      .addCase(getActivistsByActivityId.fulfilled, (state, action) => {
        state.activists = action.payload;
      })
      .addCase(getNewActivistsByActivityId.fulfilled, (state, action) => {
        state.newActivists = action.payload;
      })
      .addCase(getOldActivistsByActivityId.fulfilled, (state, action) => {
        state.oldActivists = action.payload;
      })
      .addCase(getActivistById.fulfilled, (state, action) => {
        state.activistById = action.payload;
      })
      .addCase(validateSureVote.fulfilled, (state, action) => {
        if (action.payload.isPossibleDuplicate) {
          state.existingSureVote = action.payload.sureVotes[0];
        } else {
          state.validateSureVotes = action.payload;
        }
      })

      .addCase(validateSureVote.rejected, (state) => {
        state.existingSureVote = null;
      })
      .addCase(fastSearchSureVote.pending, (state, action) => {
        state.statuses.fastSearch = ApiStatus.Pending;
      })
      .addCase(fastSearchSureVote.fulfilled, (state, action) => {
        state.fastSearch = action.payload;
        state.statuses.fastSearch = ApiStatus.Fulfilled;
      })
      .addCase(fastSearchSureVote.rejected, (state, action) => {
        state.statuses.fastSearch = ApiStatus.Rejected;
      })
      .addCase(updateSureVotesActivists.pending, (state, action) => {
        state.statuses.updateSureVotesActivist = ApiStatus.Pending;
      })
      .addCase(updateSureVotesActivists.fulfilled, (state, action) => {
        state.statuses.updateSureVotesActivist = ApiStatus.Fulfilled;
      })
      .addCase(updateSureVotesActivists.rejected, (state, action) => {
        state.statuses.updateSureVotesActivist = ApiStatus.Rejected;
      })
      .addCase(saveTrasnferSureVotes.pending, (state, action) => {
        state.statuses.transferSureVotes = ApiStatus.Pending;
      })
      .addCase(saveTrasnferSureVotes.fulfilled, (state, action) => {
        state.statuses.transferSureVotes = ApiStatus.Fulfilled;
      })
      .addCase(saveTrasnferSureVotes.rejected, (state, action) => {
        state.statuses.transferSureVotes = ApiStatus.Rejected;
      })
      .addCase(resetValidateSureVote.fulfilled, (state) => {
        state.validateSureVotes = initialState.validateSureVotes;
        state.existingSureVote = initialState.existingSureVote;
      })
      .addCase(resetSureVoteList.fulfilled, (state) => {
        state.sureVotesList = null;
      })
      .addCase(resetSureVote.fulfilled, (state) => {
        state.sureVotes = initialState.sureVotes;
      })
      .addCase(setSelectedActivityId.fulfilled, (state, action) => {
        state.selectedActivityId = action.payload;
      })
      .addCase(setSelectedActivistId.fulfilled, (state, action) => {
        state.selectedActivistId = action.payload;
      })
      .addCase(resetSelectedActivistId.fulfilled, (state, action) => {
        state.selectedActivistId = null;
      })
      .addCase(setSelectedCorrectionId.fulfilled, (state, action) => {
        state.activityCorrectionId = action.payload;
      })
      // .addCase(setSelectedCorrectionId.fulfilled, (state, action) => {
      //   state.activityCorrectionId = null;
      // })
      .addCase(resetSelectedActivityId.fulfilled, (state, action) => {
        state.selectedActivityId = null;
      })
      .addCase(resetListStatus.fulfilled, (state, action) => {
        state.statuses.sureVoteListStatus =
          initialState.statuses.sureVoteListStatus;
      })
      .addCase(resetDeleteSureVoteStatus.fulfilled, (state, action) => {
        state.statuses.deletedSureVote = initialState.statuses.deletedSureVote;
      })
      .addCase(
        resetDeleteActivityCorrectionStatus.fulfilled,
        (state, action) => {
          state.statuses.deletedActivityCorrection =
            initialState.statuses.deletedActivityCorrection;
        }
      )
      .addCase(resetFastSearch.fulfilled, (state, action) => {
        state.fastSearch = initialState.fastSearch;
      })
      .addCase(resetFastSearchStatus.fulfilled, (state, action) => {
        state.statuses.fastSearch = initialState.statuses.fastSearch;
      })
      .addCase(resetReportStatus.fulfilled, (state, action) => {
        state.statuses.report = initialState.statuses.report;
      })
      .addCase(resetReportCorrection.fulfilled, (state, action) => {
        state.correctSureVotes = initialState.correctSureVotes;
      })
      .addCase(
        resetUpdateSureVotesActivistStatus.fulfilled,
        (state, action) => {
          state.statuses.updateSureVotesActivist =
            initialState.statuses.updateSureVotesActivist;
        }
      )
      .addCase(getSureVotesForTransfer.fulfilled, (state, action) => {
        var sureVoteForTransfer: SureVotesForTransferResponse[] =
          action.payload.data;
        var test = sureVoteForTransfer.sort((a, b) =>
          a.surname.localeCompare(b.surname)
        );
        state.transferSureVotes = test;
      })
      .addCase(resetTransferSureVotes.fulfilled, (state, action) => {
        state.transferSureVotes = initialState.transferSureVotes;
      })
      .addCase(resetTransferSureVotesStatus.fulfilled, (state, action) => {
        state.statuses.transferSureVotes =
          initialState.statuses.transferSureVotes;
      })
      .addCase(resetSureVoteByIdStatus.fulfilled, (state, action) => {
        state.statuses.sureVoteById = initialState.statuses.sureVoteById;
      });
  },
});

export default sureVotesSlice.reducer;
