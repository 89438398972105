import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  styled as muiStyled,
  Pagination,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import {
  createCorrectionSureVotes,
  deleteActivityCorrection,
  getActivistsByActivityId,
  getActivityCorectionByActivityId,
  getActivityCorrectionById,
  getListSureVotes,
  getSelectedSureVoteCorrection,
  getSureVoteById,
  resetDeleteActivityCorrectionStatus,
  resetReportCorrection,
  resetReportStatus,
  resetSavedActivityCorrection,
  resetSavedActivityCorrectionStatus,
  resetSelectedActivistId,
  resetSelectedActivityId,
  resetSelectedSureVoteCorrection,
  resetSureVoteByIdStatus,
  resetSureVoteList,
  resetSureVoteStatus,
  resetUpdateSureVoteCorrectionStatus,
  saveActivityCorrection,
  selectActivists,
  selectActivities,
  selectActivityCorrectionByActivity,
  selectActivityCorrectionById,
  selectCorrectionId,
  selectDeletedActivityCorrection,
  selectListStatus,
  selectReportCorrect,
  selectReportStatus,
  selectSavedActivityCorrection,
  selectSavedActivityCorrectionStatus,
  selectSelectedActivistId,
  selectSelectedActivityId,
  selectSelectedSureVoteCorrection,
  selectSelectedSureVoteCorrectionStatus,
  selectSureVoteById,
  selectSureVotes,
  selectSureVoteStatus,
  selectUpdateSureVoteCorrectionStatus,
  setSelectedActivistId,
  setSelectedActivityId,
  setSelectedCorrectionId,
  updateSureVote,
  updateSurevoteCorrection,
} from "../../slices/sureVotesSlice/sureVotesSlice";
import SureVoteListRequest from "../../models/sureVotes/sureVoteListRequest";
import {
  EmptyGuid,
  INIT_ACTIVITY_CORRECTION,
  INIT_SURE_VOTES_CORRECTION,
} from "../../initData/initData";
import { useEffect, useRef, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";
import { useSureVotesCorrectionStyles } from "./sureVotesCorrectionStyles";
import TablePagination from "../../components/viewAndPrint/pagination";
import ActivityModel from "../../models/activityModel/activityModel";
import ActivistModel from "../../models/activistModel/activistModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import saveAs from "file-saver";
import { CorrectionRequest } from "../../models/sureVotes/correctionRequest";
import SureVoteListDetails from "../../models/sureVotes/sureVoteListDetails";
import { toast } from "react-toastify";
import { CreateActivityCorrection } from "../../models/sureVotes/createActivityCorrection";
import { ActivityCorrectionShort } from "../../models/sureVotes/activityCorrectionShort";
import DeleteConfirmationActivityCorrectionModal from "./deleteConfirmationActivityCorrectionModal";
import AppButton from "../../components/button/AppButton";
import { colors } from "../../app/theme/colors";
import BasicInput from "../../components/basicInput/basicInput";
import SearchIcon from "@mui/icons-material/Search";
import UpdateSureVoteCorrectionModal from "../../components/modals/updateSureVoteCorrectionModal/updateSureVoteCorrectionModal";
import { SureVoteCorrectionRequest } from "../../models/sureVotes/sureVoteCorrectionModel";
import SelectSureVoteCorrectionRequest from "../../models/sureVotes/sureVoteCorrectionRequest";

interface Props {
  setSelectedSureVotesForCorrection?: any;
  selectedSureVotesForCorrection?: SureVoteListDetails[];
  sureVote: SureVoteCorrectionRequest;
  setSureVote: any;
  selectedSureVotes?: string[];
  setSelectedSureVotes?: any;
  setCheckboxChecked?: any;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement={props.placement}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
}));

export default function SureVotesCorrection({
  setSureVote,
  sureVote,
  selectedSureVotesForCorrection,
  setSelectedSureVotesForCorrection,
}: Props) {
  const targetSectionRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { classes } = useSureVotesCorrectionStyles();
  const report = useAppSelector(selectReportCorrect);

  const selectedSureVote = useAppSelector(selectSelectedSureVoteCorrection);
  console.log("KOREKCIJA", selectedSureVote);
  const activityCorrectionId = useAppSelector(selectCorrectionId);
  const selectedSureVoteCorrectionStatus = useAppSelector(
    selectSelectedSureVoteCorrectionStatus
  );
  const sureVoteById = useAppSelector(selectSureVoteById);
  const sureVoteStatus = useAppSelector(selectSureVoteStatus);
  const activities = useAppSelector(selectActivities);
  const activists = useAppSelector(selectActivists);
  const sureVoteList = useAppSelector(selectSureVotes);
  const sureVoteListStatus = useAppSelector(selectListStatus);
  const updateSureVoteCorrectionStatus = useAppSelector(
    selectUpdateSureVoteCorrectionStatus
  );
  const correctionByActivity = useAppSelector(
    selectActivityCorrectionByActivity
  );
  const activityCorrectionDeleted = useAppSelector(
    selectDeletedActivityCorrection
  );
  const savedActivityCorrectionStatus = useAppSelector(
    selectSavedActivityCorrectionStatus
  );
  const reportStatus = useAppSelector(selectReportStatus);
  const savedActivityCorrection = useAppSelector(selectSavedActivityCorrection);
  const correctionById = useAppSelector(selectActivityCorrectionById);
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    place: "",
    street: "",
    homeNumber: "",
  });
  const validateFields = () => {
    const errors = {
      firstName: "",
      lastName: "",
      birthDate: "",
      place: "",
      street: "",
      homeNumber: "",
    };

    if (!sureVote.sureVoteCorrection.name) {
      errors.firstName = "Ime je obavezno polje.";
    }

    if (!sureVote.sureVoteCorrection.surname) {
      errors.lastName = "Prezime je obavezno polje.";
    }

    if (!sureVote.sureVoteCorrection.streetId) {
      errors.street = "Ulica je obavezno polje.";
    }

    if (!sureVote.sureVoteCorrection.homeNumber) {
      errors.homeNumber = "Kućni br. je obavezno polje.";
    }

    return errors;
  };

  const INIT_SELECT_SUREVOTE_CORRECTION_REQUEST: SelectSureVoteCorrectionRequest =
    {
      activityCorrectionId: "",
      id: "",
    };

  const [selectedSureVoteCorrection, setSelectedSureVoteCorrection] =
    useState<SelectSureVoteCorrectionRequest>(
      INIT_SELECT_SUREVOTE_CORRECTION_REQUEST
    );

  const [operationType, setOperationType] = useState("");

  const [selectedVoteIndex, setSelectedVoteIndex] = useState<number | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const selectedActivityId = useAppSelector(selectSelectedActivityId);
  const selectedActivistId = useAppSelector(selectSelectedActivistId);

  const [selectedSureVoteCorrectionId, setSelectedSureVoteCorrectionId] =
    useState<string>("");
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const [
    numberOfResultsPerPageCorrection,
    setNumberOfResultsPerPageCorrection,
  ] = useState(15);

  const [currentPageCorrection, setCurrentPageCorrection] = useState(1);

  const [selectedActivistSureVotes, setSelectedActivistSureVotes] = useState<
    string[]
  >([]);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openEditSureVote, setOpenEditSureVote] = useState(false);
  const [sureVotesCorrection, setSureVotesCorrection] =
    useState<CorrectionRequest>(INIT_SURE_VOTES_CORRECTION);

  const [createActivityCorrection, setCreateActivityCorrection] =
    useState<CreateActivityCorrection>(INIT_ACTIVITY_CORRECTION);

  const [pageNumber, setPageNumber] = useState(1);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [selectedActivists, setSelectedActivists] =
    useState<ActivistModel | null>(null);
  const [selectedCorrectionByActivityId, setSelectedCorrectionByActivityId] =
    useState<ActivityCorrectionShort | null>(null);
  const startIndex =
    (currentPageCorrection - 1) * numberOfResultsPerPageCorrection;

  const endIndex = Math.min(
    startIndex + numberOfResultsPerPageCorrection,
    (selectedSureVotesForCorrection || []).length
  );

  const visibleData = selectedSureVotesForCorrection?.slice(
    startIndex,
    endIndex
  );

  const handleCloseUpdateSureVoteModal = () => {
    setOpenEditSureVote(false);

    if (updateSureVoteCorrectionStatus !== "Fulfilled") {
      console.log(
        "OVDE ne treba treba da budem, USEEFFECT handleCloseUpdateSureVoteModal"
      );

      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: currentPage,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
      dispatch(resetSureVoteByIdStatus());
      setSelectedSureVoteCorrection(INIT_SELECT_SUREVOTE_CORRECTION_REQUEST);
      setSelectedSureVoteCorrectionId("");
    }
  };

  const handleCheckboxChange = (sureVote: SureVoteListDetails, e: any) => {
    const isChecked = e.target.checked;
    const currentSelectedSureVotes = selectedSureVotesForCorrection || [];

    if (isChecked) {
      const sureVotesExist = currentSelectedSureVotes.find(
        (x) => x.id === sureVote.id
      );

      if (sureVotesExist === undefined) {
        const newSelectedSureVotesForCorrection = [
          ...currentSelectedSureVotes,
          sureVote,
        ];

        setSelectedSureVotesForCorrection(newSelectedSureVotesForCorrection);
        const mappedSureVotesIds = newSelectedSureVotesForCorrection.map(
          (x) => x.id
        );

        const mappedSureVotesNotes = newSelectedSureVotesForCorrection.map(
          (x) => x.id
        );

        setCreateActivityCorrection((prevCorrection: any) => ({
          ...prevCorrection,
          sureVotes: newSelectedSureVotesForCorrection.map((sureVote) => ({
            surevoteId: sureVote.id,
            note: sureVote.note || null,
          })),
        }));
      }
    } else {
      const newSelectedSureVotesForCorrection = currentSelectedSureVotes.filter(
        (x) => x.id !== sureVote.id
      );

      setSelectedSureVotesForCorrection(newSelectedSureVotesForCorrection);
      const mappedSureVotesIds = newSelectedSureVotesForCorrection.map(
        (x) => x.id
      );

      setCreateActivityCorrection((prevCorrection: any) => ({
        ...prevCorrection,
        sureVotes: newSelectedSureVotesForCorrection.map((sureVote) => ({
          surevoteId: sureVote.id,
          note: sureVote.note || null,
        })),
      }));
    }
  };

  const handleActivityChange = (
    event: React.ChangeEvent<{}>,
    value: ActivityModel | null
  ) => {
    setSelectedActivity(value);
    if (value) {
      dispatch(setSelectedActivityId(value.id));
      dispatch(getActivistsByActivityId(value?.id || EmptyGuid));
      dispatch(getActivityCorectionByActivityId(value?.id || EmptyGuid));
      setSelectedCorrectionByActivityId(null);
      setCurrentPage(1);
      const request: SureVoteListRequest = {
        activityId: value.id || EmptyGuid,
        activistId: null,
        metaDataRequest: {
          pageNumber: currentPage,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityId: value?.id || EmptyGuid,
      }));

      setSelectedActivistSureVotes([]);
      setSelectedSureVotesForCorrection([]);
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
      }));
      setSureVotesCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
        surevotesIds: [],
      }));
    } else {
      setSelectedActivists(null);
      dispatch(resetSureVoteList());
      dispatch(resetSelectedActivistId());
      dispatch(resetSelectedActivityId());
      setSelectedActivists(null);
      setSelectedSureVotesForCorrection([]);
      setSelectedCorrectionByActivityId(null);
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
        surevotesIds: [],
        activityId: EmptyGuid,
      }));
    }
  };

  const handleActivistChange = (
    event: React.ChangeEvent<{}>,
    value: ActivistModel | null
  ) => {
    setSelectedActivists(value);

    dispatch(setSelectedActivistId(value?.id || null));
  };

  const handleCorrectionByActivity = (
    event: React.ChangeEvent<{}>,
    value: ActivityCorrectionShort | null
  ) => {
    setSelectedCorrectionByActivityId(value);
    if (value) {
      // dispatch(setSelectedCorrectionId(value?.id))
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: value?.id || EmptyGuid,
      }));
      dispatch(getActivityCorrectionById(value?.id));
    } else {
      setSelectedActivistSureVotes([]);
      setSelectedSureVotesForCorrection([]);
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
      }));
      setSureVotesCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
        surevotesIds: [],
      }));
    }
  };

  const handleChangePage = (pageNumber: number) => {
    var request: SureVoteListRequest = {
      activityId: selectedActivityId || EmptyGuid,
      activistId: selectedActivistId,
      metaDataRequest: {
        pageNumber: pageNumber,
        resultsPerPage: numberOfResultsPerPage,
      },
    };
    setPageNumber(pageNumber);

    dispatch(getListSureVotes(request));
  };

  const handlePagination = (e: any) => {
    const paginationIcon = e.target.getAttribute("data-testid");
    const currentSelectedSureVotes = selectedSureVotesForCorrection || [];

    if (paginationIcon) {
      switch (paginationIcon) {
        case "LastPageIcon":
          setCurrentPageCorrection(
            Math.ceil(
              currentSelectedSureVotes.length / numberOfResultsPerPageCorrection
            )
          );
          break;
        case "FirstPageIcon":
          setCurrentPageCorrection(1);
          break;
        case "NavigateNextIcon":
          if (
            currentPageCorrection <
            Math.ceil(
              currentSelectedSureVotes.length / numberOfResultsPerPageCorrection
            )
          ) {
            setCurrentPageCorrection(currentPageCorrection + 1);
          }
          break;
        case "NavigateBeforeIcon":
          if (currentPageCorrection > 1) {
            setCurrentPageCorrection(currentPageCorrection - 1);
          }
          break;
      }
    } else {
      setCurrentPageCorrection(+e.target.innerText);
    }
  };

  const onChangePageSizeCorrection = (value: number) => {
    setCurrentPageCorrection(1);
    setNumberOfResultsPerPageCorrection(value);
  };

  const onChangePageSize = (value: number) => {
    setCurrentPage(1);
    setNumberOfResultsPerPage(value);
    const request: SureVoteListRequest = {
      activityId: selectedActivityId || EmptyGuid,
      activistId: selectedActivistId,
      metaDataRequest: {
        pageNumber: 1,
        resultsPerPage: value,
      },
    };
    dispatch(getListSureVotes(request));
  };

  const handleSaveCorrection = () => {
    dispatch(saveActivityCorrection(createActivityCorrection));
  };

  const handleDownloadReport = () => {
    dispatch(createCorrectionSureVotes(createActivityCorrection));
  };

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deleteActivityCorrection(id));

      setOpenConfirmationModal(false);
    } catch (error) {}
  };

  const handleSave = () => {
    const errors = validateFields();
    setValidationErrors(errors);
    dispatch(updateSurevoteCorrection(sureVote));
    // if (sureVoteById) {
    //   const updatedSureVote: SureVoteModel = {
    //     ...sureVote,
    //     activityId: selectedActivity?.id || EmptyGuid,
    //     activistId: selectedActivists?.id || EmptyGuid,
    //     sureVoteRequest: {
    //       ...sureVote.sureVoteRequest,
    //       id: sureVoteById.id,
    //     },
    //   };

    //   var sureVoteForSave = { ...updatedSureVote };
    //   if (sureVote.sureVoteRequest.birthDate === "") {
    //     sureVoteForSave.sureVoteRequest.birthDate = "01-01-1900";
    //   }

    //   setSureVote(updatedSureVote);
    //   dispatch(updateSureVote(sureVoteForSave));
    // }
  };

  const handleUpdateSureVote = (selectedSureVote: any) => {
    // console.log("SELEKTOVAN SURE VOTE CEO", selectedSureVote)

    // Dispatch the action to get the SureVote by ID
    dispatch(getSureVoteById(selectedSureVote.id));

    // Dispatch the action to get the selected SureVote correction
  };

  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
  };

  const filterSureVotes = (): SureVoteListDetails[] => {
    if (!sureVoteList || !sureVoteList.results) {
      return [];
    }

    const searchTermLowerCase = searchTerm.toLowerCase();

    const filteredSureVotes: SureVoteListDetails[] =
      sureVoteList.results.filter(
        (sureVote) =>
          sureVote.surname.toLowerCase().includes(searchTermLowerCase) ||
          sureVote.name.toLowerCase().includes(searchTermLowerCase)
      );

    return filteredSureVotes;
  };
  // useEffect(() => {
  //   if (selectedSureVoteCorrectionStatus === "Fulfilled") {
  //     setOpenEditSureVote(true);
  //   }
  // }, [selectedSureVoteCorrectionStatus]);
  useEffect(() => {
    if (activityCorrectionDeleted === "Fulfilled") {
      dispatch(resetSavedActivityCorrection());
      toast.success("Uspešno ste obrisali korekciju!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetDeleteActivityCorrectionStatus());
        },
      });
      dispatch(
        getActivityCorectionByActivityId(selectedActivity?.id || EmptyGuid)
      );
      setSureVotesCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
        surevotesIds: [],
      }));
      setSelectedSureVotesForCorrection([]);
      setSelectedCorrectionByActivityId(null);
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
      }));
    }
  }, [activityCorrectionDeleted]);

  useEffect(() => {
    if (correctionById) {
      setSelectedSureVotesForCorrection([...correctionById.sureVotes]);
      const sureVoteIds = correctionById?.sureVotes.map((item) => item.id);
      setSureVotesCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: activityCorrectionId,
        sureVote: sureVoteIds,
      }));
    }
  }, [correctionById]);

  useEffect(() => {
    if (selectedActivistId) {
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    } else {
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: null,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    }
  }, [selectedActivistId]);

  useEffect(() => {
    if (selectedActivity) {
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: 1,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
    }
  }, [selectedActivityId]);

  useEffect(() => {
    if (savedActivityCorrectionStatus === "Fulfilled") {
      if (operationType === "update") {
        toast.success("Uspešno ste ažurirali korekciju!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSavedActivityCorrectionStatus());
          },
        });
      }
      if (operationType === "save") {
        toast.success("Uspešno ste sačuvali novu korekciju!", {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSavedActivityCorrectionStatus());
          },
        });
        setCreateActivityCorrection((prevCorrection) => ({
          ...prevCorrection,
          activityCorrectionId: savedActivityCorrection?.id || null,
        }));
      }
      dispatch(
        getActivityCorectionByActivityId(selectedActivity?.id || EmptyGuid)
      );
      dispatch(
        getActivityCorrectionById(savedActivityCorrection?.id || EmptyGuid)
      );
      setSelectedCorrectionByActivityId(savedActivityCorrection);
    }
  }, [savedActivityCorrection, savedActivityCorrectionStatus]);

  useEffect(() => {
    if (report && reportStatus === "Fulfilled") {
      const request: SureVoteListRequest = {
        activityId: selectedActivityId || EmptyGuid,
        activistId: selectedActivistId,
        metaDataRequest: {
          pageNumber: currentPage,
          resultsPerPage: numberOfResultsPerPage,
        },
      };
      dispatch(getListSureVotes(request));
      saveAs(report, "report.xlsx");
      toast.success(
        "Uspešno ste izvezli sigurne glasove i sačuvali korekciju!",
        {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetSavedActivityCorrectionStatus());
            dispatch(resetReportStatus());
            dispatch(resetReportCorrection());
          },
        }
      );
      dispatch(
        getActivityCorectionByActivityId(selectedActivity?.id || EmptyGuid)
      );
      setCreateActivityCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
      }));
      setSureVotesCorrection((prevCorrection) => ({
        ...prevCorrection,
        activityCorrectionId: null,
        surevotesIds: [],
      }));
      setSelectedSureVotesForCorrection([]);
      setSelectedCorrectionByActivityId(null);
    }
  }, [reportStatus, report]);

  // useEffect(() => {
  //   if (sureVoteStatus === "Fulfilled") {
  //     dispatch(
  //       getActivityCorrectionById(selectedCorrectionByActivityId?.id || "")
  //     );
  //     setCurrentPage(currentPage);

  //     toast.success("Sigurni glas je uspešno ažuriran!", {
  //       position: "bottom-left",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       onClose: () => {
  //         dispatch(resetSureVoteStatus());
  //         setSelectedVoteIndex(null);
  //       },
  //     });
  //     const request: SureVoteListRequest = {
  //       activityId: selectedActivityId || EmptyGuid,
  //       activistId: selectedActivistId,
  //       metaDataRequest: {
  //         pageNumber: currentPage,
  //         resultsPerPage: numberOfResultsPerPage,
  //       },
  //     };

  //     dispatch(getListSureVotes(request));
  //     setSelectedSureVoteCorrectionId("")
  //   }
  // }, [sureVoteStatus]);

  useEffect(() => {
    if (updateSureVoteCorrectionStatus === "Fulfilled") {
      console.log("OVDJE TREBA DA BUDEM, USEEFFECT STATUSSUREVOTE");
      dispatch(resetSelectedSureVoteCorrection());
      dispatch(
        getActivityCorrectionById(selectedCorrectionByActivityId?.id || "")
      );
      setCurrentPage(currentPage);
      setSelectedSureVoteCorrection(INIT_SELECT_SUREVOTE_CORRECTION_REQUEST);
      toast.success("Sigurni glas je uspešno ažuriran!", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          dispatch(resetUpdateSureVoteCorrectionStatus());
          setSelectedVoteIndex(null);
        },
      });

      setSelectedSureVoteCorrectionId("");
      targetSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [updateSureVoteCorrectionStatus]);

  useEffect(() => {
    if (selectedSureVotesForCorrection) {
      setCreateActivityCorrection((prevCorrection: any) => ({
        ...prevCorrection,
        sureVotes: selectedSureVotesForCorrection.map((sureVote) => ({
          surevoteId: sureVote.id,
          note: sureVote.note || null,
        })),
      }));

      dispatch(setSelectedCorrectionId(correctionById?.id || null));
    }
  }, [selectedSureVotesForCorrection]);

  const totalNumberOfPages = Math.ceil(
    (selectedSureVotesForCorrection || []).length /
      numberOfResultsPerPageCorrection
  );

  const setSureVoteCheckStatusById = (sureVoteId: string): boolean => {
    let shouldBeChecked = false;

    const currentSelectedSureVotes = selectedSureVotesForCorrection || [];
    const sureVote = currentSelectedSureVotes.find((x) => x.id === sureVoteId);

    if (sureVote) {
      shouldBeChecked = true;
    }

    return shouldBeChecked;
  };

  useEffect(() => {
    if (selectedSureVoteCorrectionId) {
      console.log("selectedSureVoteCorrectionId", selectedSureVoteCorrectionId);
      setSelectedSureVoteCorrection((prevCorrection: any) => ({
        ...prevCorrection,
        id: selectedSureVoteCorrectionId,
        activityCorrectionId: correctionById?.id,
      }));
    }
  }, [selectedSureVoteCorrectionId]);

  useEffect(() => {
    if (selectedSureVoteCorrection.id !== "") {
      dispatch(getSelectedSureVoteCorrection(selectedSureVoteCorrection));
    }
  }, [selectedSureVoteCorrection]);

  return (
    <>
      <Grid sx={{ mx: "10%" }}>
        <Grid item md={12} xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ background: "#f5f5f5" }}
            >
              <Typography sx={{ textAlign: "left" }}>
                Podaci aktivnosti
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item md={12} sx={{}} mt={1}>
                <Grid container spacing={1} mt={1}>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Typography
                        variant="body1"
                        className={classes.inputTitle}
                      >
                        Aktivnost
                      </Typography>
                      <Autocomplete
                        options={activities ? activities : []}
                        getOptionLabel={(option) => option.name}
                        value={selectedActivity}
                        onChange={handleActivityChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            className={classes.autocompleteTextField}
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Typography
                        variant="body1"
                        className={classes.inputTitle}
                      >
                        Aktivista
                      </Typography>
                      <Autocomplete
                        options={activists ? activists : []}
                        getOptionLabel={(option) =>
                          option.lastName + " " + option.firstName
                        }
                        value={selectedActivists}
                        onChange={handleActivistChange}
                        disabled={selectedActivity === null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            className={classes.autocompleteTextField}
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid container>
          <Grid item md={6} xs={12} my={2} display={"flex"}>
            <BasicInput
              label="Pretraga"
              onChange={(e) => handleSearchChange(e.target.value)}
              value={searchTerm}
              disabled={!selectedActivity}
              startAdornment={
                <InputAdornment position="start" style={{ margin: "0 5px" }}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
              <LightTooltip
                    title="Pretraga može da se izvrši isključivo po imenu ili prezimenu."
                    placement="top"
                  >
                    <InfoIcon sx={{ color: colors.primary }} />
                  </LightTooltip>
          </Grid>
        </Grid>

        {sureVoteListStatus === "Pending" ? (
          <Grid container justifyContent="center" alignItems="center" my={2}>
            <CircularProgress
              className={classes.circularProgress}
              size={40}
              style={{ alignItems: "center" }}
            />
          </Grid>
        ) : (
          <TableContainer>
            <Table id="member" size="small">
              <TableHead
                style={{
                  background: "#2f4454",
                  color: "white",
                  borderBottom: "none",
                  border: "none",
                }}
                sx={{
                  "& .MuiTableRow-root > .MuiTableCell-root": {
                    color: "white",
                  },
                }}
              >
                <StyledTableRow>
                  <StyledTableCell>Izaberite sig. glas.</StyledTableCell>
                  <StyledTableCell>Red. br.</StyledTableCell>
                  <StyledTableCell>Prezime</StyledTableCell>
                  <StyledTableCell>Ime</StyledTableCell>
                  <StyledTableCell>Datum rođ.</StyledTableCell>
                  <StyledTableCell>Telefon</StyledTableCell>
                  <StyledTableCell>Opština</StyledTableCell>
                  <StyledTableCell>Mesto</StyledTableCell>
                  <StyledTableCell>Ulica</StyledTableCell>
                  <StyledTableCell>Kućni broj</StyledTableCell>
                  <StyledTableCell>Aktivista</StyledTableCell>
                  {/* <StyledTableCell>Napomena</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filterSureVotes().map((sureVote, index) => (
                  <StyledTableRow>
                    <StyledTableCell>
                      <Checkbox
                        checked={setSureVoteCheckStatusById(sureVote.id)}
                        onChange={(e) => handleCheckboxChange(sureVote, e)}
                        className={classes.smallCheckbox}
                        style={{ color: colors.primary }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {(currentPage === 1
                        ? 0
                        : (currentPage - 1) * numberOfResultsPerPage) +
                        index +
                        1}
                    </StyledTableCell>
                    <StyledTableCell>{sureVote.surname}</StyledTableCell>
                    <StyledTableCell>{sureVote.name}</StyledTableCell>
                    <StyledTableCell>{sureVote.birthDate}</StyledTableCell>
                    <StyledTableCell>{sureVote.phone}</StyledTableCell>
                    <StyledTableCell>
                      {sureVote.municipalityName}
                    </StyledTableCell>
                    <StyledTableCell>{sureVote.cityName}</StyledTableCell>
                    <StyledTableCell>{sureVote.streetName}</StyledTableCell>
                    <StyledTableCell>{sureVote.homeNumber}</StyledTableCell>
                    <StyledTableCell>
                      {sureVote.activistDisplayName}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      {sureVote.note}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <>
          <Grid container display={"flex"} alignItems="center" mt={2}>
            <Grid
              item
              md={12}
              xs={12}
              display={isMobile ? "" : "flex"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                md={12}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
              >
                <Grid item>
                  <Typography>Rez. po stranici:</Typography>
                </Grid>
                <Grid item>
                  <select
                    className={classes.select}
                    value={numberOfResultsPerPage}
                    onChange={(e: any) => {
                      onChangePageSize(e.target.value);
                    }}
                  >
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                  </select>
                </Grid>
                <Grid item>
                  <TablePagination
                    totalCount={sureVoteList?.totalPages || 0}
                    page={sureVoteList?.currentPage || 1}
                    handleChangePage={handleChangePage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        <div
          ref={targetSectionRef}
          // style={{
          //   transition: "transform 0.3s ease-in-out", 
          // }}
        >
          <Grid container display={"flex"} alignItems={"center"}>
            <Grid item md={6} xs={12} my={2}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className={classes.inputTitle}>
                  Izaberi postojeću korekciju
                </Typography>
                <Autocomplete
                  options={correctionByActivity ? correctionByActivity : []}
                  getOptionLabel={(option) => option.name}
                  value={selectedCorrectionByActivityId}
                  onChange={handleCorrectionByActivity}
                  disabled={
                    selectedActivity === null ||
                    sureVoteListStatus === "Pending"
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      className={classes.autocompleteTextField}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <IconButton disabled={!selectedCorrectionByActivityId}>
                <DeleteOutlineOutlinedIcon
                  style={{
                    color: selectedCorrectionByActivityId
                      ? "#DE2C2C"
                      : "lightGrey",
                    cursor: "pointer",
                    marginBottom: 0,
                    fontSize: "32px",
                    marginTop: "15px",
                  }}
                  onClick={() => {
                    setOpenConfirmationModal(true);
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container direction="row" mt={2}>
            <Grid item md={12} display={"flex"}>
              <Grid item>
                {reportStatus === "Pending" ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={25}
                    style={{ alignItems: "center" }}
                  />
                ) : (
                  <Typography variant="button" fontSize={"24px"}>
                    <IconButton disabled={!selectedActivity}>
                      <LightTooltip
                        title={"Izvezi Excel"}
                        placement="top-start"
                      >
                        <TableViewIcon
                          id="tableComponent_icon_excel-report"
                          fontSize="inherit"
                          className={classes.exportExcButton}
                          onClick={() => handleDownloadReport()}
                          sx={{
                            color: selectedActivity
                              ? ""
                              : "lightGrey !important",
                          }}
                        />
                      </LightTooltip>
                    </IconButton>
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="button" fontSize={"24px"}>
                  <IconButton disabled>
                    <LightTooltip title={"Izvezi PDF"} placement="top-start">
                      <PictureAsPdfIcon
                        id="tableComponent_icon_pdf-report"
                        fontSize="inherit"
                        className={classes.exportPdfButton}
                        sx={{
                          color: "lightGrey !important",
                        }}
                      />
                    </LightTooltip>
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item md={6}>
              <Typography variant="h5">Izabrani sigurni glasovi</Typography>
            </Grid>
            <Grid item md={6} textAlign={"right"}>
              <AppButton
                color={colors.green}
                hover={colors.green}
                label={
                  savedActivityCorrectionStatus === "Pending"
                    ? createActivityCorrection?.activityCorrectionId === null
                      ? "Čuvanje korekcije..."
                      : "Ažuriranje korekcije..."
                    : createActivityCorrection?.activityCorrectionId === null
                    ? "Sačuvaj korekciju"
                    : "Ažuriraj korekciju"
                }
                disabled={
                  selectedSureVotesForCorrection?.length === 0 ||
                  savedActivityCorrectionStatus === "Pending"
                }
                onClick={() => {
                  if (createActivityCorrection?.activityCorrectionId === null) {
                    setOperationType("save");
                  } else {
                    setOperationType("update");
                  }
                  handleSaveCorrection();
                }}
                startIcon={
                  savedActivityCorrectionStatus === "Pending" ? (
                    <CircularProgress
                      size={15}
                      className={classes.circularProgress}
                    />
                  ) : (
                    ""
                  )
                }
              />
              <LightTooltip
                title={
                  "Ukoliko je izvršena akcija izvoza sigurnih glasova, korekcija će automatski biti sačuvana"
                }
                placement="top"
              >
                <InfoIcon sx={{ color: colors.primary }} />
              </LightTooltip>
            </Grid>

            <Grid item md={12}>
              <TableContainer>
                <Table id="correct-table" size="small">
                  <TableHead
                    style={{
                      background: "#2f4454",
                      color: "white",
                      borderBottom: "none",
                      border: "none",
                    }}
                    sx={{
                      "& .MuiTableRow-root > .MuiTableCell-root": {
                        color: "white",
                      },
                    }}
                  >
                    <StyledTableRow>
                      {selectedCorrectionByActivityId && (
                        <StyledTableCell>Akcija</StyledTableCell>
                      )}
                      <StyledTableCell>Red. br.</StyledTableCell>
                      <StyledTableCell>Prezime</StyledTableCell>
                      <StyledTableCell>Ime</StyledTableCell>
                      <StyledTableCell>Datum rođ.</StyledTableCell>
                      <StyledTableCell>Telefon</StyledTableCell>
                      <StyledTableCell>Opština</StyledTableCell>
                      <StyledTableCell>Mesto</StyledTableCell>
                      <StyledTableCell>Ulica</StyledTableCell>
                      <StyledTableCell>Kućni broj</StyledTableCell>
                      <StyledTableCell>Aktivista</StyledTableCell>
                      <StyledTableCell>Napomena</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {visibleData?.map((selectedSureVote: any, index: any) => (
                      <StyledTableRow key={selectedSureVote.id}>
                        {selectedCorrectionByActivityId && (
                          <StyledTableCell>
                            <LightTooltip
                              title={"Izmeni"}
                              arrow
                              onClick={() => {
                                setSelectedSureVoteCorrectionId(
                                  selectedSureVote?.id
                                );
                                handleUpdateSureVote(selectedSureVote); // Pass the selectedSureVote object
                                setOpenEditSureVote(true);
                              }}
                            >
                              <CreateOutlinedIcon
                                name="createIcon"
                                style={{
                                  color: "#00B2FF",
                                  cursor: "pointer",
                                }}
                              />
                            </LightTooltip>
                          </StyledTableCell>
                        )}

                        <StyledTableCell>
                          {(currentPageCorrection === 1
                            ? 0
                            : (currentPageCorrection - 1) *
                              numberOfResultsPerPageCorrection) +
                            index +
                            1}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.surname}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.birthDate}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.phone}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.municipalityName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.cityName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.streetName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.homeNumber}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.activistDisplayName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {selectedSureVote.note}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container display={"flex"} alignItems="center" mt={2}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  display={isMobile ? "" : "flex"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent={isMobile ? "flex-start" : "flex-end"}
                  >
                    <Grid item>
                      <Typography>Rez. po stranici:</Typography>
                    </Grid>
                    <Grid item>
                      <select
                        className={classes.select}
                        value={numberOfResultsPerPageCorrection}
                        onChange={(e: any) => {
                          onChangePageSizeCorrection(e.target.value);
                        }}
                      >
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </select>
                    </Grid>
                    <Grid item>
                      <Pagination
                        count={totalNumberOfPages}
                        page={currentPageCorrection}
                        onChange={(e: any) => handlePagination(e)}
                        variant="outlined"
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <DeleteConfirmationActivityCorrectionModal
        handleDelete={() =>
          handleDelete(selectedCorrectionByActivityId?.id || "")
        }
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        correctionName={selectedCorrectionByActivityId?.name || ""}
      />
      <UpdateSureVoteCorrectionModal
        open={openEditSureVote}
        handleClose={handleCloseUpdateSureVoteModal}
        handleSave={handleSave}
        setSureVote={setSureVote}
        setValidationErrors={setValidationErrors}
        sureVote={sureVote}
        validationErrors={validationErrors}
      />
    </>
  );
}
