import axios from "axios";

// const GetLocalIP = (): string => {
//   const os = require('os');

//   const networkInterfaces = os.networkInterfaces();
//   const ip = networkInterfaces['eth0'][0]['address']
//   console.log('GetLocalIP', ip);
//   return ip;
// }

//const localIP = "169.254.146.218";
//const localIP = "192.168.1.101";
const authIP = "";
const localIP = "localhost";

const Api_Environment_Auth = {
  Local: "https://"+localIP+":5101",
  Prod: "https://admin.api.easymembership.net"
};

const Api_Environment_SureVotes = {
  // Local: "https://"+localIP+':6501',
  Local: "https://"+"localhost"+':5001',
  Prod: "https://capillary-votes.api.easymembership.net"
};

const Api_Environment_Addresses = {
  Address: "https://"+localIP+":6701",
  AddressMaganementLocal: "https://"+localIP+":6701",
  Prod:"https://membership.api.easymembership.net"
};

export const api_auth = axios.create({
  baseURL: Api_Environment_Auth.Prod + "/api",
});

export const api_sureVotes = axios.create({
  baseURL: Api_Environment_SureVotes.Prod + "/api",
});

export const api_address = axios.create({
  baseURL: Api_Environment_Addresses.Prod + "/api",
});

export const api_address_management = axios.create({
  baseURL: Api_Environment_Addresses.AddressMaganementLocal + "/api",
});

api_sureVotes.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api_address.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api_address_management.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
